const ALL = 0
const VIP = 1
const NOMAL = 2

export const listTypeCabinet = {
    [ALL]:{
        key: ALL,
        text: "Tất cả",
    },
    [VIP]:{
        key: VIP,
        text: "Tủ VIP"
    },
    [NOMAL]:{
        key: NOMAL,
        text: "Tủ Thường"
    }
}
