import moment from 'moment';
import { listHistoryMember } from '../../data/listHistoryMember';
import { listHistoryStatus } from '../../data/listHistoryStatus';
import * as types from './types'

const defaultStartTime = moment().utc().startOf("month").toDate()
const defaultEndTime = moment(new Date()).endOf("day").toDate()

const initialState = {
    listLockers: [],
    dataLockerListByCluster: [],
    listLockerHistory: [],
    totalLockers: 0,
    isSearch: true,
    condFilters: {
        branchCode: "",
        filterFrom: "",
        filterSize: 9,
        quickSearchData: "",
        lockerType: 0,
        kioskID: "",
        clusterID: "",
        lockerStatus: 0
    },
    filterLockerHistory: {
        branchCode: "",
        kioskID: "",
        clusterID: "",
        lockerID: "",
        filterFrom: "",
        filterSize: 15,
        userType: listHistoryMember[0].key,
        actionType: listHistoryStatus[0].key,
        startTime: defaultStartTime,
        endTime: defaultEndTime,
        quickSearchData: ""
    }
}

const lockerReducer = (state= initialState, action) => {
    switch (action.type){

        case types.RESET_LIST_LOCKER: {
            return { 
                ...initialState,
                condFilters: state.condFilters,
                type: action.type }
        }
        case types.RESET_LIST_LOCKER_ALL: {
            return { 
                ...initialState,
                type: action.type 
            }
        }
        case types.GET_LIST_LOCKER:
            return {                
                ...state,
                ...action.payload,
                listLockers: action.payload.isScroll
                    ? [...state.listLockers, ...action.payload.listLockers]
                    : action.payload.listLockers,
                isSearch: true,
                type: action.type
            };
        case types.ADD_LOCKER_SUCCESS:
            return {
                ...state,
                listLockers: [],
                type: action.type
            }
        case types.UPDATE_LOCKER_SUCCESS:
            return {
                ...state,
                listLockers: [],
                type: action.type
            }
        case types.DEL_LOCKER_SUCCESS:
            return {
                ...state,
                listLockers: [],
                type: action.type
            }
        case types.RESET_VALUE_SEARCH:
            return {
                ...state,
                isSearch: false
            }
        case types.GET_LOCKER_LIST_BY_CLUSTER: 
            return {
                ...state,
                dataLockerListByCluster: action.payload.dataLockerListByCluster
            }
            
        case types.GET_LOCKER_HISTORY: 
            return {
                ...state,
                ...action.payload,
                listLockerHistory: action.payload.isScroll
                ? [...state.listLockerHistory, ...action.payload.lockerHistory]
                : action.payload.lockerHistory,
                type: action.type
            }

        case types.RESET_LOCKER_HISTORY: 
            return{
                ...state,
                filterLockerHistory: initialState.filterLockerHistory,
                type: action.type
            }
        default : return state;
    }




}
export default lockerReducer;