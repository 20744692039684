import React, { useRef, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//images
import IcClose from "../../../../../assets/images/IcClose";
import IcFilterWhite from "../../../../../assets/images/IcFilterWhite";
import { listRoom } from "../../../../../data/listRoom";
import { useOnClickOutside } from "../../../../../hook/useClickOutside";
import { getListKioskAction, resetListKiosk } from "../../../../../redux/kiosk/action";
import Button from "../../../../Control/Button";
// css
import { OverlayFullScreen } from "../../../../MainStyles/main";
import { WrapPopupFilterCabinetRange } from "./index.styles";
//component
import InputDropdownRoom from "./inputDropdownRoom";
import InputDropdownNameKiosk from "./InputDropdownNameKiosk";
import { getListClusterAction, resetValueSearchCluster } from "../../../../../redux/cluster/action";

const PopupFilterCabinetRange = ({ onClose, valueSearch }) => {
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const { listKiosks, branchCode, total, totalClusters, condFilters } =
    useSelector((state) => ({
      listKiosks: state.kioskReducer.listKiosks,
      total: state.kioskReducer.total,
      branchCode: state.login.dataLogin.branchCode,
      totalClusters: state.clusterReducer.totalClusters,
      condFilters: state.clusterReducer.condFilters,
    }));

  const configListNameKiosk = listKiosks?.map((item, i) => {
    return {
      key: item?.kioskId,
      text: item?.kioskName,
    };
  });
  //sort array theo anphabet
  configListNameKiosk.sort((a,b)=>{
    if (a.text.toLowerCase() < b.text.toLowerCase()){ return -1}
    if (a.text.toLowerCase() > b.text.toLowerCase()){ return 1}
    return 0
  })
  //add key all
  configListNameKiosk.unshift({ key: "", text: "Tất cả" });

  const [filter, setFilter] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      roomType: listRoom[condFilters?.roomType],
      kioskName: condFilters?.kioskName ? configListNameKiosk?.filter(
        (item) => item.text === condFilters?.kioskName
      )[0] : {key: "", text: "Tất cả"},
    }
  );

  const _onClosePopup = () => {
    if (filterRef) {
      filterRef.current.classList.remove("show-right-popup");
      filterRef.current.classList.add("hide-right-popup");
    }
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const _handleGetValueRoomKiosk = (val) => {
    setFilter({ roomType: val });
  };

  const _handleGetValueNameKiosk = (val) => {
    setFilter({ kioskName: val });
  };

  const _handleReset = () => {
    setFilter({
      roomType: Object.values(listRoom)[0],
      kioskName: { key: "", text: "Tất cả" },
    });
    dispatch(resetValueSearchCluster())
    dispatch(
      getListClusterAction({
        branchCode: branchCode,
        filterFrom: "",
        filterSize: totalClusters ? totalClusters : 100,
        quickSearchData: "",
        roomType: "",
        kioskName: ""
      })
    );
  };

  const _handleFilter = () => {
    const { roomType, kioskName } = filter;
   
    let dataFilter = {
      branchCode: branchCode,
      filterFrom: "",
      filterSize: totalClusters ? totalClusters : 100,
      quickSearchData: valueSearch,
      roomType: roomType?.key,
      kioskName: kioskName?.key === "" ? kioskName?.key : kioskName.text,
    };
    dispatch(getListClusterAction(dataFilter));
  };

  useOnClickOutside(filterRef, () => {
    _onClosePopup();
  });

  useEffect(() => {
    dispatch(
      getListKioskAction({
        branchCode: branchCode,
        filterFrom: "",
        filterSize: total ? total : 9,
        quickSearchData: "",
        roomType: "",
        status: ""
      })
    );
    return ()=>{
      // dispatch(resetListKiosk())
    }
  }, [total]);

  return (
    <OverlayFullScreen notUseOverlay={true}>
      <WrapPopupFilterCabinetRange className="show-right-popup" ref={filterRef}>
        <div className="wrap-header">
          <IcClose className="btn-close" onClick={_onClosePopup} />
          <div className="wrap-btn">
            <Button className="btn-reset" onClick={_handleReset} text="Reset" />
            <Button
              className="btn-filter-range"
              iconBtn={<IcFilterWhite />}
              onClick={_handleFilter}
              text="Lọc dữ liệu"
            />
          </div>
        </div>
        <InputDropdownRoom
          handleGetValueDropdown={_handleGetValueRoomKiosk}
          listDropDown={Object.values(listRoom)}
          objChoice={filter.roomType}
          required={false}
        />
        <InputDropdownNameKiosk
          handleGetValueDropdown={_handleGetValueNameKiosk}
          listDropDown={configListNameKiosk}
          objChoice={filter.kioskName}
        />
      </WrapPopupFilterCabinetRange>
    </OverlayFullScreen>
  );
};

export default PopupFilterCabinetRange;
