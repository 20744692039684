import React from 'react'
import IcKioskBlue from '../../../../../../../../../assets/images/IcKioskBlue';
import IcKioskGray from '../../../../../../../../../assets/images/IcKioskGray';
import Input from '../../../../../../../../Control/Input';
//component


const iconStore = {
    icon: IcKioskGray,
    iconActive: IcKioskBlue,
    iconError: IcKioskBlue,
}

export default function InputIdCabinet({ value, handleGetValueInput, handleValidationInput, error}) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleGetValueInput && handleGetValueInput(value)
    }
  return (
    <Input
        heightInput="63px"
        title="Số thứ tự hộc tủ"
        iconInput={iconStore}
        inputType = "TEXT"
        value = {value}
        isRequired = {true}
        required
        onChange = {_handleChangeValue}
        placeholder="ID Hộc tủ"
        handleValidationInput={handleValidationInput}
        error={error}
    />
  )
}
