import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//styles
import {TableContentCabinetRange } from "./index.styles"
//images
import IcNoData from '../../../../../assets/images/IcNoData'
//component
import EmptyDataControl from '../../../../Control/EmptyDataControl'
import CabinetRangeItem from './CabinetRangeItem'
//constant
import {
  main,
  cabinetRangeManage,
  cabinetRangeDetails,
} from "../../../../../constant/routes";
//action
import { getListClusterAction, resetListCluster } from '../../../../../redux/cluster/action'
import { resetListLockerAll } from '../../../../../redux/locker/action'
import useScrollEnd from '../../../../../hook/useScrollEnd'
import IcNoResult from '../../../../../assets/images/IcNoResult'
import { resetListKiosk } from '../../../../../redux/kiosk/action'


export default function ListCabinetRange(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const listRef = useRef(null)
  const { listClusters, branchCode, isSearching } = useSelector(state => ({
    listClusters: state.clusterReducer.listClusters,
    branchCode: state.login.dataLogin.branchCode,
    isSearching: state.clusterReducer.isSearching
  }))

  useEffect(() => {
    // const dataGetList = {
    //   branchCode: branchCode,
    //   filterFrom: "",
    //   filterSize: 9,
    //   quickSearchData: "",
    //   roomType: "",
    //   kioskName: "",
    // };
    // dispatch(getListClusterAction(dataGetList));

    return () => {
      dispatch(resetListCluster());
      dispatch(resetListLockerAll())
      dispatch(resetListKiosk())
    };
  }, []);

  // useScrollEnd(
  //   listRef,
  //   (callback) => {
  //     dispatch(
  //       getListClusterAction(
  //         {
  //           branchCode: branchCode,
  //           filterFrom: listClusters[listClusters.length - 1].id,
  //           filterSize: 9,
  //           quickSearchData: "",
  //           roomType: "",
  //           kioskName: "",
  //         },
  //         true,
  //         callback
  //       )
  //     );
  //   },
  //   [listClusters?.length],
  //   [listClusters?.length]
  // );
  
  const _handleClickBtn = (docID) => {
    navigate(`/${main}/${cabinetRangeManage}/${(cabinetRangeDetails(docID))}`)
  }

  const tableContent = useMemo(() => (
    listClusters?.length === 0 ?
      <div className="wrap-empty">
        { isSearching && (
          <EmptyDataControl 
            IconNonData={<IcNoResult />}
            labelDes='Không có kết quả'/>
        )}
        { !isSearching && (
          <EmptyDataControl 
            IconNonData={<IcNoData />}
            labelDes='Hiện tại chưa có dãy tủ nào'/>
        )}
      </div>
      :
      <div className="wrap-list-trans" ref={listRef}>
        <div className="list-trans">
          {
            listClusters?.map((item, i) => (
              <CabinetRangeItem key = {i} item = {item} refList = {listRef} onClick={() => _handleClickBtn(item.id)}/>
            ))
          }
        </div>
      </div>
  ), [JSON.stringify(listClusters)])
  return (
      <TableContentCabinetRange>
        {tableContent}
      </TableContentCabinetRange>
  )
}
