import styled from "styled-components";

const ButtonWrapper = styled.button`
    pointer-events: ${props => props.isDisable ? "none" : "auto"};
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    position: relative;
    padding: 9px 12px;
    background: #A31E23;
    cursor: pointer;
    border: 1px solid;
    outline: none;
    color: #FFFFFF;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 13.5px;
    line-height: 18px;
    letter-spacing: -0.01em;

    &.btn-filter {
        background: #A31E23;
        color: #FFFFFF;        
    }

    &.btn-reset {
        border: 1px solid #A31E23;
        background: #FFFFFF;
        color: #720000;        
    }

    &.btn-primary {
        background: #007822;
        color: #FFFFFF;        
    }

    &.btn-cancel {
        border: 1px solid #FF2028;
        background: #ffffff;
        color: #FF2028;        
    }

    &.btn-outline {
        border: 1px solid #007822;
        background: #ffffff;    
        color: #007822;
        
    }
    &.btn-add{
        margin-left: 10px;
    }

    &.btn-del{
        background: #F45C5C;
    }
    
    &.btn-cancel-del{
        background: #F7F7F7;
        color: #777777;
        border: none;
    }

    &.btn-warning{
        background: #a31e23;
    }

    &.disable{
        background: #B7B7B7;
    }

    svg{
        margin-right: 5px;
    }
`

export {ButtonWrapper};