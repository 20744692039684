import React from 'react'
import IcNameKioskBlue from '../../../../../../../../assets/images/IcNameKioskBlue'
import IcNameKioskGray from '../../../../../../../../assets/images/IcNameKioskGray'
import Input from '../../../../../../../Control/Input'

const iconStore = {
    icon: IcNameKioskGray,
    iconActive: IcNameKioskBlue,
    iconError: IcNameKioskBlue,
}

export default function InputNameKiosk({value, handleGetValueInput, handleValidationInput, error}) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleGetValueInput && handleGetValueInput(value)
    }
  return (
    <Input
        error={error}
        heightInput="63px"
        title="Tên Kiosk"
        iconInput={iconStore}
        inputType = "TEXT"
        value = {value}
        isRequired = {true}
        required
        onChange = {_handleChangeValue}
        placeholder="Tên Kiosk"
        handleValidationInput={handleValidationInput}
    />
  )
}
