import styled from "styled-components";

const WrapItem = styled.div`
  .list-cabinet {
    position: relative;
    width: 255px;
    height: 200px;
    background-color: #f9f9f9;
    border: 0.5px solid #bdbdbd;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;

    .item-cabinet-option {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 20px;
      top: 20px;
      background: rgba(163, 30, 35, 0.3);
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      .wrap-option {
        top: 30px;
        right: 0;
      }
    }
    img {
      position: absolute;
      top: -26px;
      right: -14px;
      border-radius: 0 8px 0 0;
    }
    .item-check {
      width: calc(100% - 15px);
      background-color: #fff;
      height: 40px;
      position: absolute;
      bottom: 0;
      border-radius: 0 0 8px 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1),
        5px 5px 40px rgba(174, 174, 192, 0.2);
      padding-right: 15px;
      gap: 4px;
      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
      }
    }
    .item-value {
      position: absolute;
      top: 110px;
      left: 20px;
      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.01em;
      }
      .value {
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
      }
    }
    .item-time {
      position: absolute;
      width: 74px;
      height: 19px;
      left: 20px;
      top: 20px;
      background-color: #efefef;
      border-radius: 6px;
      p {
        color: #33adff;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
      }
    }
  }
`;

export { WrapItem };
