import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TableCabinet, WrapButton } from "./index.styles";

// img
import IcFilterRed from "../../../../../../assets/images/IcFilterRed";
import IcNoData from "../../../../../../assets/images/IcNoData";
import IcPlus from "../../../../../../assets/images/IcPlus";
import IcEdit from "../../../../../../assets/images/IcEdit";

//component
import EmptyDataControl from "../../../../../Control/EmptyDataControl";
import Button from "../../../../../Control/Button";
import InputSearch from "../../../../../Control/InputSearch";
import PopupHistoryKiosk from '../../../KioskManage/Detail/HistoryKioskRange'

// data
// import dataListCabinet from "../../../../../../mockdata/dataListCabinet";
import PopupFilterCabinet from "./PopupFilterCabinet";
import Portal from "../../../../../Control/Portal";
import PopupAddCabinet from "./OptionCabinet/PopupAddCabinet";
import ItemDetailCabinet from "./DetailCabinetItem";
import { getListLockerAction, getLockerListByCluster, resetListLocker } from "../../../../../../redux/locker/action";
import useScrollEnd from "../../../../../../hook/useScrollEnd";
import IcNoResult from "../../../../../../assets/images/IcNoResult";
// import { getClusterListByKioskID, getInfoByDocID, resetGetInfoByDocID } from "../../../../../../redux/cluster/action";
// import { getInfoLocker } from "../../../../../../redux/kiosk/action";


const DetailInforCabinet = ({ clusterInfo }) => {
  
  const refList = useRef(null);
  const dispatch = useDispatch()
  const [valueSearch, setValueSearch] = useState("");
  const [isShowPopupFilter, setIsShowPopupFilter] = useState(false);
  const [isShowPopupAdd, setIsShowPopupAdd] = useState(false);
  const [isShowHistoryCabinet, setIsShowHistoryCabinet] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [lockerInfoHistory , setLockerInfoHistory] = useState({})

  const { listLockers, condFilters, branchCode, isSearch } = useSelector((state) => ({
    listLockers: state.lockerReducer.listLockers,
    condFilters: state.lockerReducer.condFilters,
    // clusterInfo: state.clusterReducer.clusterInfo,
    isSearch: state.lockerReducer.isSearch,
    branchCode: state.login.dataLogin.branchCode,
    // listClusters: state.clusterReducer.listClusters
  }))

  const callbackSuccess = () => {}
  const callbackError = () => {}

  useEffect(()=>{
    setValueSearch(condFilters.quickSearchData)
  },[])

  // useEffect(() => {
  //   clusterInfo?.kioskId && dispatch(getClusterListByKioskID({kioskID: clusterInfo?.kioskId, branchCode: branchCode} ,callbackSuccess, callbackError))
  // },[])
  // console.log(listClusters)

  // fix không trùng STT hộc tủ trong 1 kiosk, lấy ra danh sách hộc tủ hiện có
  // let arrClusterNow = kioskInfo?.lockerClusterList?.map((item)=>item.lockerList) || []
  // let arrLockerNow = arrClusterNow.flat(Infinity).map((item)=>item.lockerID)
  // let arrLockerNow = []

  useEffect(() => {
    if(!isSearch){
      setValueSearch("")
    }
  },[isSearch])

  // useEffect(()=>{
  //   dispatch(getInfoByDocID({docID: cabinetRangeId}))
  //   return ()=>{
  //     dispatch(resetGetInfoByDocID())
  //   }
  // },[])
  
  const _handleSearch = () => {
    setIsSearching(true)
    dispatch(resetListLocker())
    dispatch(getListLockerAction({
      branchCode: branchCode,
      filterFrom: "",
      filterSize: 100,
      quickSearchData: valueSearch,
      lockerType: condFilters?.lockerType,
      kioskID: clusterInfo?.kioskId,
      clusterID: clusterInfo?.clusterId,
      lockerStatus: condFilters?.lockerStatus
    }), false, callbackSuccess, callbackError)
  };

  const _handleClickBtn = () => {
    setIsShowPopupAdd((pre) => !pre);
  };
  
  useEffect(() => {
    let timer = setTimeout(()=>{
      if (valueSearch){
        _handleSearch()
        return
      }
      if (clusterInfo?.clusterId?.length){
        dispatch(getListLockerAction({
          branchCode: branchCode,
          filterFrom: "",
          filterSize: 100,
          quickSearchData: "",
          lockerType: condFilters?.lockerType,
          kioskID: clusterInfo?.kioskId,
          clusterID: clusterInfo?.clusterId,
          lockerStatus: condFilters?.lockerStatus
        },
        false,
        callbackSuccess,
        callbackError
        ))
      }
      
    },500)
    
    return ()=>{
      dispatch(resetListLocker())
      clearTimeout(timer)
    }
  },[valueSearch, clusterInfo])

  useScrollEnd(
    refList,
    (callback) => {
      dispatch(
        getListLockerAction(
          {
            branchCode: branchCode,
            filterFrom: listLockers[listLockers.length -1].id,
            filterSize: 12,
            quickSearchData: condFilters?.quickSearchData,
            lockerType: condFilters?.lockerType,
            kioskID: clusterInfo?.kioskId,
            clusterID: clusterInfo?.clusterId,
            lockerStatus: condFilters?.lockerStatus,
          },
          true,
          callback
        )
      );
    },
    [listLockers?.length],
    [listLockers?.length]
  );

  const tableContent = useMemo(() => (
    listLockers.length === 0 ? (
      <div className="wrap-empty">
        { isSearching && (
          <EmptyDataControl
            IconNonData={<IcNoResult className="ic-no-data" />}
            labelDes="Không có kết quả"
        />
        )}
        { !isSearching && (
          <EmptyDataControl
            IconNonData={<IcNoData className="ic-no-data" />}
            labelDes="Hiện tại chưa có hộc tủ nào"
          />
        )}
        
      </div>
    ) : (
      <div className="wrap-list" >
        <div className="wrap-list-cabinet-kiosk" ref={refList} >
          <div className="wrap-list-cabinet" >
            {listLockers.map((item, index) => (
              <ItemDetailCabinet 
              dataCluster={clusterInfo} 
              item={item} 
              key={index} 
              refList={refList} 
              onClick={() => {
                setIsShowHistoryCabinet(true)
                setLockerInfoHistory({
                  clusterID: item.clusterID, 
                  kioskID: item.kioskID, 
                  lockerID: item.lockerID,
                  vipStatus: item.vipStatus,
                  borrowTime: item.borrowTime,
                  useStatus: item.useStatus,
                  updateTime: item.updateTime
                })
              }}/>
            ))}
          </div>
        </div>
      </div>
    )
  ),[JSON.stringify(listLockers), isSearching])

  return (
    <TableCabinet>
      <div className="detail-title">
        <p className="title-bold">Danh sách hộc tủ</p>
        <InputSearch
          value={valueSearch}
          setValue={setValueSearch}
          type="text"
          placeholder="Nhập số hộc tủ"
          onChange={_handleSearch}
        />
        <WrapButton>
          <Button
            text="Bộ lọc"
            iconBtn={<IcFilterRed />}
            className="btn-filter"
            onClick={() => setIsShowPopupFilter(true)}
          />
          {isShowPopupFilter && (
            <Portal>
              <PopupFilterCabinet valueSearch={valueSearch} setIsSearching = {() => setIsSearching(true)} onClose={() => setIsShowPopupFilter(false)} />
            </Portal>
          )}
          <Button
            text="Thêm mới"
            iconBtn={<IcPlus />}
            className="btn-add"
            onClick={_handleClickBtn}
          />
        </WrapButton>
      </div>
      <div className="detail-container">
        {tableContent}
      </div>
      {isShowPopupAdd && (
        <Portal>
          <PopupAddCabinet dataCluster={clusterInfo} onClickIconClose={() => setIsShowPopupAdd(false)} />
        </Portal>
      )}
      {
        isShowHistoryCabinet && (
          <Portal>
            <PopupHistoryKiosk 
            onClickIconClose={() => setIsShowHistoryCabinet(false)}
            lockerInfoHistory={lockerInfoHistory}
            />
          </Portal>
        )
      }
    </TableCabinet>
  );
};

export default DetailInforCabinet;
