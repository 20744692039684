import React from 'react'
import IcKioskBlue from '../../../../../../../../assets/images/IcKioskBlue'
import IcKioskGray from '../../../../../../../../assets/images/IcKioskGray'
import Input from '../../../../../../../Control/Input'

const iconStore = {
    icon: IcKioskGray,
    iconActive: IcKioskBlue,
    iconError: IcKioskBlue,
}

export default function InputIdKiosk({ value, handleGetValueInput, isDisable, isRequired, handleValidationInput, error }) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleGetValueInput && handleGetValueInput(value)
    }
  return (
    <Input
        error={error}
        heightInput="63px"
        title="ID Kiosk"
        iconInput={iconStore}
        inputType = "TEXT"
        value = {value}
        disabled = { isDisable }
        isRequired = {isRequired}
        required
        onChange = {_handleChangeValue}
        placeholder="ID Kiosk"
        handleValidationInput={handleValidationInput}
    />
  )
}
