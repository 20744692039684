import React, { useRef, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// css
import { OverlayFullScreen } from '../../../../../../MainStyles/main'
import { WrapPopupHistoryKiosk } from './index.styles'

// img
import IcClose from '../../../../../../../assets/images/IcClose'
import IcFilterWhite from '../../../../../../../assets/images/IcFilterWhite'

// component
import Button from '../../../../../../Control/Button'
import DatePickerInput from '../../../../../../Control/DatePickerInput'
import InputDropdownHistoryStatus from './InputDropdownStatus'
import InputDropdownHistoryMember from './InputDropdownMember'

// hook
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'

// data
import { listHistoryStatus } from '../../../../../../../data/listHistoryStatus'
import { listHistoryMember } from '../../../../../../../data/listHistoryMember'

// redux
import { getLockerHistory } from '../../../../../../../redux/locker/action'
import { resetValueSearchCluster } from '../../../../../../../redux/cluster/action'



const PopupHistoryKiosk = ({ onClickIconClose, lockerInfoHistory, valueSearch }) => {

    const dispatch = useDispatch()
    const filterRef = useRef(null)

    const { filterLockerHistory, branchCode } = useSelector((state) => ({
        filterLockerHistory: state.lockerReducer.filterLockerHistory,
        branchCode: state.login.dataLogin.branchCode
    }))
  
    const [filter, setFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            userType: listHistoryMember.filter(item=>item.key === filterLockerHistory.userType)[0],
            actionType: listHistoryStatus.filter(item=>item.key === filterLockerHistory.actionType)[0],
            startTime: filterLockerHistory.startTime,
            endTime: filterLockerHistory.endTime,
            
        }
    )
    
    const _onClosePopup = () => {
        if (filterRef) {
            filterRef.current.classList.remove('show-right-popup')
            filterRef.current.classList.add('hide-right-popup')
        }
        setTimeout(() => {
            onClickIconClose()
        }, 300);
    }

    const _handleGetFromDate = (date) => {
        setFilter({ startTime: date ? moment(date).startOf('day').toDate() : undefined })
    }

    const _handleGetToDate = (date) => {
        setFilter({ endTime: date ? moment(date).endOf('day').toDate() : undefined })
    }

    const _handleGetValueMember = (val) => {
        setFilter({ userType: val })
    }

    const _handleGetValueStatus = (val) => {
        setFilter({ actionType: val })
    }

    const callbackSuccess = () => {}
    const callbackError = () => {}

    const _handleReset = () => {        
        setFilter({
            userType: listHistoryMember[0],
            actionType: listHistoryStatus[0],
            startTime: moment().utc().startOf("month").toDate(),
            endTime: moment(new Date()).endOf("day").toDate(),
        })
        dispatch(resetValueSearchCluster())
        dispatch(getLockerHistory({
            kioskID: lockerInfoHistory?.kioskID,
            clusterID: lockerInfoHistory?.clusterID,
            lockerID: lockerInfoHistory?.lockerID,
            branchCode: branchCode,
            filterFrom: "",
            filterSize: 15,
            quickSearchData: valueSearch,
            userType: listHistoryMember[0].key,
            actionType: listHistoryStatus[0].key, 
            startTime: moment().utc().startOf("month").toDate(),
            endTime: moment(new Date()).endOf("day").toDate()
        },false, callbackSuccess , callbackError
        ))
    }

    

    const _handleFilter = () => {
        dispatch(getLockerHistory({
            branchCode: branchCode,
            filterFrom: "",
            filterSize: 15,
            quickSearchData: valueSearch,
            userType: filter?.userType?.key,
            actionType: filter?.actionType?.key,
            kioskID: lockerInfoHistory?.kioskID,
            clusterID: lockerInfoHistory?.clusterID,
            lockerID: lockerInfoHistory?.lockerID,
            startTime: filter?.startTime,
            endTime: filter?.endTime
        },false, callbackSuccess , callbackError
        ))
        _onClosePopup()
    }

    // useOnClickOutside(filterRef, ()=>{
    //     _onClosePopup()
    // })

    return (
        <OverlayFullScreen notUseOverlay={true}>
            <WrapPopupHistoryKiosk className='show-right-popup' ref={filterRef}>
                <div className="wrap-header">
                    <IcClose
                        className='btn-close'
                        onClick={_onClosePopup}
                    />
                    <div className="wrap-btn">
                        <Button
                            className="btn-reset"
                            onClick={_handleReset}
                            text="Reset"
                        />
                        <Button
                            className="btn-filter-range"
                            iconBtn={<IcFilterWhite />}
                            onClick={_handleFilter}
                            text="Lọc dữ liệu"
                        />
                    </div>
                </div>
                <InputDropdownHistoryMember
                    handleGetValueDropdown={_handleGetValueMember}
                    listDropDown={listHistoryMember}
                    objChoice={filter.userType}
                    required={false}
                />
                <InputDropdownHistoryStatus
                    handleGetValueDropdown={_handleGetValueStatus}
                    listDropDown={listHistoryStatus}
                    objChoice={filter.actionType}
                />
                <div className="select-filter">
                    <p className="title">Từ ngày</p>
                    <DatePickerInput
                        selected={filter.startTime}
                        onChange={_handleGetFromDate}
                        maxDate={filter.endTime}
                        placeholder="Chọn ngày"
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                    />
                </div>
                <div className="select-filter">
                    <p className="title">Đến ngày</p>
                    <DatePickerInput
                        selected={filter.endTime}
                        onChange={_handleGetToDate}
                        maxDate={new Date()}
                        minDate={filter.startTime}
                        placeholder="Chọn ngày"
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                    />
                </div>

            </WrapPopupHistoryKiosk>
        </OverlayFullScreen>
    )
}

export default PopupHistoryKiosk
