import React from "react";

export default function IcOutDoor(props) {
  return (
    <svg
      {...props}
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83333 9.91671L12.75 7.00004L9.83333 4.08337"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 7H5.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 12.25H3.41667C3.10725 12.25 2.8105 12.1271 2.59171 11.9083C2.37292 11.6895 2.25 11.3928 2.25 11.0833V2.91667C2.25 2.60725 2.37292 2.3105 2.59171 2.09171C2.8105 1.87292 3.10725 1.75 3.41667 1.75H5.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
