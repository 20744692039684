const FREE = 2
const USING = 1
const ALL = 0
const DEACTIVE = 3

export const listUseStatus = {
    [ALL]: {
        text: "Tất cả",
        key: ALL
    },
    [FREE]:{
        text: 'Còn trống',
        key: FREE
    },
    [USING]:{
        text: 'Đang sử dụng',
        key: USING
    },
    [DEACTIVE]:{
        text: "Ngưng hoạt động",
        key: DEACTIVE
    }
}