export const CONNECTED = 'connected'
export const DISCONNECTED = 'disconnected'
export const PROBLEMATIC = 'problematic'
export const ALL = ''

export const listKioskStatus = {
    [ALL]:{
        key: ALL,
        text: 'Tất cả'
    },
    [CONNECTED]:{
        key: CONNECTED,
        text: 'Đã kết nối'
    },
    [DISCONNECTED]:{
        key: DISCONNECTED,
        text: 'Chưa kết nối'
    },
    // [PROBLEMATIC]:{
    //     key: PROBLEMATIC,
    //     text: 'Có vấn đề'
    // }
}