import styled from "styled-components";


const WrapInputDropdown = styled.div`
    .input-dropdown-title{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #191919;
        margin-bottom: 8px;
        .notice{
            margin-left: 5px;
            color:  #A31E23;
        }
    }
    .wrap-input{
        position: relative;
        height: 40px;
        border: 0.5px solid #B7B7B7;
        box-sizing: border-box;
        border-radius: 4px;  
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        padding: 0px 15px 0px 40px;
        align-items: center;
        cursor: pointer;
        width: 100%;
        &.disable{
            background: #F7F7F7;
            pointer-events: none;
            .text-dropdown{
                color: #B7B7B7!important;
            }
        }
        &.active{
            border: 0.5px solid #33adff;
        }
        .icon-left{
            position: absolute;
            left: 10px;
            top: 10px;
        }
        .placeholder{
            font-family: "Open Sans";
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #BDBDBD;
        }
        .text-dropdown{
            font-family: "Open Sans";
            font-size: 13.5px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #313131;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .arrow-down{
            cursor: pointer;
            flex-shrink: 0;
        }
        .wrap-dropdown{
            width: 100%;
            z-index: 10;
            top: calc(100% + 2px);
        }
        &.error-input{
            border: 0.5px solid #F45C5C;
        }
    }
    .error{
        color: #F45C5C;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        padding: 4px 0 0 15px;
        float: left;
        margin-top: -20px;
    }
`

export {WrapInputDropdown};