import styled from 'styled-components'

const WrapBreadcrumb = styled.div`
    display: flex;
    align-items: center;

    p {
        font-weight: 600;
        font-size: 20px;
        font-family: 'Open Sans';
        font-style: normal;
        font-size: 15px;
        line-height: 20px;
    }
    
    & > p:last-child {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.01em;
        color: #828282;
    }

    & > p:first-child {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.01em;
        color: #191919; 
    }

    .icon-text {
        padding: 10px;
    }
`;

const WrapBreadcrumbAndTool = styled.div`
    height: 64px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    .wrap-breadcrumb-and-tool{
        width: calc(1280px - 160px);
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 390px 1fr;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        // .title-breadcrumb{
        //     font-family: 'Open Sans';
        //     font-style: normal;
        //     font-weight: 700;
        //     font-size: 20px;
        //     line-height: 27px;
        //     letter-spacing: -0.01em;
        //     color: #313131;
        // }
        
    }
`;

const WrapButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button-filter{
        background: #FFFFFF;
        color: #A31E23;
        padding: 8px 24px;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
    }
`

export {
    WrapBreadcrumbAndTool,
    WrapBreadcrumb,
    WrapButton
}