import styled from 'styled-components'

const Bound = styled.div`
    background: #fff;
    width: 100%;
    height: 100%;
    font-family: Open Sans;
    font-style: normal;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    .detail-header{
        display: flex;
        gap: 33px;
        border-bottom: 1px solid #EFEFEF;
        padding: 0px 20px;
        
        .detail-item{
            display: flex;
            align-items: center;
            gap: 8px;
            text-decoration: none;
            cursor: pointer;
            padding: 14px 0 8px 0;
            border-bottom: 2px solid transparent;
            .icon{
                width: 18px;
                height: 18px;
            }
            p{
                color: #191919;
                font-size: 13.5px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.01em;
            }
            &.selected{
                border-bottom: 2px solid #A31E23;
                /* box-sizing: border-box; */
                p{
                    color: #A31E23;
                    font-weight: 600;
                }
            }
        }
    }
`;

export{
    Bound
}