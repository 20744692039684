import React, { useState } from 'react'
//images
import IcAddItem from '../../../../../../../assets/images/IcAddItem'
//actions
import { addClusterAction } from '../../../../../../../redux/cluster/action';
//components
import FormOptionCabinetRange from '../FormOptionCabinetRange'

export default function PopupAddCabinetRange({ onClickIconClose }) {

  const [objLstImg, setObjLstImg] = useState({});
  const [objLstUrlImg, setObjLstUrlImg] = useState({});
  const apiAddCabinetRange = addClusterAction
  
  return (
    <FormOptionCabinetRange
      textBtn="Thêm mới"
      iconBtn={<IcAddItem />}
      objLstImg={objLstImg}
      setObjLstImg={setObjLstImg}
      objLstUrlImg={objLstUrlImg}
      setObjLstUrlImg={setObjLstUrlImg}
      onClickIconClose={onClickIconClose}
      titleOption="Thêm mới Dãy tủ"
      apiHandleAdd={apiAddCabinetRange}
    />
  )
}
