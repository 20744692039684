import React from 'react'

const IcEdit = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.41663 2.33334H2.33329C2.02387 2.33334 1.72713 2.45625 1.50833 2.67504C1.28954 2.89384 1.16663 3.19058 1.16663 3.5V11.6667C1.16663 11.9761 1.28954 12.2728 1.50833 12.4916C1.72713 12.7104 2.02387 12.8333 2.33329 12.8333H10.5C10.8094 12.8333 11.1061 12.7104 11.3249 12.4916C11.5437 12.2728 11.6666 11.9761 11.6666 11.6667V7.58334" stroke="#3D8B4C" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7916 1.45833C11.0237 1.22627 11.3384 1.09589 11.6666 1.09589C11.9948 1.09589 12.3096 1.22627 12.5416 1.45833C12.7737 1.6904 12.9041 2.00514 12.9041 2.33333C12.9041 2.66152 12.7737 2.97627 12.5416 3.20833L6.99996 8.75L4.66663 9.33333L5.24996 7L10.7916 1.45833Z" stroke="#3D8B4C" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcEdit
