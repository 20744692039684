
export const GET_LIST_CLUSTER = 'GET_LIST_CLUSTER'
export const GET_LIST_CLUSTER_ERR = 'GET_LIST_CLUSTER_ERR'

export const RESET_LIST_CLUSTER = 'RESET_LIST_CLUSTER'

export const ADD_CLUSTER_SUCCESS = "ADD_CLUSTER_SUCCESS"
export const ADD_CLUSTER_ERR = "ADD_CLUSTER_ERR"

export const DEL_CLUSTER_SUCCESS = 'DEL_CLUSTER_SUCCESS'
export const DEL_CLUSTER_ERR = 'DEL_CLUSTER_ERR'

export const UPDATE_CLUSTER_SUCCESS = 'UPDATE_CLUSTER_SUCCESS'
export const UPDATE_CLUSTER_ERR = 'UPDATE_CLUSTER_ERR'

export const RESET_LIST_NOT_YET = 'RESET_LIST_NOT_YET'

export const RESET_VALUE_SEARCH = 'RESET_VALUE_SEARCH'

export const GET_INFO_BY_DOC_ID = 'GET_INFO_BY_DOC_ID'
export const GET_INFO_BY_DOC_ID_ERR = 'GET_INFO_BY_DOC_ID_ERR'
export const RESET_INFO_BY_DOC_ID = 'RESET_INFO_BY_DOC_ID'

// export const GET_CLUSTER_LIST_BY_KIOSK_ID = 'GET_CLUSTER_LIST_BY_KIOSK_ID'
// export const GET_CLUSTER_LIST_BY_KIOSK_ID_FAIL = 'GET_CLUSTER_LIST_BY_KIOSK_ID_FAIL'


