
import * as types from './types'


const initialState = {
    listClusters: [],
    totalClusters: 0,
    isSearch: true,
    condFilters: {
        roomType: "",
        kioskName:"",
        filterFrom: "",
        filterSize: 9,
        quickSearchData: "",
        branchCode: ""
    },
    clusterInfo:{},
    isSearching: false
}

const clusterReducer = (state= initialState, action) => {
    switch (action.type){

        case types.RESET_LIST_CLUSTER: {
            return {
                ...state,
                condFilters: state.condFilters,
                listClusters: [],
                clusterInfo:{},
                isSearching: false,
                type: action.type 
            }
        }
        case types.GET_LIST_CLUSTER:
            if (action.payload.condFilters.roomType !== initialState.condFilters.roomType || action.payload.condFilters.kioskName !== initialState.condFilters.kioskName || action.payload.condFilters.quickSearchData !== initialState.condFilters.quickSearchData){
                state.isSearching = true
            }
            return {                
                ...state,
                ...action.payload,
                listClusters: action.payload.isScroll
                    ? [...state.listClusters, ...action.payload.listClusters]
                    : action.payload.listClusters,
                isSearch: true,
                type: action.type
            };
        case types.ADD_CLUSTER_SUCCESS:
            return {
                ...state,
                listClusters: [],
                condFilters: initialState.condFilters,
                type: action.type
            }
        case types.UPDATE_CLUSTER_SUCCESS:
            return {
                ...state,
                listClusters: [],
                condFilters: initialState.condFilters,
                type: action.type
            }
        case types.DEL_CLUSTER_SUCCESS:
            return {
                ...state,
                listClusters: [],
                condFilters: initialState.condFilters,
                type: action.type
            }
        case types.RESET_VALUE_SEARCH:
            return {
                ...state,
                isSearch: false,
                isSearching: false,
                type: action.type
            }
        case types.GET_INFO_BY_DOC_ID:
            return {
                ...state,
                clusterInfo: action.clusterInfo,
                type: action.type
            }
        
        case types.RESET_INFO_BY_DOC_ID:
            return {
                ...state,
                clusterInfo: {},
                type: action.type
            } 
        
        // case types.GET_CLUSTER_LIST_BY_KIOSK_ID:
        //     return {
        //         ...state,
        //         listClusters: action.listClusters,
        //         type: action.type
        //     }
            
        default : return state;
    }




}
export default clusterReducer;