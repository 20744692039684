import React from "react";

export default function IcIdCabinetRangeBlue(props) {
  return (
    <svg
      {...props}
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 11.25H15"
        stroke="#33ADFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6.75H15"
        stroke="#33ADFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.25L10.5 15.75"
        stroke="#33ADFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 2.25L6 15.75"
        stroke="#33ADFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
