import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// component
import Login from "./components/Login";
import Main from "./components/Main";
// page
import NewFaceBiometric from "./components/Main/Page/NewFaceBiometric";
import FaceBiometric from "./components/Main/Page/FaceBiometric";
import KioskManage from "./components/Main/Page/KioskManage";
import CabinetRangeManage from "./components/Main/Page/CabinetRangeManage";
import AdvertismentManage from "./components/Main/Page/AdvertismentManage";

import PrivateRoute from "./components/Control/PrivateRoute";

// routes
import {
  main,
  newFaceBiometric,
  faceBiometric,
  kioskManage,
  cabinetRangeManage,
  advertismentManage,
  kioskDetails,
  cabinetRangeDetails
} from "./constant/routes";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path={`/${main}/*`}
        element={
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        }
      >
        <Route path={newFaceBiometric}
          element={
            <PrivateRoute>
              <NewFaceBiometric />
            </PrivateRoute>
          }
        />
        <Route path={`${newFaceBiometric}/:salesforceID`}
          element={
            <PrivateRoute>
              <NewFaceBiometric />
            </PrivateRoute>
          }
        />
        <Route path={faceBiometric}
          element={
            <PrivateRoute>
              <FaceBiometric />
            </PrivateRoute>
          }
        />
        <Route path={kioskManage}
          element={
            <PrivateRoute>
              <KioskManage />
            </PrivateRoute>
          }
        />
        <Route path={`${kioskManage}/${kioskDetails()}`}
          element={
            <PrivateRoute>
              <KioskManage />
            </PrivateRoute>
          }
        />
        <Route path={cabinetRangeManage}
          element={
            <PrivateRoute>
              <CabinetRangeManage />
            </PrivateRoute>
          }
        />
        <Route path={`${cabinetRangeManage}/${cabinetRangeDetails()}`}
          element={
            <PrivateRoute>
              <CabinetRangeManage />
            </PrivateRoute>
          }
        />
        <Route path={advertismentManage}
          element={
            <PrivateRoute>
              <AdvertismentManage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to={`/${main}/${newFaceBiometric}`} />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
