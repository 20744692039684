import React from 'react'

const IcBtnCancel = ({...props}) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 3.5L4 10.5" stroke="#A31E23" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4 3.5L11 10.5" stroke="#A31E23" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBtnCancel
