import React, { useRef } from "react";
import { useMemo } from "react";
import IcClose from "../../../../../assets/images/IcClose";
import { useOnClickOutside } from "../../../../../hook/useClickOutside";
import EmptyDataControl from "../../../../Control/EmptyDataControl";
import { OverlayFullScreen } from "../../../../MainStyles/main";
import {
    TableContentCheckFaceEnroll,
  TableHeaderCheckFaceEnroll,
  WrapContentCheckFace,
  WrapContentCheckFaceImg,
  WrapHeaderCheckFace,
  WrapNewCheckFaceAccess,
} from "./index.styles";
import ItemNewCheckFace from "./ItemNewCheckFace";

const PopupCheckFaceAccess = ({ onClickIconClose, dataHeader, urlMemberPhoto, identifyList }) => {
  const listRef = useRef(null);
  const filterRef = useRef(null);

  useOnClickOutside(filterRef, () => {
    onClickIconClose();
  });

  const tableContent = useMemo(() =>
    identifyList?.length === 0 ? (
        <div className="wrap-empty">
          <EmptyDataControl labelDes="Hiện tại chưa có lượt trùng nào" />
        </div>
      ) : (
        <div className="wrap-list-trans" ref={listRef}>
          <div className="list-trans">
            {identifyList?.map((item, i) => (
              <ItemNewCheckFace
                key={i}
                index={i}
                className="history-item"
                item={item}
                refList={listRef}
                urlMemberPhoto={urlMemberPhoto}
              />
            ))}
          </div>
        </div>
      ),
    [JSON.stringify(identifyList)]
  );

  return (
    <OverlayFullScreen>
      <WrapNewCheckFaceAccess>
        <WrapHeaderCheckFace>
          <div className="check-face-header">
            <p className="header-value">Thông tin trùng khuôn mặt</p>
            <IcClose
              className="btn-close"
              onClick={onClickIconClose}
            />
          </div>
          <span>Thông tin chi tiết kiểm tra trùng</span>
        </WrapHeaderCheckFace>
        <WrapContentCheckFace>
          <WrapContentCheckFaceImg>
            <p className="check-face-title">Khuôn mặt đăng ký</p>
            <div className="check-face-img">
              <img src={urlMemberPhoto} alt="citigym" />
            </div>
          </WrapContentCheckFaceImg>
          <TableHeaderCheckFaceEnroll>
            {identifyList?.length !== 0 ? (
              dataHeader.map((item, i) => (
                <div className="table-header" key={i}>
                  {item}
                </div>
              ))
            ) : (
              <></>
            )}
          </TableHeaderCheckFaceEnroll>
          <TableContentCheckFaceEnroll>
            {tableContent}
          </TableContentCheckFaceEnroll>
        </WrapContentCheckFace>
      </WrapNewCheckFaceAccess>
    </OverlayFullScreen>
  );
};

export default PopupCheckFaceAccess;
