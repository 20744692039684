import React, { useEffect, useRef, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//style
import { OverlayFullScreen } from '../../../../MainStyles/main'
import { WrapPopupFilter } from './index.style'

//image
import IcClose from '../../../../../assets/images/IcClose'
import IcFilterWhite from '../../../../../assets/images/IcFilterWhite'

//component
import Button from '../../../../Control/Button'
import { listKioskStatus } from '../../../../../data/listKioskStatus'
import { listRoom } from '../../../../../data/listRoom'
import InputDropdownStatusKiosk from './InputDropdownStatusKiosk'
import InputDropdownRoomKiosk from '../Listing/OptionKiosk/FormOptionKiosk/InputDropdownRoomKiosk'
import { useOnClickOutside } from '../../../../../hook/useClickOutside'
import { getListKioskAction, resetValueSearchKiosk } from '../../../../../redux/kiosk/action'

const PopupFilterKiosk = ({ onClose, valueSearch }) => {

    const dispatch = useDispatch()
    const filterRef = useRef(null)
    const {total, branchCode,condFilters} = useSelector(state=>({
        total: state.kioskReducer.total,
        branchCode: state.login.dataLogin.branchCode,
        condFilters: state.kioskReducer.condFilters
    }))

    const [filter, setFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            roomKiosk: listRoom[condFilters?.roomType],
            status: listKioskStatus[condFilters?.status],
        }
    )
   
    const _onClosePopup = () => {
        if (filterRef) {
            filterRef.current.classList.remove('show-right-popup')
            filterRef.current.classList.add('hide-right-popup')
        }
        setTimeout(() => {
            onClose()
        }, 300);
    }

    const _handleGetValueRoomKiosk = (val) => {
        setFilter({roomKiosk: val })
    }

    const _handleGetValueStatus = (val) => {
        setFilter({ status: val })
    }

    const _handleReset = () => {        
        setFilter({
            roomKiosk: Object.values(listRoom)[0],
            status: Object.values(listKioskStatus)[0]
        })
        dispatch(resetValueSearchKiosk())
        dispatch(getListKioskAction({branchCode: branchCode, filterFrom: "", filterSize: total ? total : 100, quickSearchData: "", status: "", roomType: ""}))
    }

    const _handleFilter = () => {
        
        let dataFilter = {
            branchCode: branchCode,
            filterFrom: "",
            filterSize: total ? total : 100,
            quickSearchData: valueSearch,
            roomType: filter?.roomKiosk?.key,
            status: filter?.status?.key,
        }
        dispatch(getListKioskAction(dataFilter))
    }

    useOnClickOutside(filterRef, ()=>{
        _onClosePopup()
    })
    
    return (
        <OverlayFullScreen notUseOverlay={true}>
            <WrapPopupFilter className='show-right-popup' ref={filterRef}>
                <div className="wrap-header">
                    <IcClose
                        className='btn-close'
                        onClick={_onClosePopup}
                    />
                    <div className="wrap-btn">
                        <Button
                            className="btn-reset"
                            onClick={_handleReset}
                            text="Reset"
                        />
                        <Button
                            className="btn-filter"
                            iconBtn={<IcFilterWhite />}
                            onClick={_handleFilter}
                            text="Lọc dữ liệu"
                        />
                    </div>
                </div>
                <InputDropdownRoomKiosk
                    handleGetValueDropdown={_handleGetValueRoomKiosk}
                    listDropDown={Object.values(listRoom)}
                    objChoice={filter.roomKiosk}
                    required={false}
                />
                <InputDropdownStatusKiosk
                    handleGetValueDropdown={_handleGetValueStatus}
                    listDropDown={Object.values(listKioskStatus)}
                    objChoice={filter.status}
                />
            </WrapPopupFilter>
        </OverlayFullScreen>
    )
}

export default PopupFilterKiosk
