import React from "react";

export default function IcInputWifiOff(props) {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3620_10244)">
        <path
          d="M0.666748 0.666666L15.3334 15.3333"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13.3333H8.00667"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.68677 10.74C6.36357 10.2592 7.17322 10.0008 8.00343 10.0008C8.83365 10.0008 9.6433 10.2592 10.3201 10.74"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.1467 7.37333C11.6928 7.63983 12.2034 7.97353 12.6667 8.36667"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33325 8.36667C4.32131 7.54055 5.5105 6.99081 6.77992 6.77333"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.946777 6C1.87155 5.18257 2.93191 4.53282 4.08011 4.08"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.13989 3.36667C8.56817 3.25158 10.005 3.42544 11.3646 3.87788C12.7242 4.33032 13.9787 5.05205 15.0532 6"
          stroke="#828282"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3620_10244">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
