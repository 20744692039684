import React from "react";

export default function IcBtnUpdateKioskRed(props) {
  return (
    <svg
      {...props}
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H32C34.2091 0 36 1.79086 36 4V32C36 34.2091 34.2091 36 32 36H4C1.79086 36 0 34.2091 0 32V4Z"
        fill="#A31E23"
      />
      <path
        d="M26.25 24V19.5H21.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 12V16.5H14.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3675 15.75C23.9871 14.6751 23.3407 13.7141 22.4884 12.9566C21.6361 12.1991 20.6059 11.6698 19.4938 11.4182C18.3817 11.1666 17.2239 11.2008 16.1286 11.5176C15.0333 11.8344 14.0361 12.4235 13.23 13.23L9.75 16.5M26.25 19.5L22.77 22.77C21.9639 23.5765 20.9667 24.1656 19.8714 24.4824C18.7761 24.7992 17.6183 24.8334 16.5062 24.5818C15.3941 24.3302 14.3639 23.8009 13.5116 23.0434C12.6593 22.2859 12.0129 21.3249 11.6325 20.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
