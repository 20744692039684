import React from 'react'

export default function IcSearchRed(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z" stroke="#A31E23" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.75 15.7498L12.4875 12.4873" stroke="#A31E23" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
