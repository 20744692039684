import React from "react";

export default function IcStatusNonActive(props) {
  return (
    <svg
      {...props}
      width={30}
      height={18}
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3686_35487)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.32765 0.553507C10.3512 0.495443 11.3735 0.500088 12.3971 0.500088C12.4041 0.500088 17.5835 0.500088 17.5835 0.500088C18.6271 0.500088 19.6494 0.495443 20.6724 0.553507C21.6024 0.605765 22.5082 0.717249 23.41 0.966344C25.3082 1.49008 26.9659 2.58401 28.1641 4.13491C29.3553 5.67593 30 7.56244 30 9.49946C30 11.4388 29.3553 13.3242 28.1641 14.8652C26.9659 16.4155 25.3082 17.51 23.41 18.0337C22.5082 18.2828 21.6024 18.3937 20.6724 18.4466C19.6494 18.5046 18.6271 18.4994 17.6035 18.4994C17.5965 18.4994 12.4159 18.5 12.4159 18.5C11.3735 18.4994 10.3512 18.5046 9.32765 18.4466C8.39824 18.3937 7.49235 18.2828 6.59059 18.0337C4.69235 17.51 3.03471 16.4155 1.83647 14.8652C0.645294 13.3242 0 11.4388 0 9.50004C0 7.56244 0.645294 5.67593 1.83647 4.13491C3.03471 2.58401 4.69235 1.49008 6.59059 0.966344C7.49235 0.717249 8.39824 0.605765 9.32765 0.553507Z"
          fill="#999999"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 17.5C13.4183 17.5 17 13.9183 17 9.5C17 5.08172 13.4183 1.5 9 1.5C4.58172 1.5 1 5.08172 1 9.5C1 13.9183 4.58172 17.5 9 17.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3686_35487">
          <rect
            width={30}
            height={18}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
