import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

//styles
import { WrapHeader } from './index.styles'

//image
import LogoCitiGymWhite from '../../../assets/images/LogoCitiGymWhite'
import IcAvatarDefault from '../../../assets/images/IcAvatarDefault'
import IcLogOutBlack from '../../../assets/images/IcLogOutBlack'

//action
import PopupLogout from './PopupLogout'
import Portal from '../../Control/Portal'
import { main } from '../../../constant/routes'

export default function Header() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false)

  const { dataLogin, loggedIn } = useSelector(state => ({
    dataLogin: state.login.dataLogin,
    loggedIn: state.login.loggedIn
  }))

  useEffect(() => {
    if (loggedIn) return
    navigate('/')
    return () => {

    }
  }, [loggedIn])

  const handleClose = () => {
    setIsShow(false)
  }

  return (
    <WrapHeader>
      <div className='wrap-inner'>
        <Link to={`/${main}`} className='logo-header'>
          <LogoCitiGymWhite />
        </Link>
        <div className="user">
          <p className="user_name">{dataLogin.username}</p>
          <IcAvatarDefault className="ic-logout" />
          <IcLogOutBlack className="ic-logout" onClick={()=>setIsShow(true)} />
        </div>
      </div>
      { isShow && (
        <Portal>
          <PopupLogout
            handleClose={handleClose}
          />
        </Portal>
      )}
    </WrapHeader>
  )
}
