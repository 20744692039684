import React from 'react'

// img
import IcTable from '../../../../../../../../assets/images/IcTable'

//control
import InputDropdown from '../../../../../../../Control/InputDropdown'

const iconStore = {
    icon: IcTable,
    iconActive: IcTable,
    iconError: IcTable,
}

const InputDropdownRange = ({ handleGetValueDropdown, listDropDown, objChoice, handleValidationInput }) => {
    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }

    return (
        <InputDropdown
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Tên dãy tủ"
            required={false}
            dataFilterOption={listDropDown}
            objChoice={objChoice}
            handleValidationInput={handleValidationInput}
            isLengthStringBig={true}
        />
    )
}

export default InputDropdownRange
