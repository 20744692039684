import styled, { css } from 'styled-components'

const HEIGHT_ITEM = 42;

const WrapDropdownList = styled.div`
    position: absolute;
    top: calc(100% + ${props => props.alignTop || "18px"});
    left: 0;
    /* left: 50%; */
    /* transform: translate(-50%, 0); */
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    
    ${
        props => props.maxItemShow
            ? css`
                overflow-y: auto;
                /* overflow-x: hidden; */
                max-height: ${HEIGHT_ITEM * props.maxItemShow}px;
            `
            : ''
    }

    & > * {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        box-sizing: border-box;
        padding: 0 10px 0 14px;
        height: ${HEIGHT_ITEM}px;
        box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
        
        span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 13.5px;
            line-height: 18px;
            color: #191919;
        }

        .ic-checkbox{
            margin-right: 6px;
        }

        .ic-check-mark{
            position: absolute;
            right: 10px;
        }

        &:hover { 
            cursor: pointer;
        }
    }
`;

export {
    WrapDropdownList
}