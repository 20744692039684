import React, { useMemo, useRef } from "react";
//css
import { TableContentHistory, TableHistoryKiosk } from "./index.styles";
import { OverlayFullScreen } from "../../../../../MainStyles/main";

// img
import IcClose from "../../../../../../assets/images/IcClose";
import IcFilterRed from "../../../../../../assets/images/IcFilterRed";

// component
import InputSearch from "../../../../../Control/InputSearch";
import Button from "../../../../../Control/Button";
import { useState } from "react";
import ItemHistoryKiosk from "./ItemHistoryKiosk";
import EmptyDataControl from "../../../../../Control/EmptyDataControl";
import IcNoData from "../../../../../../assets/images/IcNoData";
import { useOnClickOutside } from "../../../../../../hook/useClickOutside";
import PopupHistoryKiosk from "./PopupHistoryKiosk";
import Portal from "../../../../../Control/Portal";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getLockerHistory,
  resetLockerHistory,
} from "../../../../../../redux/locker/action";
import { convertDateTime } from "../../../../../../helper/convertDateTime";
import converTime from "../../../../../../helper/converTime";
import IcNoResult from "../../../../../../assets/images/IcNoResult";
import useScrollEnd from "../../../../../../hook/useScrollEnd";

const dataHeader = [
  "Mã hội viên",
  "Hình STH",
  "Tên người dùng",
  "Đối tượng",
  "Thao tác",
  "Thời gian",
];

// const list = dataListHistory


const HistoryKioskRange = ({ onClickIconClose, lockerInfoHistory }) => {
  
  let newTime = Date.now();

  const dispatch = useDispatch();
  const listRef = useRef(null);
  const filterRef = useRef(null);
  const [valueSearch, setValueSearch] = useState("");
  const [showPopupHistory, setShowPopupHistory] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { filterLockerHistory, branchCode, listLockerHistory, isSearch } =
    useSelector((state) => ({
      branchCode: state.login.dataLogin.branchCode,
      filterLockerHistory: state.lockerReducer.filterLockerHistory,
      listLockerHistory: state.lockerReducer.listLockerHistory,
      isSearch: state.lockerReducer.isSearch,
    }));
  const _handleSearch = () => {
    setIsSearching(true);
    dispatch(
      getLockerHistory(
        {
          ...filterLockerHistory,
          branchCode: branchCode,
          kioskID: lockerInfoHistory?.kioskID,
          clusterID: lockerInfoHistory?.clusterID,
          lockerID: lockerInfoHistory?.lockerID,
          quickSearchData: valueSearch,
        },
        false
      )
    );
  };

  const callbackSuccess = () => {};
  const callbackError = () => {};

  useEffect(() => {
    dispatch(
      getLockerHistory(
        {
          ...filterLockerHistory,
          kioskID: lockerInfoHistory?.kioskID,
          clusterID: lockerInfoHistory?.clusterID,
          lockerID: lockerInfoHistory?.lockerID,
          branchCode: branchCode,
        },
        false,
        callbackSuccess,
        callbackError
      )
    );
    return () => {
      dispatch(resetLockerHistory());
    };
  }, []);

  useScrollEnd(
    listRef,
    (callback) => {
      dispatch(
        getLockerHistory(
          {
            ...filterLockerHistory,
            kioskID: lockerInfoHistory?.kioskID,
            clusterID: lockerInfoHistory?.clusterID,
            lockerID: lockerInfoHistory?.lockerID,
            branchCode: branchCode,
            filterFrom: listLockerHistory[listLockerHistory.length - 1].id
          },
          true,
          callback
        )
      );
    },
    [listLockerHistory],
    [listLockerHistory?.length]
  );

  useEffect(() => {
    if (!isSearch) {
      setValueSearch("");
    }
  }, [isSearch]);

  const tableContent = useMemo(
    () =>
      listLockerHistory.length === 0 ? (
        <div className="wrap-empty">
          {isSearching && (
            <EmptyDataControl
              IconNonData={<IcNoResult className="ic-no-data" />}
              labelDes="Không có kết quả"
            />
          )}
          {!isSearching && (
            <EmptyDataControl
              IconNonData={<IcNoData className="ic-no-data" />}
              labelDes="Hiện tại chưa có hộc tủ nào"
            />
          )}
        </div>
      ) : (
        <div className="wrap-list-trans" ref={listRef}>
          <div className="list-trans">
            {listLockerHistory.map((item, i) => (
              <ItemHistoryKiosk
                className="history-item"
                key={i}
                item={item}
                refList={listRef}
              />
            ))}
          </div>
        </div>
      ),
    [JSON.stringify(listLockerHistory)]
  );

  useOnClickOutside(filterRef, () => {
    onClickIconClose();
  });

  return (
    <OverlayFullScreen>
      <TableHistoryKiosk>
        <div className="history-header">
          <p className="value">Lịch sử</p>
          <IcClose className="btn-close" onClick={onClickIconClose} />
        </div>
        <span>Thông tin lịch sử người sử dụng hộc tủ</span>
        <div className="history-info">
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory?.lockerID ? lockerInfoHistory?.lockerID : "-"}
            </p>
            <p>ID hộc tủ</p>
          </div>
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory?.clusterID
                ? lockerInfoHistory?.clusterID
                : "-"}
            </p>
            <p>ID Dãy tủ</p>
          </div>
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory?.kioskID ? lockerInfoHistory?.kioskID : "-"}
            </p>
            <p>ID Kiosk</p>
          </div>
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory?.vipStatus === true ? "Tủ Vip" : "Tủ Thường"}
            </p>
            <p>Loại tủ</p>
          </div>
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory.useStatus === true
                ? "Đang sử dụng"
                : "Đang trống"}
            </p>
            <p>Trạng thái</p>
          </div>
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory.useStatus === true
                ? converTime(newTime, lockerInfoHistory.borrowTime , "phút")
                : "-"}
            </p>
            <p>Thời gian sử dụng</p>
          </div>
          <div className="history-info-item">
            <p className="bold">
              {lockerInfoHistory?.updateTime
                ? convertDateTime(
                  lockerInfoHistory?.updateTime,
                    "DD/MM/YYYY HH:mm"
                  )
                : "-"}
            </p>
            <p>Thời gian thao tác gần nhất</p>
          </div>
        </div>
        <div className="history-filter">
          <InputSearch
            value={valueSearch}
            setValue={setValueSearch}
            isOnChange={true}
            type="text"
            placeholder="Nhập mã hội viên, tên hội viên..."
            onChange={_handleSearch}
          />
          <Button
            text="Bộ lọc"
            iconBtn={<IcFilterRed />}
            className="btn-filter"
            onClick={() => setShowPopupHistory(true)}
          />
        </div>
        <div className="history-table">
          <div className="history-title">
            {dataHeader.map((item, index) => (
              <div className="table-title" key={index}>
                {item}
              </div>
            ))}
          </div>
          <TableContentHistory>{tableContent}</TableContentHistory>
        </div>
      </TableHistoryKiosk>
      {showPopupHistory && (
        <Portal>
          <PopupHistoryKiosk
            valueSearch={valueSearch}
            lockerInfoHistory={lockerInfoHistory}
            onClickIconClose={() => setShowPopupHistory(false)}
            setIsSearching={() => setIsSearching(true)}
          />
        </Portal>
      )}
    </OverlayFullScreen>
  );
};

export default HistoryKioskRange;
