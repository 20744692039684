import { CHECK_IN, CHECK_OUT } from "../../components/Main/ControlAutoDoor/PopupControlDoor";
import { GET_LIST_DEVICE, GET_STATUS_DEVICE } from "./type";

const initialState = {
  listDeviceIo : [],
  deviceCheckIn: {},
  deviceCheckOut: {},
  dataDevice : {}
};

const controlAutoDoor = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_DEVICE: {
        return {
            listDeviceIo: action.data,
            deviceCheckIn: action.data?.filter((item) => item.location === CHECK_IN)[0],
            deviceCheckOut: action.data?.filter((item) => item.location === CHECK_OUT)[0]
        }
    }
    case GET_STATUS_DEVICE: {
      return {
        ...state,
        dataDevice: action.payload.data
      }
    }
    default:
      return state;
  }
};

export default controlAutoDoor;
