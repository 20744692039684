import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import IcMoreOptionWhite from '../../../../../../../assets/images/IcMoreOptionWhite'

//images
import IcEdit from '../../../../../../../assets/images/IcEdit'
import IcTrash from '../../../../../../../assets/images/IcTrash'

//component
import MoreOption from '../../../../../../Control/MoreOption'
import PopupWarning from '../../../../../../Control/PopupWarning'
import Portal from '../../../../../../Control/Portal'
import PopupDeleteCabinet from './PopupDeleteCabinet'
import PopupEditCabinet from './PopupEditCabinet'

export const EDIT_CABINET = 'EDIT_CABINET'
export const DEL_CABINET = "DEL_CABINET"
export const WARNING_EDIT = 'WARNING_EDIT'
export const WARNING_DEL = 'WARNING_DEL'


const OptionCabinet = ({ dataCabinet, refList, dataCluster}) => {

    const dispatch = useDispatch()
    const [keyShowForm, setKeyShowForm] = useState('')

    const handleEdit = () => {
        if (dataCabinet?.useStatus){
            setKeyShowForm(WARNING_EDIT)
            return
        }
        setKeyShowForm(EDIT_CABINET)
    }

    const handleDelete = () => {
        if (dataCabinet?.useStatus){
            setKeyShowForm(WARNING_DEL)
            return
        }
        setKeyShowForm(DEL_CABINET)
    }

    const handleClose = (e) => {
        // e.stopPropagation()
        setKeyShowForm('')
        
    }

    const dataMoreOption = [
        {
            Icon: IcEdit,
            text: "Chỉnh sửa",
            handleOption: handleEdit
        },
        {
            Icon: IcTrash,
            text: "Xoá",
            handleOption: handleDelete
        }
    ]

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
                iconOption={<IcMoreOptionWhite/>}
            />
            { keyShowForm === EDIT_CABINET && (
                <Portal>
                    <PopupEditCabinet
                        dataCluster={dataCluster}
                        onClickIconClose = {handleClose}
                        dataCabinet = {dataCabinet}
                    />
                </Portal>
            )}
            { keyShowForm === DEL_CABINET && (
                <Portal>
                    <PopupDeleteCabinet
                        dataCabinet={dataCabinet}
                        handleClose={handleClose}
                    />
                </Portal>
            )}
            { keyShowForm === WARNING_EDIT && (
                <Portal>
                    <PopupWarning 
                        title="Cảnh báo chỉnh sửa Hộc tủ"
                        subTitle="Hộc tủ hiện đang được sử dụng. Bạn không thể chỉnh sửa."
                        handleClose={handleClose}
                    />
                </Portal>
            )}
            { keyShowForm === WARNING_DEL && (
                <Portal>
                    <PopupWarning 
                        title="Cảnh báo xóa Hộc tủ"
                        subTitle="Hộc tủ hiện đang sử dụng. Bạn không thể xóa. Vui lòng kiểm tra và thử lại sau!"
                        handleClose={handleClose}
                    />
                </Portal>
            )}
        </>
    )
}

export default OptionCabinet
