import React from 'react'

const IcFilterRed = (props) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 1.75H1.66675L6.33342 7.26833V11.0833L8.66675 12.25V7.26833L13.3334 1.75Z" stroke="#A31E23" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcFilterRed
