import React from 'react'
import IcClosedWhite from '../../../../../../../assets/images/IcClosedWhite'
import { apiGetPhoto } from '../../../../../../../constant/host'
import { OverlayFullScreen } from '../../../../../../MainStyles/main'
import { WrapFaceItem } from './index.style'

const PopupFace = ({urlMemberPhoto, item, onClickIconClose}) => {

    return (
        <OverlayFullScreen>
            <WrapFaceItem>
                <div className="face-icon">
                    <IcClosedWhite
                    className="btn-close"
                    onClick={onClickIconClose}
                    /> 
                </div>
                <div className="show-face">
                    <img src={urlMemberPhoto} alt="" />
                    <img src={apiGetPhoto(item?.avatarURL)} alt="" />
                </div>
            </WrapFaceItem>
        </OverlayFullScreen>
    )
}

export default PopupFace
