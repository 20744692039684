
export const GET_LIST_KIOSK = 'GET_LIST_KIOSK'
export const GET_LIST_KIOSK_ERR = 'GET_LIST_KIOSK_ERR'

export const RESET_LIST_KIOSK = 'RESET_LIST_KIOSK'

export const ADD_KIOSK_SUCCESS = "ADD_KIOSK_SUCCESS"
export const ADD_KIOSK_ERR = "ADD_KIOSK_ERR"

export const DEL_KIOSK_SUCCESS = 'DEL_KIOSK_SUCCESS'
export const DEL_KIOSK_ERR = 'DEL_KIOSK_ERR'

export const UPDATE_KIOSK_SUCCESS = 'UPDATE_KIOSK_SUCCESS'
export const UPDATE_KIOSK_ERR = ' UPDATE_KIOSK_ERR'

export const GET_CODE_IDENTIFY = 'GET_CODE_IDENTIFY'
export const GET_CODE_IDENTIFY_ERR = 'GET_CODE_IDENTIFY_ERR'

export const EXPORT_FILE_SUCCESS = 'EXPORT_FILE_SUCCESS'
export const EXPORT_FILE_ERR = 'EXPORT_FILE_ERR'

export const RESET_CODE_IDENTIFY = 'RESET_CODE_IDENTIFY'

export const RESET_VALUE_SEARCH = 'RESET_VALUE_SEARCH'

export const GET_INFO_KIOSK = 'GET_INFO_KIOSK'
export const GET_INFO_KIOSK_ERROR = 'GET_INFO_KIOSK_ERROR'

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERR = 'CHANGE_PASSWORD_ERR'

export const ADD_CLUSTER_KIOSK_SUCCESS = 'ADD_CLUSTER_KIOSK_SUCCESS'
export const ADD_CLUSTER_KIOSK_ERR = 'ADD_CLUSTER_KIOSK_ERR'