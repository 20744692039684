import styled from 'styled-components'

import { TableContent, TableHeader, WrapPageItem } from '../../../MainStyles/main'

const WrapFaceBiometric = styled(WrapPageItem)`
    
`;

const TableHeaderFaceBiometric = styled(TableHeader)`
    grid-template-columns:
        50px
        175px
        70px
        minmax( 200px, 1fr)
        250px
        150px
        115px;
    padding: 11px 0px 0px 20px;
`;


const TableContentFaceBiometric = styled(TableContent)`

`;

export{
    WrapFaceBiometric,
    TableHeaderFaceBiometric,
    TableContentFaceBiometric
}