import { CITYGYM_ADMIN_LOGIN } from "../constant/keys"

const checkSessionLogin = (login = { token: "", sessionKey: "" }) => {
    const dataStore = localStorage.getItem(CITYGYM_ADMIN_LOGIN)
    if(!dataStore) return false
    
    if(!login) return false

    let loginStore = JSON.parse(dataStore)
    if( loginStore.token !== login.token ){
        return false
    }
    else{
        if(loginStore.sessionKey !== login.sessionKey) return false
    }
    return true
}

export default checkSessionLogin