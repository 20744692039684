import styled from 'styled-components'

import { WrapPageItem } from '../../../MainStyles/main'

const WrapAdvertismentManage = styled(WrapPageItem)`
    background: red;
`;

export{
    WrapAdvertismentManage
}