import * as types from './types'
import { LOGOUT } from '../login/types'

const initialState = {
    // "TEST_SUCCESS": {
    //     type: "TEST_SUCCESS",
    //     payload: {
    //         code: "TEST_SUCCESS"
    //     }
    // },
    // "TEST_ERR": {
    //     type: "TEST_ERR",
    //     payload: {
    //         code: "TEST_ERR"
    //     }
    // }
}

const notifies = (state = initialState, action) => {
    const actionError = /.*_ERR/.test(action.type)
    const actionSuccess = /.*_SUCCESS/.test(action.type)
    if(actionError || actionSuccess){
        return {
            ...state,
            [action.type]: action
        }
    }
    if(action.type === types.POP_NOTIFY) {
        const copyState = {...state};
        delete copyState[action.payload.typeItem]
        return copyState;
    }
    if(action.type === LOGOUT){
        return initialState
    }
    return state;
}

export default notifies