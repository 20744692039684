import React from "react";

export default function IcKioskGray(props) {
  return (
    <svg
      {...props}
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14L16.5 9.5L12 5"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 5L1.5 9.5L6 14"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
