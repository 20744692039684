import React, { useEffect, useMemo, useRef, useState } from "react";

// css
import { TableKiosk, WrapButton } from "./index.styles";

// img
import LockerActiveTrue from "../../../../../../assets/images/locker_active_true.png";
import LockerActiveFalse from "../../../../../../assets/images/locker_active_false.png";
import LockerInActive from "../../../../../../assets/images/locker_inactive.png";

import IcFilterRed from "../../../../../../assets/images/IcFilterRed";
import IcNoData from "../../../../../../assets/images/IcNoData";
import IcPlus from "../../../../../../assets/images/IcPlus";
import IcSearchNoData from '../../../../../../assets/images/IcSearchNoData'

//component
import EmptyDataControl from "../../../../../Control/EmptyDataControl";
import Button from "../../../../../Control/Button";
import InputSearch from "../../../../../Control/InputSearch";
import Portal from "../../../../../Control/Portal";

// data
// import dataListCabinetKiosk from "../../../../../../mockdata/dataListKiosk";
import PopupFilterApply from "./PopupApplyRange";
import HistoryKioskRange from "../HistoryKioskRange";
import PopupFilterCabinet from "../../../CabinetRangeManage/Detail/DetailCabinet/PopupFilterCabinet";
import { getListLockerAction, resetListLocker, resetValueSearchCluster } from "../../../../../../redux/locker/action";
import { useDispatch, useSelector } from "react-redux";
import { getInfoByDocID, resetGetInfoByDocID } from "../../../../../../redux/cluster/action";
import { listRoom } from "../../../../../../data/listRoom";
import converTime from "../../../../../../helper/converTime";
import IcVipKiosk from "../../../../../../assets/images/IcVipKiosk";
import IcNoResult from "../../../../../../assets/images/IcNoResult";
import useScrollEnd from "../../../../../../hook/useScrollEnd";


const DetailKioskRange = ({ kioskInfo }) => {

  let newTime = Date.now()
  const [infoClusterId, setInfoClusterId] = useState(kioskInfo.lockerClusterList?.[0]);
  const [valueSearch, setValueSearch] = useState("");
  const [isShowPopupApply, setIsShowPopupApply] = useState(false);
  const [isShowPopupFilter, setIsShowPopupFilter] = useState(false);
  const [isShowHistoryKiosk, setIsShowHistoryKiosk] = useState(false);
  const [lockerInfoHistory , setLockerInfoHistory] = useState({})
  const [isSearching, setIsSearching] = useState(false)
  const dispatch = useDispatch();
  const listRef = useRef(null)


  const { listLockers, clusterInfo, isSearch, branchCode, condFilters } = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
    listLockers: state.lockerReducer.listLockers,
    condFilters: state.lockerReducer.condFilters,
    isSearch: state.lockerReducer.isSearch,
    clusterInfo: state.clusterReducer.clusterInfo
  }));

  useEffect(()=>{
    setValueSearch(condFilters.quickSearchData)
  },[])

  const _handleSearch = () => {
    if (!infoClusterId) return
    setIsSearching(true)
    dispatch(getListLockerAction({
      branchCode: branchCode,
      filterFrom: "",
      filterSize: 100,
      quickSearchData: valueSearch,
      lockerType: condFilters?.lockerType,
      kioskID: kioskInfo?.kioskId,
      clusterID: infoClusterId?.lockerClusterID,
      lockerStatus: condFilters.lockerStatus
    },false,
      callbackSuccess,
      callbackError
    ))
  };

  useEffect(() => {
    if(!isSearch){
      setValueSearch("")
    }
  },[isSearch])

  const _handleClickBtn = () => {
    setIsShowPopupApply((pre) => !pre);
  };
  
  const callbackSuccess = () => {}
  const callbackError = () => {}

  useEffect(() => {
    if (!infoClusterId) return
    dispatch(getInfoByDocID({
      docID : infoClusterId?.docID
    },
    callbackSuccess,
    callbackError
    ))
    dispatch(
      getListLockerAction({
        branchCode: branchCode,
        filterFrom: "",
        filterSize: 100,
        quickSearchData: condFilters?.quickSearchData,
        lockerType: condFilters?.lockerType,
        kioskID: kioskInfo?.kioskId,
        clusterID: infoClusterId?.lockerClusterID,
        lockerStatus: condFilters?.lockerStatus
      },
      false,
      callbackSuccess,
      callbackError
      )
    )
    return () => {
      dispatch(resetValueSearchCluster())
      dispatch(resetGetInfoByDocID())
      dispatch(resetListLocker())
    }
  }, [infoClusterId]);

  useScrollEnd(listRef, (callback) => {
    dispatch(getListLockerAction({
      branchCode: branchCode,
      filterFrom: listLockers[listLockers.length -1].id,
      filterSize: 12,
      quickSearchData: condFilters?.quickSearchData,
      lockerType: condFilters?.lockerType,
      kioskID: kioskInfo?.kioskId,
      clusterID: infoClusterId?.lockerClusterID,
      lockerStatus: condFilters?.lockerStatus
    },
    true,
    callback
    ))
  }, [listLockers], [listLockers?.length])

  const totalUseStatus = clusterInfo.lockerList?.reduce((total, item) => {
    return item.useStatus === false && item.activeStatus === true ? total + 1 : total + 0
  },0)

  // fix không trùng STT hộc tủ trong 1 kiosk, lấy ra danh sách hộc tủ hiện có
  let arrClusterNow = kioskInfo.lockerClusterList.map((item)=>item.lockerList)
  let arrLockerNow = arrClusterNow.flat(Infinity).map((item)=>item.lockerID)

  return (
    <TableKiosk>
      <div className="detail-cabinet-left">
        <div className="list-range">
          {
            kioskInfo.lockerClusterList?.map((item,index) => (
              <div
              key={index}
              className={`detail-cabinet-item ${
                item.lockerClusterID === infoClusterId?.lockerClusterID ? "selected" : ""
              }`}
              onClick={() => setInfoClusterId(item)}
            >
              <p className="range-value" title={item?.lockerClusterName}>{item?.lockerClusterName.length > 20 ? item.lockerClusterName.substr(0,20) + "..." : item?.lockerClusterName}</p>
            </div>
          ))
        }
        </div>

        <WrapButton>
          <Button
            text="Áp dụng"
            iconBtn={<IcPlus />}
            className="btn"
            onClick={() => setIsShowPopupApply(true)}
          />
          {isShowPopupApply && (
            <Portal>
              <PopupFilterApply kioskDocID={kioskInfo?.id} onClickIconClose={_handleClickBtn} arrTest={arrLockerNow} />
            </Portal>
          )}
        </WrapButton>
      </div>
      <div className="detail-cabinet-right">
        <div className="detail-title">
          <p className="title-bold">Thông tin dãy tủ</p>
          <InputSearch
            value={valueSearch}
            setValue={setValueSearch}
            isOnChange={true}
            type="text"
            placeholder="Nhập số hộc tủ"
            onChange={_handleSearch}
          />
          <WrapButton>
            <Button
              text="Bộ lọc"
              iconBtn={<IcFilterRed />}
              className="btn-filter"
              onClick={() => setIsShowPopupFilter(true)}
            />
            {isShowPopupFilter && (
              <Portal>
                <PopupFilterCabinet
                  setIsSearching={()=>setIsSearching(true)}
                  valueSearch={valueSearch}
                  onClose={() => setIsShowPopupFilter(false)}
                />
              </Portal>
            )}
          </WrapButton>
        </div>
        <div className="detail-container">
          <div className="info-container">
            <div className="item-check">
              <p>{clusterInfo.clusterId ? clusterInfo.clusterId : "-"}</p>
              <p className="value">ID Dãy tủ</p>
            </div>
            <div className="item-check">
              <p>{clusterInfo.roomType ? listRoom[clusterInfo.roomType]?.text : '-'}</p>
              <p className="value">Vị trí dãy tủ</p>
            </div>
            <div className="item-check">
              <p>{clusterInfo.lockerList?.length || 0}</p>
              <p className="value">Số lượng hộc tủ</p>
            </div>
            <div className="item-check">
              <p>{totalUseStatus || 0}</p>
              <p className="value">Còn trống</p>
            </div>
          </div>
          <div className="info-title">Danh sách hộc tủ</div>
          {listLockers.length === 0 ? (
            <div className="wrap-empty">
              { isSearching && (
                <EmptyDataControl
                  IconNonData={<IcNoResult className="ic-no-data" />}
                  labelDes="Không có kết quả"
                />
              )}
              { !isSearching && (
                <EmptyDataControl
                  IconNonData={<IcNoData className="ic-no-data" />}
                  labelDes="Hiện tại chưa có hộc tủ nào"
                />
              )}
            </div>
          ) : (
            <div className="wrap-list">
              <div className="wrap-list-cabinet-kiosk" ref={listRef}>
                <div className="wrap-list-cabinet" >
                  {listLockers.map((item, index) => (
                    <div
                      className="list-cabinet"
                      key={index}
                      ref={listRef}
                      onClick={() => {
                        setIsShowHistoryKiosk(true);
                        setLockerInfoHistory({
                          clusterID: item.clusterID, 
                          kioskID: item.kioskID, 
                          lockerID: item.lockerID,
                          vipStatus: item.vipStatus,
                          borrowTime: item.borrowTime,
                          useStatus: item.useStatus,
                          updateTime: item.updateTime
                        })
                      }
                      }
                    >
                      <div className="item-cabinet">
                        <div className="item-value">
                          <p>{item.lockerID}</p>
                          <p className="value">{item.roomType ? listRoom[item.roomType]?.text : '-'}</p>
                        </div>
                        {
                            item.activeStatus === true ? (
                              <>
                                {
                                  item.useStatus === false ? (
                                      <img src={LockerActiveTrue} alt="citigym-locker" />
                                  ) : (
                                    <img src={LockerActiveFalse} alt="citigym-locker" />                 
                                  )
                                }
                              </>
                            ) : (
                              <img src={LockerInActive} alt="citigym-locker" />
                            )
                        }
                        <div className="item-check">
                          <p>{item.vipStatus === true ? "Tủ Vip" : "Tủ Thường"}</p>
                          {item.vipStatus === true ? <IcVipKiosk /> : ""}
                        </div>
                        <div className={`${item.useStatus === true ? "item-time" : ""}`}>
                          <p>{item.useStatus === true ? converTime(newTime , item.borrowTime, "giờ + phút") : ""}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isShowHistoryKiosk && (
        <Portal>
          <HistoryKioskRange
            lockerInfoHistory={lockerInfoHistory}
            onClickIconClose={() => setIsShowHistoryKiosk(false)}
          />
        </Portal>
      )}
    </TableKiosk>
  );
};

export default DetailKioskRange;
