import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({children}) => {

    const {loggedIn} = useSelector(state => ({
        loggedIn: state.login.loggedIn
    }))

    return (
        loggedIn ? (
            children
        ) : (
            <Navigate to={{pathname: '/'}}/>
        )
    )
}

export default PrivateRoute
