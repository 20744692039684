import Axios from "axios";
import { nanoid } from "nanoid";

//types
import * as types from "./types";

//host
import { host } from "../../constant/host";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import checkSessionLogin from "../../helper/checkSessionLogin";
import mapErrors from "../../helper/mapErrors";

//action
import { logoutAction } from "../login/action";
import { enableLoading, disableLoading } from "../loading/action";
import { CITYGYM_ADMIN_LOGIN } from "../../constant/keys";

const apiSearchByMemberCode = `${host}/member/searchByMemberCode`;
const apiSearchByMemberId = `${host}/member/saleforce/searchByID`;
const apiUpdateFaceMember = `${host}/member/updateFace`;
const apiConfirmRegisterFaceMember = `${host}/member/confirmRegisterFace`;
const apiUpdateFaceMemberList = `${host}/member/getUpdateMemberList`;
const apiGetDuplicateMemberList = `${host}/member/getDuplicateList`;

// const dataLogin = JSON.parse(localStorage.getItem(CITYGYM_ADMIN_LOGIN))

let isCalling = false;
let isCallingUpdateFace = false;

const headerDataRequest = {
  cid: "web",
  reqId: `${new Date().getTime()}`,
  langCode: "vn",
};

export const searchByMemberCode =
  (dataSearch = { memberCode: "" }, callbackSuccess, callbackError) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    // if (isCalling) return;
    dispatch(enableLoading());
    // isCalling = true;
    const dataRequest = {
      ...headerDataRequest,
      data: dataSearch,
      cid: dataLogin.id,
    };

    try {
      const res = await Axios.post(apiSearchByMemberCode, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        const dataMember = data.data
          ? {
              ...data.data,
              isExistedFace:
                !!data.data?.facePhotoPath && data.data.facePhotoPath !== "",
            }
          : {};

        dispatch({
          type: types.SEARCH_MEMBER,
          payload: {
            data: dataMember,
          },
        });

        if (!data.data) {
          callbackError && callbackError();
        }
        isCalling = false;
        callbackSuccess && callbackSuccess();
      } else {
        dispatch({
          type: types.SEARCH_MEMBER_ERR,
          payload: {
            ...data,
          },
        });
        callbackError && callbackError();
      }
    } catch (err) {
      dispatch(mapErrors(err, types.SEARCH_MEMBER_ERR));
    }
    // isCalling = false;
    setTimeout(() => {
      dispatch(disableLoading());
    }, 1500);
  };

export const searchByMemberId =
  (dataSearch = { id: "" }, callbackSuccess, callbackError) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    // if (isCalling) return;
    dispatch(enableLoading());
    // isCalling = true;
    const dataRequest = {
      ...headerDataRequest,
      data: dataSearch,
      cid: dataLogin.id,
    };

    try {
      const res = await Axios.post(apiSearchByMemberId, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        const dataMember = data.data
          ? {
              ...data.data,
              isExistedFace:
                !!data.data?.facePhotoPath && data.data.facePhotoPath !== "",
            }
          : {};

        dispatch({
          type: types.SEARCH_MEMBER,
          payload: {
            data: dataMember,
          },
        });

        if (!data.data) {
          callbackError && callbackError();
        }
        isCalling = false;
        callbackSuccess && callbackSuccess();
      } else {
        dispatch({
          type: types.SEARCH_MEMBER_FAIL,
          payload: {
            ...data,
          },
        });
        callbackError && callbackError();
      }
    } catch (err) {
      dispatch(mapErrors(err, types.SEARCH_MEMBER_ERR));
    }
    // isCalling = false;
    dispatch(disableLoading());
  };

export const resetSearchMemberCode = () => async (dispatch) => {
  dispatch({ type: types.RESET_SEARCH_MEMBER });
};

export const updateFaceMember =
  (
    data = {
      branchCode: "",
      memberSFID: "",
      memberCode: "",
      photo: "",
      avatar: "",
      memberType: "",
      fullName: "",
      pinCode: "",
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return;
    isCalling = true;
    dispatch(enableLoading());
    const dataRequest = { ...headerDataRequest, data: data, cid: dataLogin.id };

    try {
      const res = await Axios.post(apiUpdateFaceMember, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.UPDATE_FACE_MEMBER_SUCCESS,
          payload: {
            code: "UPDATE_FACE_SUCCESS",
            data: data,
          },
        });
        isCalling = false;
        callbackSuccess && callbackSuccess();
      } else {
        switch (data.code) {
          case 78:
            dispatch({
              type: types.UPDATE_FACE_MEMBER_FAIL,
              payload: {
                code: 78,
                identifyList: data.data.identifyList,
              },
            });
            callbackError && callbackError();
            break;

          case 88:
            dispatch({
              type: types.UPDATE_FACE_MEMBER_ERR,
              payload: { code: 88 },
            });
            break;

          case 115:
            dispatch({
              type: types.DUPLICATED_PINCODE_ERR,
              payload: {
                code: 0,
              },
            });
            break;

          default:
            dispatch({
              type: types.UPDATE_FACE_MEMBER_ERR,
              payload: { code: 0 },
            });
        }
      }
    } catch (err) {
      const { code } = err?.response?.data || {};
      if (code === 19) {
        callbackError && callbackError();
        return;
      }
      dispatch(mapErrors(err, types.UPDATE_FACE_MEMBER_ERR));
    }
    isCalling = false;
    setTimeout(() => {
      dispatch(disableLoading());
    }, 500);
  };

export const confirmRegisterFaceMember =
  (
    data = {
      duplicateList: [],
      data: {
        branchCode: "",
        memberSFID: "",
        memberCode: "",
        pinCode: "",
        photo: "",
        avatar: "",
        memberType: "",
        fullName: "",
      },
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return;
    isCalling = true;
    dispatch(enableLoading());
    const dataRequest = { ...headerDataRequest, data: data, cid: dataLogin.id };

    try {
      const res = await Axios.post(apiConfirmRegisterFaceMember, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.UPDATE_FACE_MEMBER_SUCCESS,
          payload: {
            code: "UPDATE_FACE_SUCCESS",
            data: data,
          },
        });
        isCalling = false;
        callbackSuccess && callbackSuccess();
      } else {
        if (data.code === 115) {
          dispatch({
            type: types.DUPLICATED_PINCODE_ERR,
            payload: {
              code: 0,
            },
          });
          callbackError && callbackError();
        }
      }
    } catch (err) {
      dispatch(mapErrors(err, types.UPDATE_FACE_MEMBER_ERR));
    }
    isCalling = false;
    setTimeout(() => {
      dispatch(disableLoading());
    }, 500);
  };

export const getUpdateMemberList =
  (
    data = {
      branchCode: "",
      filterInfo: {
        limit: 15,
        cursor: "",
        startTime: "",
        endTime: "",
        quickSearchData: "",
      },
    },
    isScroll = false,
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return;
    isCalling = true;
    !isScroll && dispatch(enableLoading());
    let filterInfo = { ...data };
    let dataRequest = {
      ...headerDataRequest,
      data: filterInfo,
      cid: dataLogin.id,
    };
    try {
      const res = await Axios.post(apiUpdateFaceMemberList, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.GET_LIST_UPDATE,
          payload: {
            list: data.data.result || [],
            filterInfo: filterInfo.filterInfo,
            isScroll,
            totalupdateToDay: data.data.totalUpdateToday || 0,
            totalUpdate: data.data.totalUpdate || 0,
          },
        });
        isCalling = false;
        callbackSuccess && callbackSuccess();
      } else {
        dispatch({
          type: types.GET_LIST_UPDATE_ERR,
          payload: {
            ...data,
          },
        });

        callbackError && callbackError();
      }
    } catch (error) {
      dispatch(mapErrors(error, types.GET_LIST_UPDATE_ERR));
    }
    isCalling = false;
    dispatch(disableLoading());
  };

export const getDuplicateMemberList =
  (
    data = {
      memberID: ""
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    let filterInfo = { ...data };
    let dataRequest = {
      ...headerDataRequest,
      data: filterInfo,
      cid: dataLogin.id,
    };
    try {
      const res = await Axios.post(apiGetDuplicateMemberList, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.GET_LIST_DUPLICATE,
          payload: {
            identifyList: data.data.memberList || []
          },
        });
        callbackSuccess && callbackSuccess();
      } else {
        dispatch({
          type: types.GET_LIST_DUPLICATE_FAIL,
          payload: {
            ...data,
          },
        });
        callbackError && callbackError();
      }
    } catch (error) {
      dispatch(mapErrors(error, types.GET_LIST_DUPLICATE_FAIL));
    }
  };

export const clearUpdateMemberList = (callbackSuccess) => async (dispatch) => {
  dispatch({ type: types.CLEAR_LIST_UPDATE });
  callbackSuccess && callbackSuccess();
};

export const setFaceUpdateMember =
  (photoFirebase, avatarFirebase) => async (dispatch) => {
    try {
      dispatch({
        type: types.SET_FACE_UPDATE,
        payload: {
          facePhotoPath: photoFirebase,
          avatarPhotoPath: avatarFirebase,
        },
      });
    } catch (error) {
      dispatch(mapErrors(error, types.SET_FACE_UPDATE_ERR));
    }
    isCalling = false;
  };

export const resetValueSearchMember = () => async (dispatch) => {
  dispatch({ type: types.RESET_VALUE_SEARCH });
};
