import React from 'react'

export default function IcMoreOptionGray(props) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99998 13.3333C8.36817 13.3333 8.66665 13.0349 8.66665 12.6667C8.66665 12.2985 8.36817 12 7.99998 12C7.63179 12 7.33331 12.2985 7.33331 12.6667C7.33331 13.0349 7.63179 13.3333 7.99998 13.3333Z" fill="#BDBDBD" stroke="#BDBDBD" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.99998 8.66683C8.36817 8.66683 8.66665 8.36835 8.66665 8.00016C8.66665 7.63197 8.36817 7.3335 7.99998 7.3335C7.63179 7.3335 7.33331 7.63197 7.33331 8.00016C7.33331 8.36835 7.63179 8.66683 7.99998 8.66683Z" fill="#BDBDBD" stroke="#BDBDBD" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.99998 3.99984C8.36817 3.99984 8.66665 3.70136 8.66665 3.33317C8.66665 2.96498 8.36817 2.6665 7.99998 2.6665C7.63179 2.6665 7.33331 2.96498 7.33331 3.33317C7.33331 3.70136 7.63179 3.99984 7.99998 3.99984Z" fill="#BDBDBD" stroke="#BDBDBD" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
