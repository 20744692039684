import styled from "styled-components";


const WrapControlAutoDoor = styled.div`
    position: absolute;
    z-index: 99;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
   
    #controlAutoDoor{
        display: inline-block;
    }
    .hiden-block{
        display: none;
    }
    .zoom-out-block{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: move;
       
    }
    .popup-control{
        display: inline-block;
    }
  
`



export {
    WrapControlAutoDoor
}