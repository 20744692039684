import styled from "styled-components";
import { TableContent} from "../../../../MainStyles/main"


const TableContentCabinetRange = styled(TableContent)`
  .wrap-list-trans{
    margin-top: 0;
    .list-trans{
      display: grid;
      grid-template-columns: 360px 360px 360px;
      grid-gap: 20px;
    }
  }
  
`;

export { TableContentCabinetRange };
