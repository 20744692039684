import * as types from './types'

export const popNotify = (typeItem) => dispatch => {
    dispatch({
        type: types.POP_NOTIFY,
        payload: {
            typeItem
        }
    })
}

export const showNotify = (typeItem) => dispatch => {
    dispatch({
        type: typeItem,
    })
}