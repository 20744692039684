import styled from 'styled-components'

const WrapMain = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 60px 1fr;
    height: 100%;

    & > *:first-child {
        grid-column: 1/-1;
    }
`;

export {
    WrapMain
}