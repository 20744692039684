import React from "react";
//styles
import { WrapKioskItem } from "./index.styles";

//images
import KioskActiveConnect from "../../../../../../assets/images/kiosk-active-connect.png";
import KioskDisConnect from "../../../../../../assets/images/kiosk_disconect2.png";

//component
import OptionKiosk from "../OptionKiosk";
import KioskStatus from "./KioskStatus";

//data
import { CONNECTED } from "../../../../../../data/listKioskStatus";
import { listRoom } from "../../../../../../data/listRoom";

export default function KioskItem(props) {
  const { className, item, refList, onClick } = props;

  return (
    <WrapKioskItem>
      <div className="kiosk-option">
        <OptionKiosk dataKiosk={item} refList={refList} />
      </div>
      <div style={{ height: "170px", cursor: "pointer" }} onClick={onClick}>
        <div className="kiosk-img" >
          {item.status === CONNECTED && (
            <img src={KioskActiveConnect} alt="citygym" />
          )}
          {item.status !== CONNECTED && (
            <img src={KioskDisConnect} alt="citygym" />
          )}
        </div>
        <div className="kiosk-name" title={item?.kioskName}>
          {item?.kioskName.length > 15
            ? `${item?.kioskName.substring(0, 15)}...`
            : item?.kioskName}
        </div>
        <div className="kiosk-room">{listRoom[item?.roomType]?.text}</div>
      </div>
      <KioskStatus item={item} onClick={onClick} />
    </WrapKioskItem>
  );
}
