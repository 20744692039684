//host
import { host } from "../../constant/host";

//axios
import Axios from "axios";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";
import * as types from "./types";
import checkSessionLogin from "../../helper/checkSessionLogin";
import { logoutAction } from "../login/action";
import { CITYGYM_ADMIN_LOGIN } from "../../constant/keys";
import { disableLoading, enableLoading } from "../loading/action";

const apiAddKiosk = `${host}/kiosk/add`;
const apiUpdateKiosk = `${host}/kiosk/update`;
const apiDelKiosk = `${host}/kiosk/delete`;
const apiFilterKiosk = `${host}/kiosk/filter`;
const apiGetIndentifyCode = `${host}/kiosk/genIdentifierCode`;
const apiGetInfoKiosk = `${host}/kiosk/getInfoByDocID`
const apiChangePasswordKiosk = `${host}/kiosk/pwd/change`
const apiAddClusterForKiosk = `${host}/kiosk/addLockerCluster`

// const dataLogin = JSON.parse(localStorage.getItem(CITYGYM_ADMIN_LOGIN))

const headerDataRequest = {
  cid: "web",
  reqId: `${new Date().getTime()}`,
  langCode: "vn",
};
let isCalling = false

export const addKioskAction =
  (
    dataKiosk = {
      branchCode: "",
      kioskID: "",
      kioskName: "",
      roomType: "",
      photoList: [""],
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataKiosk };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiAddKiosk, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        await dispatch({ type: types.ADD_KIOSK_SUCCESS });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({ type: types.ADD_KIOSK_ERR, payload:{ code: data.code } });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.ADD_KIOSK_ERR));
    }
    isCalling = false
    dispatch(disableLoading())
  };

export const updateKioskAction =
  (
    dataKiosk = {
      id: "",
      kioskName: "",
      photoList: [""],
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataKiosk };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiUpdateKiosk, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.UPDATE_KIOSK_SUCCESS,
          payload: { code: 0 },
        });
        isCalling = false
        callbackSuccess && callbackSuccess();

      } else {
        dispatch({ type: types.UPDATE_KIOSK_ERR });
        callbackError && callbackError(data.code);
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.UPDATE_KIOSK_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };
export const delKioskAction =
  (
    dataKiosk = {
      kioskDocID: "",
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataKiosk };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiDelKiosk, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        dispatch({ type: types.DEL_KIOSK_SUCCESS });
        isCalling = false
        callbackSuccess && callbackSuccess();

      } else {
        dispatch({ type: types.DEL_KIOSK_ERR });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.DEL_KIOSK_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const getListKioskAction =
  (
    dataGetList = {
      branchCode: "",
      filterFrom: "",
      filterSize: 10,
      quickSearchData: "",
      roomType: "",
      status: "",
    },
    isScroll,
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    !isScroll && dispatch(enableLoading())
    isCalling = true
    let data = { ...dataGetList };

    const condFilters = {...data};

    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    try {
      const res = await Axios.post(apiFilterKiosk, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.GET_LIST_KIOSK,
          payload: {
            listKiosks: data.data.listKiosk || [],
            total: data.data.total || 0,
            condFilters,
            isScroll,
            // totalSearch: data.totalSearch || 0,
          },
        });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({
          type: types.GET_LIST_KIOSK_ERR,
          payload: {
            ...data,
          },
        });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.GET_LIST_KIOSK_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const resetListKiosk = (callbackSuccess) => async (dispatch) => {
  dispatch({
    type: types.RESET_LIST_KIOSK,
  });

  callbackSuccess && callbackSuccess();
};

export const getIdentifyCode =
  (
    dataKiosk = {
      kioskId: "",
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataKiosk };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiGetIndentifyCode, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.GET_CODE_IDENTIFY,
          payload: { ...data.data },
        });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({ type: types.GET_CODE_IDENTIFY_ERR });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.GET_CODE_IDENTIFY_ERR));
    }
    
    isCalling = false
    dispatch(disableLoading())
  };

export const resetCodeIdentify = (callbackSuccess) => async (dispatch) => {
  dispatch({
    type: types.RESET_CODE_IDENTIFY,
  });

  callbackSuccess && callbackSuccess();
};

export const resetValueSearchKiosk = () => async (dispatch) => {
  dispatch({
    type: types.RESET_VALUE_SEARCH
  })
}

export const getInfoLocker = (
  data = {
    docID: ""
  },
  callbackSuccess,
  callbackError
) => async (dispatch, getState) => {
  let dataLogin = getState().login.dataLogin
  if(!checkSessionLogin(dataLogin)){
    dispatch(logoutAction())
    return
  }
  let dataRequest = {...headerDataRequest, data: data, cid: dataLogin.id}
  dispatch(enableLoading())
  try {
    const resp = await Axios.post(apiGetInfoKiosk, dataRequest)
    const { data } = resp
    if(checkIsSuccessAction(data.code)){
      dispatch({
        type: types.GET_INFO_KIOSK,
        payload: {
          kioskInfo: data.data.kioskInfo
        }
      })
      callbackSuccess && callbackSuccess()
    }else{
      dispatch({
        type: types.GET_INFO_KIOSK_ERROR,
        payload: {
          ...data
        }
      })
      callbackError && callbackError()
    }
  } catch (error) {
    dispatch(mapErrors(error, types.GET_INFO_KIOSK_ERROR))
  }
  dispatch(disableLoading())
}

export const changePasswordKiosk = (
  data = {
    kioskDocID: "",
    pwd: ""
  },
  callbackSuccess,
  callbackError
) => async (dispatch, getState) => {
  let dataLogin = getState().login.dataLogin
  if(!checkSessionLogin(dataLogin)){
    dispatch(logoutAction())
    return
  }
  let dataRequest = {...headerDataRequest, data: data, cid: dataLogin.id}
  try {
    const resp = await Axios.post(apiChangePasswordKiosk, dataRequest)
    const { data } = resp
    if(checkIsSuccessAction(data.code)){
      dispatch({
        type: types.CHANGE_PASSWORD_SUCCESS,
        payload: {code: 0}
      })
      callbackSuccess && callbackSuccess()
    }else{
      dispatch({
        type: types.CHANGE_PASSWORD_ERR,
        payload: {code: 0}
      })
      callbackError && callbackError()
    }
  } catch (error) {
    dispatch(mapErrors(error, types.CHANGE_PASSWORD_ERR))
  }
}

export const addLockerClusterForKiosk = (
  data = {
    kioskDocID: "",
    clusterDocID: ""
  },
  callbackSuccess, callbackError
) => async (dispatch, getState) => {

  let dataLogin = getState().login.dataLogin
  if(!checkSessionLogin(dataLogin)){
    dispatch(logoutAction())
    return
  }
  if (isCalling) return
  isCalling = true
  let dataRequest = {...headerDataRequest, data: data, cid: dataLogin.id}
  dispatch(enableLoading())

  try {
    const resp = await Axios.post(apiAddClusterForKiosk, dataRequest)
    const { data } = resp
    if(checkIsSuccessAction(data.code)){
      dispatch({
        type: types.ADD_CLUSTER_KIOSK_SUCCESS,
        payload: {code: 0}
      })

      isCalling = false
      callbackSuccess && callbackSuccess()
    }else{
      dispatch({
        type: types.ADD_CLUSTER_KIOSK_ERR,
      })
      callbackError && callbackError()
    }
  } catch (error) {
    dispatch(mapErrors(error, types.ADD_CLUSTER_KIOSK_ERR))
  }

  isCalling = false
  dispatch(disableLoading())
}