import React from 'react'

const IcVipKiosk = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="1.16959" fill="#C4C4C4"/>
            <rect width="16" height="16" rx="1.16959" fill="url(#paint0_linear_5333_6611)"/>
            <path d="M4.11694 11.7308L4.85723 10.1595C5.77983 10.8056 6.88014 11.1514 8.0074 11.1494C9.29897 11.1494 9.81874 10.7094 9.81874 10.1438C9.81874 8.38385 4.3532 9.59378 4.3532 6.1054C4.3532 4.53405 5.64478 3.18269 8.30667 3.18269C9.45439 3.15734 10.5872 3.44532 11.5828 4.01551L10.9055 5.58686C10.1197 5.1164 9.22308 4.86161 8.30667 4.84832C7.0151 4.84832 6.51105 5.31973 6.51105 5.91684C6.51105 7.64532 11.9766 6.45109 11.9766 9.89234C11.9766 11.4637 10.685 12.815 7.99163 12.815C6.61904 12.8597 5.26605 12.4811 4.11694 11.7308Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear_5333_6611" x1="-9.12281" y1="16.7485" x2="17.1228" y2="5.9883" gradientUnits="userSpaceOnUse">
            <stop stopColor="#BE965D"/>
            <stop offset="0.28" stopColor="#976F40"/>
            <stop offset="0.43" stopColor="#C99B61"/>
            <stop offset="0.56" stopColor="#F0BE7B"/>
            <stop offset="0.63" stopColor="#FFCB85"/>
            <stop offset="0.71" stopColor="#EEBC79"/>
            <stop offset="0.87" stopColor="#C0935B"/>
            <stop offset="1" stopColor="#976F40"/>
            </linearGradient>
            </defs>
            </svg>

    )
}

export default IcVipKiosk
