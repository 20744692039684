import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";

//data
import dataMenu from "../../../data/dataMenu";
import { kioskManage, cabinetRangeManage, newFaceBiometric, faceBiometric } from "../../../constant/routes";

//action
import {
  getListClusterAction,
  resetValueSearchCluster,
} from "../../../redux/cluster/action";
import {
  getListKioskAction,
  resetValueSearchKiosk,
} from "../../../redux/kiosk/action";

// styles
import { WrapSideMenu } from "./index.styles";
import { useDispatch, useSelector } from "react-redux";
import { clearUpdateMemberList, getUpdateMemberList, resetSearchMemberCode, resetValueSearchMember } from "../../../redux/member/action";

const SideMenu = () => {
  const dispatch = useDispatch();
  const { branchCode } = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
  }));

  const callbackSuccessOfDay = () => {
    dispatch(getUpdateMemberList({
      branchCode: branchCode,
      filterInfo: {
        limit: 15,
        cursor: "",
        quickSearchData: "",
        startTime: moment(new Date()).utc().startOf("day").toDate(),
        endTime: moment(new Date()).utc().endOf("day").toDate()
      }
    }))
  }

  const callbackSuccessOfMonth = () => {
    dispatch(getUpdateMemberList({
      branchCode: branchCode,
      filterInfo: {
        limit: 15,
        cursor: "",
        startTime: moment().utc().startOf("month").toDate(),
        endTime: moment(new Date()).endOf("day").toDate(),
        quickSearchData: "",
      }
    }))
  }


  const _handleClickBtn = (key) => {
    if (!key) return;
    switch (key) {
      case newFaceBiometric: {
        dispatch(resetSearchMemberCode())
        dispatch(resetValueSearchMember())
        dispatch(clearUpdateMemberList(callbackSuccessOfDay))
        break;
      }

      case faceBiometric: {
        dispatch(resetValueSearchMember())
        dispatch(clearUpdateMemberList(callbackSuccessOfMonth))
        break;
      }

      case kioskManage: {
        const callbackSuccess = ()=>{
          clearTimeout(timer)
        }
        const callbackError = () => {
          clearTimeout(timer)
        }
        let timer = setTimeout(()=>{
          dispatch(
            getListKioskAction({
              branchCode: branchCode,
              filterFrom: "",
              filterSize: 100,
              roomType: "",
              quickSearchData: "",
              status: ""
            },false,callbackSuccess,callbackError)
          );
        },500)
        
        dispatch(resetValueSearchKiosk());
        break;
      }

      case cabinetRangeManage: {
        const callbackSuccess = ()=>{
          clearTimeout(timer)
        }
        const callbackError = () => {
          clearTimeout(timer)
        }
        let timer = setTimeout(()=>{
          dispatch(
            getListClusterAction({
              branchCode: branchCode,
              filterFrom: "",
              filterSize: 100,
              quickSearchData: "",
              roomType: "",
              kioskName: ""
            },false,callbackSuccess,callbackError)
          );
        },500)
        
        dispatch(resetValueSearchCluster());
        break;
      }

      default:
        break;
    }
  };

  return (
    <WrapSideMenu>
      {dataMenu.map((item, index) => (
        <NavLink
          to={item.path}
          key={index}
          className={({ isActive }) =>
            isActive ? "link-item selected" : "link-item "
          }
          onClick={() => _handleClickBtn(item.key)}
        >
          <item.IconActive className="icon active" />
          <item.Icon className="icon" />
          <p>{item.name}</p>
        </NavLink>
      ))}
    </WrapSideMenu>
  );
};

export default SideMenu;
