import React, { useState, useReducer, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapFormOptionCabinet } from './index.styles'
import { OverlayFullScreen } from '../../../../../../../MainStyles/main'

//component
import Button from '../../../../../../../Control/Button'
import InputDropdownNameCabinetRange from './InputDropdownNameCabinetRange'
import InputOnRoadKiosk from './InputOnRoadKiosk'
import InputIdCabinet from './InputIdCabinet'

// image
import IcClose from '../../../../../../../../assets/images/IcClose'
import CheckBoxControl from '../../../../../../../Control/CheckBoxControl'
import IcUnCheckBox from '../../../../../../../../assets/images/IcUnCheckBox'
import IcCheckBoxActive from '../../../../../../../../assets/images/IcCheckBoxActive'
import IcStatusActive from '../../../../../../../../assets/images/IcStatusActive'
import IcStatusNonActive from '../../../../../../../../assets/images/IcStatusNonActive'
import { checkValidation, getStrValidation } from '../../../../../../../../helper/validation'
import { getListLockerAction } from '../../../../../../../../redux/locker/action'

const iconStoreVip = {
    icon: IcUnCheckBox,
    iconActive: IcCheckBoxActive
}
const iconStatusActive = {
    icon: IcStatusNonActive,
    iconActive: IcStatusActive
}

const FormOptionCabinet = ({
    dataCluster,
    onClickIconClose,
    titleOption,
    dataCabinetEdit,
    isDisable,
    textBtn,
    iconBtn,
    apiHandleAdd,
    apiHandleEdit,
    // arrTest
}) => {
    const dispatch = useDispatch()
    const [dataCabinet, setDataCabinet] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            clusterID: dataCluster?.clusterId,
            activePort: dataCabinetEdit?.activePort || undefined,
            lockerID: dataCabinetEdit?.lockerID || undefined,
            vipStatus: dataCabinetEdit?.vipStatus || false,
            activeStatus: dataCabinetEdit ? dataCabinetEdit?.activeStatus : true,
            errorSubmitPort: false,
            errorSubmitIdLocker: false,
            error: ""
        }
    )
    const _handleSetValueNameCabinetRange = (val) => {
        setDataCabinet({ clusterID: val })
    }

    const _handleSetValueOnRoadKiosk = (val) => {
        setDataCabinet({ activePort: val, errorSubmitPort: false })
    }

    const _handleSetValueIdCabinet = (val) => {
        setDataCabinet({ lockerID: val, errorSubmitIdLocker: false })
    }

    const handleCheckVip = (val) => {
        setDataCabinet({vipStatus : !val})
    }

    const handleCheckStatus = (val) => {
        setDataCabinet({activeStatus : !val})
    }

    const _handleValidationInput = (value, callback) => {
        if (!value?.trim() || 0 === value.length) {
            setDataCabinet({errorSubmitIdLocker: true})
            callback("Thông tin không được để trống")
            return
        }
        if (checkValidation.errorNumberAndMax(value, 3) || value.length < 3) {
            setDataCabinet({errorSubmitIdLocker: true})
            callback(getStrValidation.errorFormat);
        }
    }

    const _handleValidationInputPort = (value, callback) => {
        if (!value?.trim() || 0 === value.length) {
            setDataCabinet({errorSubmitPort: true})
            callback("Thông tin không được để trống")
            return
        }
        if (checkValidation.errorNumberAndMax(value, 3)) {
            setDataCabinet({errorSubmitPort: true})
            callback(getStrValidation.errorFormat);
        }
    }

    // // check trùng STT hộc tủ
    // const checkIDLocker = (idLocker) => {
    //     return arrTest.includes(idLocker)
    // }
    
    const onSubmit = () => {
        if (!dataCabinet.activePort && !dataCabinet.lockerID){
            setDataCabinet({error: "Thông tin không được để trống"})
            return
        }
        if (dataCabinet.errorSubmitIdLocker || dataCabinet.errorSubmitPort) return
        
        const callbackSuccess = () => {
            onClickIconClose();
            
            dispatch(
                getListLockerAction({
                    branchCode: dataCluster?.branchCode,
                    filterFrom: "",
                    filterSize: 12,
                    quickSearchData: "",
                    lockerType: 0,
                    kioskID: dataCluster?.kioskId,
                    clusterID: dataCluster?.clusterId,
                    lockerStatus: 0
                  })
            );
          };
          const callbackError = (code) => {
            
          };
      
          {
            apiHandleAdd &&
              dispatch(
                apiHandleAdd(
                  {
                    branchCode: dataCluster?.branchCode,
                    clusterID: dataCabinet?.clusterID,
                    activePort: dataCabinet?.activePort,
                    lockerID: dataCabinet?.lockerID,
                    vipStatus: dataCabinet?.vipStatus,
                    activeStatus: dataCabinet?.activeStatus
                  },
                  callbackSuccess,
                  callbackError
                )
              );
          }
      
          {
            apiHandleEdit &&
              dispatch(
                apiHandleEdit(
                  {
                    docID: dataCabinetEdit?.id,
                    activePort: dataCabinet?.activePort,
                    lockerID: dataCabinet?.lockerID,
                    vipStatus: dataCabinet?.vipStatus,
                    activeStatus: dataCabinet?.activeStatus
                  },
                  callbackSuccess,
                  callbackError
                )
              );
          }
    }


    return (
        <OverlayFullScreen onClick={(e)=>e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapFormOptionCabinet>
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <div className="title">{titleOption}</div>
                    <div className="description">
                        Thông tin chi tiết Hộc tủ
                    </div>
                    <InputDropdownNameCabinetRange
                        handleGetValueDropdown={_handleSetValueNameCabinetRange}
                        objChoice={{key: dataCluster?.clusterID, text: dataCluster?.clusterName}}
                    />
                    <InputOnRoadKiosk
                        handleGetValueInput={_handleSetValueOnRoadKiosk}
                        value={dataCabinet?.activePort}
                        handleValidationInput={_handleValidationInputPort}
                        error={dataCabinet.error}
                    />
                    
                    <InputIdCabinet
                        handleGetValueInput={_handleSetValueIdCabinet}
                        value={dataCabinet?.lockerID}
                        handleValidationInput={_handleValidationInput}
                        error={dataCabinet.error}
                    />
                    
                    <CheckBoxControl
                        extendStyles={{width:"80px"}}
                        iconStore={iconStoreVip}
                        isChecked={dataCabinet?.vipStatus}
                        handleCheck={()=>{handleCheckVip(dataCabinet?.vipStatus)}}
                    >Tủ VIP</CheckBoxControl>
                    <div className='title-status'>Trạng thái kích hoạt</div>
                    <CheckBoxControl
                        extendStyles={{width:"100px"}}
                        iconStore={iconStatusActive}
                        isChecked={dataCabinet?.activeStatus}
                        handleCheck={()=>{handleCheckStatus(dataCabinet?.activeStatus)}}
                    >Kích hoạt</CheckBoxControl>
                    
                    <div className='btn-container'>
                        <Button
                            text={textBtn}
                            iconBtn={iconBtn}
                            className="btn-edit"
                            onClick={onSubmit}
                        />
                    </div>
                </WrapFormOptionCabinet>
            </div>
        </OverlayFullScreen>

    )
}

export default FormOptionCabinet
