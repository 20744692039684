import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import { TableContentKiosk } from "./index.styles";

//component
import KioskItem from "./KioskItem";
import EmptyDataControl from "../../../../Control/EmptyDataControl";

//images
import IcNoData from "../../../../../assets/images/IcNoData";
import { useNavigate } from "react-router-dom";

//constant
import {
  main,
  kioskManage,
  kioskDetails,
} from "../../../../../constant/routes";

//hook
import useScrollEnd from "../../../../../hook/useScrollEnd";

//action
import {
  getListKioskAction,
  resetListKiosk,
} from "../../../../../redux/kiosk/action";
import IcNoResult from "../../../../../assets/images/IcNoResult";
import { resetListLockerAll } from "../../../../../redux/locker/action";

export default function ListKiosk(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef(null);

  const { listKiosks, branchCode, isSearching, condFilters, total } = useSelector((state) => ({
    listKiosks: state.kioskReducer.listKiosks,
    branchCode: state.login.dataLogin.branchCode,
    isSearching: state.kioskReducer.isSearching,
    condFilters: state.kioskReducer.condFilters,
    total: state.kioskReducer.total,
  }));

//   useEffect(() => {
//     // let timer = setTimeout()
//     //   const dataGetList = {
//     //     branchCode: branchCode,
//     //     filterFrom: condFilters.filterFrom,
//     //     filterSize: 9,
//     //     quickSearchData: condFilters.quickSearchData,
//     //     roomType: condFilters.roomType,
//     //     status: condFilters.status,
//     //   };
//     //   dispatch(getListKioskAction(dataGetList));
      
// }, []);

  useEffect(()=>{
    return () => {
      dispatch(resetListKiosk());
      dispatch(resetListLockerAll())
    };
  },[])
  
  // useScrollEnd(
  //   listRef,
  //   (callback) => {
  //     dispatch(
  //       getListKioskAction(
  //         {
  //           branchCode: branchCode,
  //           filterFrom: listKiosks[listKiosks.length - 1].id,
  //           filterSize: 9,
  //           quickSearchData: condFilters.quickSearchData,
  //           roomType: condFilters.roomType,
  //           status: condFilters.status,
  //         },
  //         true,
  //         callback
  //       )
  //     );
  //   },
  //   [listKiosks?.length],
  //   [listKiosks?.length]
  // );

    const _handleClickBtn = (id) => {
      navigate(`/${main}/${kioskManage}/${kioskDetails(id)}`)
    }

  const tableContent = useMemo(
    () =>
      listKiosks?.length === 0 ? (
        <div className="wrap-empty">
          { isSearching && (
            <EmptyDataControl
              IconNonData={<IcNoResult />}
              labelDes="Không có kết quả"
            />
          )}
          { !isSearching && (
            <EmptyDataControl
              IconNonData={<IcNoData />}
              labelDes="Hiện tại chưa có Kiosk nào"
          />
          )}
        </div>
      ) : (
        <div className="wrap-list-trans" ref={listRef}>
          <div className="list-trans">
            {listKiosks?.map((item, i) => (
              <KioskItem
                key={i}
                item={item}
                refList={listRef}
                onClick={() =>
                  _handleClickBtn(item.id)
                }
              />
            ))}
          </div>
        </div>
      ),
    [JSON.stringify(listKiosks)]
  );
  return <TableContentKiosk>{tableContent}</TableContentKiosk>;
}
