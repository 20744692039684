import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// styles
import { WrapPopupDeleteCabinet } from './index.styles'
import { OverlayFullScreen } from '../../../../../../../MainStyles/main';

//images
import IcClose from '../../../../../../../../assets/images/IcClose';
import IcTrashWhite from '../../../../../../../../assets/images/IcTrashWhite';

//control
import Button from '../../../../../../../Control/Button';
import { delLockerAction, getListLockerAction } from '../../../../../../../../redux/locker/action';




const PopupDeleteCabinet = ({ dataCabinet, handleClose }) => {

    const dispatch = useDispatch()
    
    const _handleDelete = () =>{
        const callbackSuccess = () =>{
            handleClose && handleClose()
            //get list lại ở đây
            dispatch(getListLockerAction({
                branchCode: dataCabinet?.branchCode,
                filterFrom: "",
                filterSize: 12,
                quickSearchData: "",
                lockerType: 0,
                kioskID: dataCabinet?.kioskID,
                clusterID: dataCabinet?.clusterID,
                lockerStatus: 0
            }))
        }
    
        const callbackError = () => {
    
        }
        dispatch(delLockerAction({
            id: dataCabinet?.id,
            branchCode: dataCabinet?.branchCode
        }, callbackSuccess, callbackError))
    }

    return (
            <OverlayFullScreen onClick={(e)=> e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupDeleteCabinet>
                <IcClose className="icon-close" onClick={handleClose} />
                    <div className="title">Xoá Hộc tủ?</div>
                    <div className="sub-title">
                        Bạn đang thực hiện xóa Hộc tủ, việc này sẽ không thể hoàn tác. Bạn có chắc chắn muốn xóa không?
                    </div>
                    {/* <div className="notice">
                        *Dãy tủ đang hoạt động sẽ mất liên kết với Kiosk này
                    </div> */}
                    <div className='btn-container'>
                        <Button
                            text="Hủy bỏ"
                            className="btn-cancel-del"
                            onClick={handleClose}
                        />
                        <Button
                            text="Xóa"
                            iconBtn={<IcTrashWhite />}
                            className="btn-del"
                            onClick={_handleDelete}
                        />
                    </div>
                </WrapPopupDeleteCabinet>
            </div>
        </OverlayFullScreen>        
    )
}

export default PopupDeleteCabinet
