import styled from "styled-components";

const WrapPopupFilterRange = styled.div`
    position: absolute;
    width: 400px;
    height: 244px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: -5px -5px 40px rgb(0 0 0 / 10%), 5px 5px 40px rgb(174 174 192 / 20%);
    padding: 27px 20px;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    text-align: left;
    .wrap-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-close {
            cursor: pointer;
        }
        .bold{
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.01em;
            color: #191919;
        }
    }
    .btn-confirm{
        width: 100%;
    }
    .label-value{
        display: flex;
        gap: 2px;
        margin-bottom: 8px;
        span{
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #191919;
        }
        .notice{
            color: #A31E23;
        }
    }
    .wrap-input .placeholder {
        color: #313131;
    }
    .submit-error{
        color: #F45C5C;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        margin: -15px 0 0px 15px;
    }
    .value{
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #191919;
        margin: 12px 0 20px 0;
    }
`

export {
    WrapPopupFilterRange
}