import styled from 'styled-components'

const Bound = styled.div`
    font-family: "Open Sans";
    display:flex;
    flex-direction:column;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-style: normal;

    .animation-container{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }    

    .ic-no-data {
        display:flex;
        width:140px;
        height:140px;
        margin-bottom:28px;
    }

    h1{
        font-weight: bold;
        font-size: 16px;
        line-height: 27px;
        display: flex;
        margin-bottom:7px;
        color: #222222;
    }
    p{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        color: #828282;
    }
`

export { Bound }