import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

//images
import IcEdit from '../../../../../../assets/images/IcEdit'
import IcMoreOptionWhite from '../../../../../../assets/images/IcMoreOptionWhite'
import IcTrash from '../../../../../../assets/images/IcTrash'

// component
import MoreOption from '../../../../../Control/MoreOption'
import PopupWarning from '../../../../../Control/PopupWarning'
import Portal from '../../../../../Control/Portal'
import PopupDeleteCabinetRange from './PopupDeleteCabinetRange'
import PopupEditCabinetRange from './PopupEditCabinetRange'

export const EDIT_CABINET_RANGE = 'EDIT_CABINET_RANGE'
export const DEL_CABINET_RANGE = 'DEL_CABINET_RANGE'
export const WARNING = 'WARNING'

const OptionCabinetRange = ({ dataCabinetRange, refList }) => {

    const dispatch = useDispatch()
    const [keyShowForm, setKeyShowForm] = useState('')
    let isUsing = dataCabinetRange?.lockerList?.map(item=>item.useStatus)?.includes(true)
   
    const handleEdit = () => {
        setKeyShowForm(EDIT_CABINET_RANGE)
    }

    const handleDelete = () => {
        if (isUsing){
            setKeyShowForm(WARNING)
            return
        }
        setKeyShowForm(DEL_CABINET_RANGE)
    }

    const handleClose = (e) => {
        // e.stopPropagation()
        setKeyShowForm('')
        
    }

    const dataMoreOption = [
        {
            Icon: IcEdit,
            text: "Chỉnh sửa",
            handleOption: handleEdit
        },
        {
            Icon: IcTrash,
            text: "Xoá",
            handleOption: handleDelete
        }
    ]

    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
                iconOption={<IcMoreOptionWhite/>}
            />
            {   keyShowForm === EDIT_CABINET_RANGE && (
                <Portal>
                    <PopupEditCabinetRange
                        dataCabinetRange={dataCabinetRange}
                        onClickIconClose={handleClose}
                    />
                </Portal>
            )}
            {   keyShowForm === DEL_CABINET_RANGE && (
                <Portal>
                    <PopupDeleteCabinetRange
                        dataCabinetRange={dataCabinetRange}
                        handleClose={handleClose}
                    />
                </Portal>
            )}
            { keyShowForm === WARNING && (
                <Portal>
                    <PopupWarning
                        handleClose={handleClose}
                        title="Cảnh báo xóa dãy tủ"
                        subTitle="Dãy tủ hiện có hộc tủ đang sử dụng. Bạn không thể xóa. Vui lòng kiểm tra và thực hiện lại sau!"
                    />
                </Portal>
            )}
            
        </>
    )
}

export default OptionCabinetRange
