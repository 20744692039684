import React, { useState } from 'react'
import IcAddItem from '../../../../../../../../assets/images/IcAddItem'
import { addLockerAction } from '../../../../../../../../redux/locker/action';
import FormOptionCabinet from '../FormOptionCabinet';

export default function PopupAddCabinet({ onClickIconClose, dataCluster }) {

  const apiAddCabinet = addLockerAction
  
  return (
    <FormOptionCabinet
      // arrTest={arrTest}
      dataCluster={dataCluster}
      textBtn="Thêm mới"
      iconBtn={<IcAddItem />}
      onClickIconClose={onClickIconClose}
      titleOption="Thêm mới Hộc tủ"
      apiHandleAdd={apiAddCabinet}
    />
  )
}
