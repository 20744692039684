import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import DetailInfoCabinet from './DetailCabinet'
import DetailCabinetRange from './DetailCabinetRange'
import dataHeaderCabinet from '../../../../../data/dataHeaderCabinet'

// css
import { Bound } from './index.styles'
import { CABINET_INFO, CABINET_RANGE } from '../../../../../constant/keys'
import { getInfoByDocID, getListClusterAction, resetGetInfoByDocID, resetListCluster } from '../../../../../redux/cluster/action'


export default function DetailCabinet() {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(dataHeaderCabinet[0].key);
  const [isReset, setIsReset] = useState(false)
  const listRef = useRef(null)
  const {cabinetRangeId} = useParams()

  const { branchCode, clusterInfo, condFilters } = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
    condFilters: state.clusterReducer.condFilters,
    clusterInfo: state.clusterReducer.clusterInfo,
  }))
  
  // useEffect(()=>{
  //   dispatch(getInfoByDocID({docID: cabinetRangeId}))
  //   return ()=>{
  //     dispatch(resetGetInfoByDocID())
  //   }
  // },[])

  useEffect(()=>{
    dispatch(getInfoByDocID({ docID: cabinetRangeId }));
    dispatch(getListClusterAction({branchCode: branchCode, filterFrom: condFilters.filterFrom, filterSize: condFilters.filterSize, quickSearchData: condFilters.quickSearchData, roomType: condFilters.roomType, kioskName: condFilters.kioskName}))
    return ()=>{
      dispatch(resetListCluster())
      dispatch(resetGetInfoByDocID());
    }
  },[])

  const tableContent = () => {
    switch (isActive) {
      case CABINET_INFO:
        return (
          <>
            <DetailCabinetRange refList={listRef} cabinetRangeId={cabinetRangeId} isGetNewInfo={isReset} />
          </>
        );
      case CABINET_RANGE:
        return (
          <>
            <DetailInfoCabinet clusterInfo={clusterInfo} />
          </>
        )
    }
  };
  
  return (
    <Bound>
        <div className="detail-header">
        {dataHeaderCabinet.map((item, index) => (
          <div
            key={index}
            className={`detail-item ${
              item.key === isActive ? "selected" : ""
            }`}
            onClick={() => {
              setIsActive(item.key)
              setIsReset(true)
            }}
          >
            <item.Icon className="icon" />
            <p>{item.name}</p>
          </div>
        ))}
      </div>
      <>
        {tableContent()}
      </>
    </Bound>
  )
}
