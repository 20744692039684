import styled from "styled-components";


const TableCabinet = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    .detail-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        padding: 10px 20px;
        .title-bold{
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.01em;
        }
    }
    .detail-container{
        padding: 15px 20px 0px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .wrap-empty{
            height: 100%;
        }
        .wrap-list{
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1;
            width: 100%;
            height: 100%;
        }
        .wrap-list-cabinet-kiosk{
            width: calc(100% + 20px);
            flex-grow: 1;
            overflow-x: hidden;
            position: relative;
            z-index: 0;
            margin-left: 20px;
            .wrap-list-cabinet{
                display: flex;
                flex-wrap: wrap;
                gap: 17px;
                position: absolute;
            }
        }
    }
`

const WrapButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-filter{
        background: #FFFFFF;
        color: #A31E23;
        height: 36px;
    }

`
export {
    TableCabinet,
    WrapButton
}