import React, { useEffect, useRef, useState } from "react";

// styles
import { WrapFace } from "./index.styles";
import ImageZoomDefault from "../../../assets/images/zoom_img_default.png";

export default function FaceZoom({ url, className, refList, listItem }) {
  
  const [src, setSrc] = useState("")

  useEffect(() => {
    if(!listItem) return
    if (listItem?.length > 0) {
      setSrc(listItem[0])
    }
  },[listItem])

  return (
    <WrapFace className={className}>
      {
        listItem?.length !== 0 ? (
          <>
            <img
                src={src}
                className='wrap-face-zoom'
            />
            <div className="face-img-item" >
              <div className="img-item-check">
                <div className="list-item" >
                  {listItem?.map((item, index) => (
                    <img
                      src={item}
                      key={index}
                      className={`wrap-face-item ${item === src ? "active" : ""}`}
                      onClick={() => {
                        setSrc(item)
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
          
        ) : (
          <img
            src={ImageZoomDefault}
            className='wrap-face-default'
          />
        )
      }
      
    </WrapFace>
  );
}
