import styled from 'styled-components'

import { WrapCreateAndEditPopup } from '../../../../../../MainStyles/main'

const WrapFormOptionKiosk = styled(WrapCreateAndEditPopup)`
    width: 400px;
    box-sizing: border-box;
    .title{
        margin-bottom: 8px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
    }
    .description{
        margin-bottom: 20px;
    }
    .upload-img{
        display: flex;
        flex-direction: column;
        max-width: calc(400px - 48px);
        overflow: hidden;
        .title-upload{
            margin-bottom: 12px;    
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #191919;
        }
        .wrap-list-image {
            display: flex;
            align-items: center;
            overflow: auto;
            .wrap-images{
                position: relative;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0px;
                }               
            }
        }
    }
    .submit-error{
        color: #F45C5C;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        margin: -15px 0 0px 15px;
    }
    .btn-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        .btn-edit{
            width: 100%;
        }
    }
`;



export {
    WrapFormOptionKiosk,
    
}