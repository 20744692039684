import React from "react";

export default function IcMoreOptionWhite(props) {
  return (
    <svg
      {...props}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00008 11.6667C7.32225 11.6667 7.58341 11.4055 7.58341 11.0833C7.58341 10.7612 7.32225 10.5 7.00008 10.5C6.67792 10.5 6.41675 10.7612 6.41675 11.0833C6.41675 11.4055 6.67792 11.6667 7.00008 11.6667Z"
        fill="#EFEFEF"
        stroke="#EFEFEF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00008 7.58317C7.32225 7.58317 7.58341 7.322 7.58341 6.99984C7.58341 6.67767 7.32225 6.4165 7.00008 6.4165C6.67792 6.4165 6.41675 6.67767 6.41675 6.99984C6.41675 7.322 6.67792 7.58317 7.00008 7.58317Z"
        fill="#EFEFEF"
        stroke="#EFEFEF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00008 3.50016C7.32225 3.50016 7.58341 3.239 7.58341 2.91683C7.58341 2.59466 7.32225 2.3335 7.00008 2.3335C6.67792 2.3335 6.41675 2.59466 6.41675 2.91683C6.41675 3.239 6.67792 3.50016 7.00008 3.50016Z"
        fill="#EFEFEF"
        stroke="#EFEFEF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
