import React, {useState} from "react";
import { useRef } from "react";

// css
import { Bound } from "./index.styles";

//component
import DetailInfoKiosk from './DetailKiosk'
import DetailKioskRange from "./DetailKioskRange";

import dataHeaderKiosk from "../../../../../data/dataHeaderKiosk";
import { KIOSK_INFO, KIOSK_RANGE } from "../../../../../constant/keys";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getInfoLocker, getListKioskAction, resetListKiosk } from "../../../../../redux/kiosk/action";


export default function DetailKiosk() {
  
  const {kioskId} = useParams()
  const [isActive, setIsActive] = useState(dataHeaderKiosk[0].key);
  const [isGetNewInfo, setIsGetNewInfo] = useState(false)
  const listRef = useRef(null)
  const dispatch = useDispatch()
  
  const { kioskInfo, branchCode, condFilters } = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
    kioskInfo: state.kioskReducer.kioskInfo,
    condFilters: state.kioskReducer.condFilters,
  }))

  const callbackSuccess = () => {}
  const callbackError = () => {}

  useEffect(() => {
    dispatch(getInfoLocker({docID: kioskId} ,callbackSuccess, callbackError))
    dispatch(getListKioskAction({branchCode: branchCode, filterFrom: condFilters.filterFrom, filterSize: condFilters.filterSize, roomType: condFilters.roomType, status: condFilters.status, quickSearchData: condFilters.quickSearchData }))
    return () => {
      dispatch(resetListKiosk())
    }
  },[])

  
  const tableContent = () => {
    switch (isActive) {
      case KIOSK_INFO:
        return (
          <>
            <DetailInfoKiosk refList={listRef} kioskId={kioskId} isGetNewInfor={isGetNewInfo}/>
          </>
        );
      case KIOSK_RANGE:
        return (
          <>
            <DetailKioskRange kioskInfo={kioskInfo} />
          </>
        )
    }
  };

  return (
    <Bound>
      <div className="detail-header">
        {dataHeaderKiosk.map((item, index) => (
          <div
            key={index}
            className={`detail-item ${
              item.key === isActive ? "selected" : ""
            }`}
            onClick={() => {
              setIsActive(item.key)
              setIsGetNewInfo(true)
            }}
          >
            <item.Icon className="icon" />
            <p>{item.name}</p>
          </div>
        ))}
      </div>
      <>
        {tableContent()}
      </>
    </Bound>
  );
}
