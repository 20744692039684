import styled from 'styled-components'

import { WrapCreateAndEditPopup } from '../../../../../../../MainStyles/main'

const WrapFormOptionCabinet = styled(WrapCreateAndEditPopup)`
    width: 400px;
    box-sizing: border-box;
    .title{
        margin-bottom: 8px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
    }
    .description{
        margin-bottom: 20px;
    }
    .title-status{
        margin: 20px 0 12px 0;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #191919;
    }
    .submit-error{
        color: #F45C5C;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        margin: -15px 0 0px 15px;
    }
    .btn-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        .btn-edit{
            width: 100%;
        }
    }
`;



export {
    WrapFormOptionCabinet,
    
}