import React, { useState, useRef, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDatabase, ref, onValue, off } from "firebase/database";

// styles
import { WrapPage } from "../../../MainStyles/main";
import {
  WrapNewFaceBiometric,
  WrapPageHeader,
  WrapFormSubmit,
  TableHeaderEnroll,
  TableContentEnroll,
} from "./index.styles";

// component
import Breadcrumb from "../../Breadcrumb";
import Hero from "../../Hero";
import Input from "../../../Control/Input";
import Button from "../../../Control/Button";
import ItemNewFaceBiometric from "./ItemNewFaceBiometric";
import EmptyDataControl from "../../../Control/EmptyDataControl";
import Portal from "../../../Control/Portal";

//image
import IcArrowDown from "../../../../assets/images/IcArrowDown";
import IcInputUser from "../../../../assets/images/IcInputUser";
import IcInputUserActive from "../../../../assets/images/IcInputUserActive";
import IcInputUserError from "../../../../assets/images/IcInputUserError";
import IcSearchWhite from "../../../../assets/images/IcSearchWhite";
import IcBtnUpdate from "../../../../assets/images/IcBtnUpdate";
import IcCheckWhite from "../../../../assets/images/IcCheckWhite";
import IcBtnCancel from "../../../../assets/images/IcBtnCancel";
import IcRefreshWhite from "../../../../assets/images/IcRefreshWhite";
import IcPictureUser from "../../../../assets/images/IcPictureUser";
import image_loading from "../../../../assets/images/loading.png";
import IcUnCheckBox from "../../../../assets/images/IcUnCheckBox";
import IcCheckBoxActive from "../../../../assets/images/IcCheckBoxActive";

//hook
import useScrollEnd from "../../../../hook/useScrollEnd";

//action
import {
  clearUpdateMemberList,
  confirmRegisterFaceMember,
  getDuplicateMemberList,
  getUpdateMemberList,
  resetFaceUpdateMember,
  resetSearchMemberCode,
  resetUpdateFaceMember,
  searchByMemberCode,
  searchByMemberId,
  setFaceUpdateMember,
  updateFaceMember,
} from "../../../../redux/member/action";
import { showNotify } from "../../../../redux/notifies/action";

// action type
import {
  DEVICE_ENROLLING_ERR,
  ACTION_ENROLLING_EXISTED_ERR,
  UPDATE_FACE_ERR,
  UPDATE_FACE_TIMEOUT_ERR,
  DEVICE_ERR,
  GET_LIST_UPDATE_ERR,
  UPDATE_FACE_MEMBER_ERR,
} from "../../../../redux/member/types";
import { NETWORK_ERR } from "../../../../redux/notifies/types";

// firebase
import {
  setFirebase,
  FIREBASE_NODE_DEVICE,
  FIREBASE_NODE_RECEPTION,
} from "../../../../firebase/realtimeDatabase";
import { getFileStorage } from "../../../../firebase/storage";
import { apiGetAvatar, apiGetImageFireBase } from "../../../../constant/host";
import PopupCheckFaceAccess from "./PopupCheckFace";
import CheckBoxControl from "../../../Control/CheckBoxControl";

// routes
import { main, newFaceBiometric } from "../../../../constant/routes";
import PopupConfirmPINCode from "./PopupConfirmPINCode";
import { sha256 } from "js-sha256";
import IcInputLockActive from "../../../../assets/images/IcInputLockActive";
import IcInputLock from "../../../../assets/images/IcInputLock";

const dataHeader = [
  "STT",
  "Salesforce ID",
  "Hình STH",
  "Tên hội viên",
  "Thời gian cập nhật",
];

const iconUser = {
  icon: IcInputUser,
  iconActive: IcInputUserActive,
  iconError: IcInputUserError,
};

const iconStoreVip = {
  icon: IcUnCheckBox,
  iconActive: IcCheckBoxActive,
};

const DEFAULT = "DEFAULT";
const IMAGE = "IMAGE";
const LOADING = "LOADING";

var pathNodeReceptionFirebase = "";
var pathNodeDeviceFirebase = "";

//turn ON when device enrolling
var isDeviceEnrolling = false;
//turn ON when admin owner start enrolling
var isReceptionEnrolling = false;
//turn ON when device respone result enroll
var isDeviceRespone = false;

let timer = null;
let isFirst = true;

export default function NewFaceBiometric() {
  const listRef = useRef(null);
  const memberRef = useRef(null);
  const connectedRef = useRef(null);
  const startDevice = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { salesforceID } = useParams();

  const [valueId, setValueId] = useState("");
  // const [isDisabledBtnSearch, setIsDisabledBtnSearch] = useState(true);
  const [imageState, setImageState] = useState(DEFAULT);
  const [errorInput, setErrorInput] = useState("");
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [errorFace, setErrorFace] = useState("");
  const [urlMemberPhoto, setUrlMemberPhoto] = useState("");
  const [avatarRef, setAvatarRef] = useState("");
  const [imageRef, setImageRef] = useState("");
  const [isShowPopupCheckFace, setIsShowPopupCheckFace] = useState(false);
  const [isDisconnect, setIsDisconnect] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdateMemberType, setIsUpdateMemberType] = useState(false);
  const [isShowPopupPinCode, setIsShowPopupPinCode] = useState(false);


  const NORMAL = "NORMAL";
  const VIP = "VIP";

  const {
    dataMember,
    list,
    branchCode,
    dataLogin,
    filterInfoToDay,
    identifyList,
    isSearch,
  } = useSelector((state) => ({
    dataMember: state.memberReducer.data,
    list: state.memberReducer.list,
    branchCode: state.login.dataLogin.branchCode,
    dataLogin: state.login.dataLogin,
    filterInfoToDay: state.memberReducer.filterInfoToDay,
    identifyList: state.memberReducer.identifyList,
    isSearch: state.memberReducer.isSearch,
  }));

  const [faceUpdateVip, setFaceUpdateVip] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      fullname: dataMember.fullname || "",
      memberType: dataMember.memberType === VIP ? true : false,
    }
  );
  const _handleCheckVip = (val) => {
    setFaceUpdateVip({ memberType: !val });
  };

  const _handleChangeName = (e) => {
    let value = e.target.value.trimStart();
    let isSpace = false;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === " ") {
        if (isSpace) {
          value = value.substring(0, i) + value.substring(i + 1);
          i--;
        } else isSpace = true;
      } else isSpace = false;
    }
    setFaceUpdateVip({ fullname: value });
  };

  const callbackSuccess = () => {};
  const callbackError = () => {};
  const callBackError = () => {
    setErrorInput("Không tìm thấy thông tin hội viên trên hệ thống");
  };

  //update 09/9/2022 add func CRM
  useEffect(() => {
    if (!salesforceID) return;
    if (salesforceID) {
      setValueId(salesforceID);
      dispatch(
        searchByMemberId(
          {
            id: salesforceID.trim(),
          },
          null,
          callBackError
        )
      );
    }
  }, []);

  useEffect(() => {
    // let connectedRef = null
    // let startDevice = null
    dispatch(
      getUpdateMemberList(
        {
          branchCode: branchCode,
          filterInfo: {
            ...filterInfoToDay,
          },
        },
        false,
        callbackSuccess,
        callbackError
      )
    );
    if (dataLogin.bioDeviceID)
      pathNodeDeviceFirebase = `${FIREBASE_NODE_DEVICE}/${dataLogin.bioDeviceID}`;
    if (dataLogin.receptionID)
      pathNodeReceptionFirebase = `${FIREBASE_NODE_RECEPTION}/${dataLogin.receptionID}`;
    if (dataLogin.bioDeviceID) {
      _startListenConnectedState(connectedRef);
      _startListenFirebaseDevice(startDevice);
    }
    return () => {
      timer && clearTimeout(timer);
      connectedRef.current && off(connectedRef.current);
      startDevice.current && off(startDevice.current);

      dispatch(resetSearchMemberCode());
      if (isReceptionEnrolling) {
        if (
          !pathNodeReceptionFirebase ||
          pathNodeReceptionFirebase.length === 0
        )
          return;
        let dataWebPortal = {
          Action: "DONE",
          SessionID: `${new Date().getTime()}`,
          IsOnline: 1,
        };
        setFirebase(pathNodeReceptionFirebase, dataWebPortal);
        isReceptionEnrolling = false;
      }
    };
  }, []);

  useEffect(() => {
    setFaceUpdateVip({
      fullname: dataMember.fullname,
      memberType: dataMember.memberType === VIP ? true : false,
    });
  }, [dataMember]);

  useEffect(() => {
    let memberTypeSelected = faceUpdateVip.memberType === true ? VIP : NORMAL;
    if (memberTypeSelected !== dataMember.memberType) {
      setIsUpdateMemberType(true);
      return;
    } else {
      setIsUpdateMemberType(false);
    }
  }, [faceUpdateVip.memberType, dataMember]);

  useEffect(() => {
    if (!isSearch) {
      setValueId("");
    }
  }, [isSearch]);

  useEffect(() => {
    if (!dataMember || !dataMember.memberSFID) return;
    memberRef.current = dataMember;
    if (isReceptionEnrolling || !isSearch) {
      _handleCancel();
    }
    if (timer) {
      clearTimeout(timer);
    }
    if (!dataMember.facePhotoPath || dataMember.facePhotoPath.length === 0)
      return;

    setAvatarRef(dataMember.avatarPhotoPath);
    setImageRef(dataMember.facePhotoPath);
    const callbackGetFileStorageSuccess = (url) => {
      setUrlMemberPhoto(url);
      setTimeout(() => {
        setImageState(IMAGE);
      }, 500);
    };
    getFileStorage(dataMember.facePhotoPath, callbackGetFileStorageSuccess);
    if (dataMember?.isDuplicate){
      setErrorFace("Khuôn mặt bị trùng!")
      dispatch(getDuplicateMemberList({memberID: dataMember.memberID}))
    }
    return () => {};

  }, [dataMember]);

  const _startListenConnectedState = (connectedRef) => {
    const db = getDatabase();
    connectedRef.current = ref(db, ".info/connected");
    onValue(connectedRef.current, (snap) => {
      if (isFirst) {
        isFirst = false;
        return;
      }
      if (snap.val() === true) {
        console.log("connected");
        setIsDisconnect(false);
      } else {
        console.log("not connected");
        setIsDisconnect(true);
        dispatch(showNotify(NETWORK_ERR));
      }
    });
  };

  const _startListenFirebaseDevice = (startDevice) => {
    if (!pathNodeDeviceFirebase) return;
    const db = getDatabase();
    startDevice.current = ref(db, pathNodeDeviceFirebase);
    onValue(startDevice.current, (snapshot) => {
      const data = snapshot.val();
      if (!data) return;
      const { Status } = data;
      console.log(Status);
      switch (Status) {
        case "ENROLLING":
          {
            isDeviceEnrolling = true;
          }
          break;
        case "DONE":
          {
            isDeviceEnrolling = true;
            isDeviceRespone = true;
            if (timer) {
              clearTimeout(timer);
            }
            if (!memberRef?.current?.memberSFID) return;
            if (!isReceptionEnrolling) return;
            if (
              !pathNodeReceptionFirebase ||
              pathNodeReceptionFirebase.length === 0
            )
              return;
            let dataWebPortal = {
              Action: "DONE",
              SessionID: `${new Date().getTime()}`,
              IsOnline: 1,
            };
            setFirebase(pathNodeReceptionFirebase, dataWebPortal);
            isReceptionEnrolling = false;
            //
            const { ImageRef, AvatarRef } = data;
            dispatch(setFaceUpdateMember(ImageRef, AvatarRef));
            setAvatarRef(AvatarRef);
            setImageRef(ImageRef);
            setIsUpdate(true);
          }
          break;
        case "WAITING": {
          isDeviceEnrolling = false;
          if (timer) {
            clearTimeout(timer);
          }
          if (isReceptionEnrolling) {
            _handleDeviceStopEnroll();
            if (!isDeviceRespone) {
              if (
                memberRef.current.memberSFID &&
                memberRef.current.memberSFID.length > 0
              )
                dispatch(showNotify(UPDATE_FACE_TIMEOUT_ERR));
            }
          }
          break;
        }
        case "FAIL":
          {
            isDeviceEnrolling = false;
            isDeviceRespone = true;
            if (!memberRef.current.memberSFID) return;
            if (isReceptionEnrolling) {
              dispatch(showNotify(UPDATE_FACE_ERR));
              _handleDeviceStopEnroll();
            }
            if (timer) {
              clearTimeout(timer);
            }
          }
          break;
        default:
          break;
      }
    });
  };

  const _handleDeviceStopEnroll = () => {
    if (
      !memberRef.current.facePhotoPath ||
      memberRef.current.facePhotoPath.length === 0
    )
      setImageState(DEFAULT);
    else setImageState(IMAGE);
    if (!pathNodeReceptionFirebase || pathNodeReceptionFirebase.length === 0)
      return;
    let dataWebPortal = {
      Action: "DONE",
      SessionID: `${new Date().getTime()}`,
      IsOnline: 1,
    };
    setFirebase(pathNodeReceptionFirebase, dataWebPortal);
    isReceptionEnrolling = false;
  };

  const _handleValidateInput = () => {
    setErrorInput("");
  };

  const _handleValidationName = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      callback("Thông tin không được để trống");
      return;
    }
  };

  const _handleSearchId = () => {
    const dataRequest = {
      id: valueId.trim(),
    };
    // chờ API CRM fix lại
    if (valueId.length > 0) {
      dispatch(searchByMemberId(dataRequest, null, callBackError));
      navigate(`/${main}/${newFaceBiometric}/${valueId}`);
    } else {
      setErrorInput("Không được để trống");
    }
    setImageState(DEFAULT);
  };

  const _handleChange = (e) => {
    let value = e.target.value.trimStart();
    let isSpace = false;
    for (let i = 0; i < value.length; i++) {
      if (value[i] === " ") {
        if (isSpace) {
          value = value.substring(0, i) + value.substring(i + 1);
          i--;
        } else isSpace = true;
      } else isSpace = false;
    }
    setValueId(value);
    setErrorInput("");
  };

  const _resetData = () => {
    setValueId("");
    setFaceUpdateVip({ fullname: "", memberType: false });
    setImageState(DEFAULT);
    setErrorFace("");
    setUrlMemberPhoto("");
    setAvatarRef("");
    setImageRef("");
    setIsShowConfirm(false);
    setIsUpdate(false);
    setIsShowPopupPinCode(false);
    dispatch(resetSearchMemberCode());
  };

  const _handleCancel = () => {
    _resetData();
    if (isReceptionEnrolling) {
      let dataWebPortal = {
        Action: "CANCEL",
        SessionID: `${new Date().getTime()}`,
        IsOnline: 1,
      };
      setFirebase(pathNodeReceptionFirebase, dataWebPortal);
      isReceptionEnrolling = false;
    }
    // update 27/09/2022 add CRM
    navigate(`/${main}/${newFaceBiometric}`);
  };

  const callbackRequestUpdateFace = () => {
    setImageState(LOADING);
  };

  const _handleUpdateImage = () => {
    if (isDisconnect) {
      dispatch(showNotify(NETWORK_ERR));
      return;
    }
    setErrorFace("");
    if (isDeviceEnrolling) {
      dispatch(showNotify(DEVICE_ENROLLING_ERR));
      return;
    }
    if (isReceptionEnrolling) {
      dispatch(showNotify(ACTION_ENROLLING_EXISTED_ERR));
      return;
    }
    if (!pathNodeReceptionFirebase || pathNodeReceptionFirebase.length === 0)
      return;
    setImageState(LOADING);
    let dataWebPortal = {
      Action: "START",
      SessionID: `${new Date().getTime()}`,
      IsOnline: 1,
      cid: memberRef.current.memberSFID,
    };
    setFirebase(pathNodeReceptionFirebase, dataWebPortal);
    isReceptionEnrolling = true;
    isDeviceRespone = false;

    timer = setTimeout(() => {
      if (
        !memberRef.current.facePhotoPath ||
        memberRef.current.facePhotoPath.length === 0
      )
        setImageState(DEFAULT);
      else setImageState(IMAGE);
      if (!pathNodeReceptionFirebase || pathNodeReceptionFirebase.length === 0)
        return;
      if (isReceptionEnrolling) {
        let dataWebPortal = {
          Action: "CANCEL",
          SessionID: `${new Date().getTime()}`,
          IsOnline: 1,
        };
        if (
          memberRef.current.memberSFID &&
          memberRef.current.memberSFID.length > 0
        )
          dispatch(showNotify(DEVICE_ERR));
        setFirebase(pathNodeReceptionFirebase, dataWebPortal);
        isReceptionEnrolling = false;
      }
    }, 35000);
  };

  const callbackUpdateFaceMember = () => {
    _resetData();
    dispatch(
      getUpdateMemberList(
        {
          branchCode: branchCode,
          filterInfo: {
            ...filterInfoToDay,
          },
        },
        false,
        callbackSuccess,
        callbackError
      )
    );
    //update 27/9/2022 add CRM
    navigate(`/${main}/${newFaceBiometric}`);
  };

  const callbackErrorFaceMember = () => {
    setErrorFace("Khuôn mặt bị trùng!");
  };

  const _handleUpdateFaceMember = (pinCode) => {
    if (isDisconnect) {
      dispatch(showNotify(NETWORK_ERR));
      return;
    }
    const dataRequest = {
      branchCode: branchCode,
      memberSFID: dataMember.memberSFID,
      memberCode: dataMember.memberCode,
      photo: imageRef,
      avatar: avatarRef,
      memberType: faceUpdateVip.memberType === true ? VIP : NORMAL,
      fullName: faceUpdateVip.fullname,
      pinCode: pinCode ?  btoa(sha256(pinCode)) : "",
    };

    dispatch(
      updateFaceMember(
        dataRequest,
        callbackUpdateFaceMember,
        callbackErrorFaceMember
      )
    );
    setIsShowConfirm(false);
  };

  const _handleConfirmRegisterFaceMember = (pinCode) => {
    if (isDisconnect) {
      dispatch(showNotify(NETWORK_ERR));
      return;
    }
    let identifyListId = identifyList?.map((item) => item.id);
    const dataRequest = {
      duplicateList: identifyListId,
      data: {
        branchCode: branchCode,
        memberSFID: dataMember.memberSFID,
        memberCode: dataMember.memberCode,
        photo: imageRef,
        avatar: avatarRef,
        memberType: faceUpdateVip.memberType === true ? VIP : NORMAL,
        fullName: faceUpdateVip.fullname,
        pinCode: btoa(sha256(pinCode)),
      },
    };

    dispatch(
      confirmRegisterFaceMember(dataRequest, callbackUpdateFaceMember, () => {})
    );
  };

  const _handleClickUpdate = () => {
    if (errorFace) {
      //update 16/11/2022 add poup confirm pincode
      setIsShowPopupPinCode(true);
      return;
    }
    if (dataMember.isExistedFace) {
      setIsShowConfirm(true);
      return;
    }
    _handleUpdateFaceMember();
  };

  useScrollEnd(
    listRef,
    (callback) => {
      dispatch(
        getUpdateMemberList(
          {
            branchCode: branchCode,
            filterInfo: {
              ...filterInfoToDay,
              cursor: list[list.length - 1].ID,
              limit: 15,
            },
          },
          true,
          callback
        )
      );
    },
    [list],
    [list.length]
  );

  const tableContent = useMemo(
    () =>
      list.length === 0 ? (
        <div className="wrap-empty">
          <EmptyDataControl labelDes="Hiện tại chưa có lượt đăng ký nào hôm nay" />
        </div>
      ) : (
        <div className="wrap-list-trans" ref={listRef}>
          <div className="list-trans">
            {list.map((item, i) => (
              <ItemNewFaceBiometric
                key={i}
                index={i}
                className="history-item"
                item={item}
                refList={listRef}
              />
            ))}
          </div>
        </div>
      ),
    [JSON.stringify(list)]
  );

  return (
    <WrapPage>
      <Hero />
      <Breadcrumb />
      <WrapNewFaceBiometric>
        <WrapPageHeader>
          <IcArrowDown />
          <p className="title-header">Thông tin đăng ký</p>
        </WrapPageHeader>
        <WrapFormSubmit>
          <div className="wrap-content">
            <div className="form-content">
              <div className="form-id">
                <p className="form-title">Salesforce ID</p>
                <p className="notify">*</p>
              </div>
              <Input
                error={errorInput}
                placeholder="Id"
                inputType="TEXT"
                value={valueId}
                onChange={_handleChange}
                iconInput={iconUser}
                handleValidationInput={_handleValidateInput}
                className="input-enroll"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    _handleSearchId();
                  }
                }}
                disabled={Object.keys(dataMember).length !== 0}
              />
              <div className="content-btn">
                <Button
                  text="Kiểm tra"
                  iconBtn={<IcSearchWhite />}
                  className="btn-search"
                  onClick={_handleSearchId}
                  isDisable={Object.keys(dataMember).length !== 0}
                />
              </div>
            </div>
            {Object.keys(dataMember).length !== 0 && (
              <div className="form-info">
                <div className="face-content">
                  <p className="form-title">Hình khuôn mặt</p>
                  {imageState === IMAGE && (
                    <div
                      className={`facePrint-image ${
                        !!errorFace ? "face-error" : ""
                      }`}
                    >
                      <img src={urlMemberPhoto} alt="face by Id" />
                      <IcRefreshWhite
                        className={ dataLogin?.updateFaceImagePermission ? "remove-face" : "remove-face-hide" }
                        onClick={_handleUpdateImage}
                      />
                    </div>
                  )}
                  {imageState === DEFAULT && (
                    <div className="facePrint-image default">
                      <IcPictureUser className="icon_faceUser" />
                      <p className={ dataLogin?.updateFaceImagePermission ? "update" : "update-hide" } onClick={_handleUpdateImage}>
                        Bổ sung ngay
                      </p>
                    </div>
                  )}
                  {imageState === LOADING && (
                    <div className="facePrint-image default">
                      <img
                        src={image_loading}
                        alt="face by Id"
                        className="loading-image"
                      />
                    </div>
                  )}
                  {!!errorFace && (
                    <div className="error-check-face">
                      <div className="error-face">{errorFace}</div>
                      <div
                        className="error-popup"
                        onClick={() => setIsShowPopupCheckFace(true)}
                      >
                        Xem danh sách
                      </div>
                    </div>
                  )}
                </div>
                <div className="info-content">
                  <p className="form-title">Thông tin</p>
                  <div className="info">
                    <p className="title">Tên hội viên</p>
                    <p className="notify">*</p>
                  </div>
                  <Input
                    placeholder="Nhập tên hội viên"
                    inputType="TEXT"
                    className="input-info"
                    iconInput={iconUser}
                    value={faceUpdateVip.fullname}
                    onChange={_handleChangeName}
                    handleValidationInput={_handleValidationName}
                    maxLength={100}
                    // disabled={isShowConfirm}
                    disabled={true}
                  />
                  <CheckBoxControl
                    extendStyles={{ width: "80px" }}
                    iconStore={iconStoreVip}
                    className="info-check-member"
                    isChecked={faceUpdateVip?.memberType}
                    handleCheck={() => {
                      _handleCheckVip(faceUpdateVip?.memberType);
                    }}
                    isDisabled={isShowConfirm}
                  >
                    Hội viên S - Member
                  </CheckBoxControl>
                </div>
                <div className="info-btn">
                  <div className="wrap-btn-update">
                    <Button
                      text="Cập nhật"
                      iconBtn={<IcBtnUpdate />}
                      className="btn-search"
                      isDisable={
                        !dataMember.facePhotoPath ||
                        [DEFAULT, LOADING].includes(imageState) ||
                        !faceUpdateVip.fullname ||
                        (!isUpdate && !isUpdateMemberType && !dataMember?.isDuplicate)
                      }
                      onClick={_handleClickUpdate}
                    />
                    {isShowConfirm && (
                      <div className="wrap-confirm">
                        <div className="title">Cập nhật khuôn mặt</div>
                        <div className="desc">
                          Khuôn mặt khách hàng này sẽ được cập nhật mới.
                        </div>
                        <Button
                          text="Đồng ý"
                          iconBtn={<IcCheckWhite />}
                          className="btn-accept"
                          onClick={()=>_handleUpdateFaceMember()}
                        />
                        <Button
                          text="Hủy bỏ"
                          className="btn-reject"
                          onClick={() => setIsShowConfirm(false)}
                        />
                      </div>
                    )}
                    {isShowPopupPinCode && (
                      <Portal>
                        <PopupConfirmPINCode
                          onClickIconClose={() => setIsShowPopupPinCode(false)}
                          handleRegister={_handleConfirmRegisterFaceMember}
                          handleUpdate={_handleUpdateFaceMember}
                          isDuplicate={dataMember?.isDuplicate}
                        />
                      </Portal>
                    )}
                  </div>
                  <Button
                    text="Hủy"
                    iconBtn={<IcBtnCancel />}
                    className="btn-cancel"
                    onClick={_handleCancel}
                  />
                </div>
              </div>
            )}
          </div>
        </WrapFormSubmit>
        <WrapPageHeader>
          <IcArrowDown />
          <p className="title-header">Danh sách khách hàng</p>
        </WrapPageHeader>
        <TableHeaderEnroll>
          {list.length !== 0 ? (
            dataHeader.map((item, i) => (
              <div className="table-header" key={i}>
                {item}
              </div>
            ))
          ) : (
            <></>
          )}
        </TableHeaderEnroll>
        <TableContentEnroll>{tableContent}</TableContentEnroll>
      </WrapNewFaceBiometric>
      {isShowPopupCheckFace && (
        <Portal>
          <PopupCheckFaceAccess
            dataHeader={dataHeader}
            urlMemberPhoto={urlMemberPhoto}
            onClickIconClose={() => setIsShowPopupCheckFace(false)}
            identifyList={identifyList}
          />
        </Portal>
      )}
    </WrapPage>
  );
}
