import styled from "styled-components";
import {WrapPageItem, TableHeader, TableContent} from "../../../../MainStyles/main"

const WrapKiosk = styled(WrapPageItem)`
`;

const TableContentKiosk = styled(TableContent)`
  .wrap-list-trans{
    margin-top: 0;
    .list-trans{
      display: grid;
      grid-template-columns: 360px 360px 360px;
      grid-gap: 20px;
    }
  }
  
`;

export { WrapKiosk, TableContentKiosk };
