import Axios from "axios";

//host
import { host } from "../../constant/host";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";
import checkSessionLogin from "../../helper/checkSessionLogin";
import { logoutAction } from "../login/action";
import { GET_LIST_DEVICE, GET_LIST_DEVICE_ERR, GET_STATUS_DEVICE, OPEN_DOOR, OPEN_DOOR_ERR, OPEN_DOOR_SUCCESS } from "./type";



const apiGetAllDevice = `${host}/portal/ioDevice/getAll`;
const apiOpenDoor = `${host}/portal/ioDevice/openDoor`;

const headerDataRequest = {
    cid: 'web',
    reqId: `${new Date().getTime()}`,
    langCode: 'vn'
}

export const getListDevice = (data={branchCode: ""}, callbackSuccess, callbackError) => async (dispatch, getState) => {

    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    let dataRequest = { ...headerDataRequest, data: data, cid: dataLogin.id };
    const requestData = JSON.stringify(dataRequest);

    try {
        const res = await Axios.post(apiGetAllDevice, requestData);
        const { data } = res;
        
        if (checkIsSuccessAction(data.code)) {
           
            dispatch({
                type: GET_LIST_DEVICE,
                data: data.data,
            });
            callbackSuccess && callbackSuccess()
            
        }
        else {
            dispatch({type: GET_LIST_DEVICE_ERR, payload: {code: 0}})
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, GET_LIST_DEVICE_ERR))
    }
}

export const openDoor = (data={branchCode: "", deviceID: ""}, callbackSuccess, callbackError) => async (dispatch, getState) => {

    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    let dataRequest = { ...headerDataRequest, data: data, cid: dataLogin.id };
    const requestData = JSON.stringify(dataRequest);

    try {
        const res = await Axios.post(apiOpenDoor, requestData);
        const { data } = res;
        if (checkIsSuccessAction(data.code)) {
            dispatch({
                type: OPEN_DOOR,
                payload: {code: 0}
            })
            callbackSuccess && callbackSuccess()
            
        }
        else {
            dispatch({type: OPEN_DOOR_ERR, payload: {code: 0}})
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, OPEN_DOOR_ERR))
    }
}

export const getStatusDevice = (data) => dispatch => {
    dispatch({
        type: GET_STATUS_DEVICE,
        payload: data
    })
}

