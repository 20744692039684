import React from "react";

export default function IcTrashWhite(props) {
  return (
    <svg
      {...props}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 3.5H2.91667H12.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 3.50008V2.33341C4.66675 2.024 4.78966 1.72725 5.00846 1.50846C5.22725 1.28966 5.524 1.16675 5.83342 1.16675H8.16675C8.47617 1.16675 8.77291 1.28966 8.99171 1.50846C9.2105 1.72725 9.33342 2.024 9.33342 2.33341V3.50008M11.0834 3.50008V11.6667C11.0834 11.9762 10.9605 12.2729 10.7417 12.4917C10.5229 12.7105 10.2262 12.8334 9.91675 12.8334H4.08341C3.774 12.8334 3.47725 12.7105 3.25846 12.4917C3.03966 12.2729 2.91675 11.9762 2.91675 11.6667V3.50008H11.0834Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16675 6.41675V9.91675"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83325 6.41675V9.91675"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
