import React, { useEffect, useState, useRef } from 'react'

import { useOnClickOutside } from '../../../hook/useClickOutside'
import IcArrowDownGray from '../../../assets/images/IcArrowDownGray'
import DropdownList from "../../Control/DropdownList"

//style
import { WrapInputDropdown } from './index.styles'

const SELECT = "SELECT"
const SELECT_ACTIVE = "SELECT_ACTIVE"
const SELECT_ERROR = "SELECT_ERROR"

const InputDropdown = ({ 
    className,
    title,
    iconInput, 
    getValueDropdown, 
    placeholder, 
    dataFilterOption, 
    objChoice, 
    maxItemShow=5,
    required,
    isDisable,
    handleValidationInput,
    error,
    isLengthStringBig,
    handleScrollEnd=()=>{} 
}) => {

    const [isShowValueDropdown, setIsShowValueDropdown] = useState(false)
    const [typeInput, setTypeInput] = useState(SELECT)
    const [valueDropdown, setValueDropdown] = useState(objChoice)
    const [borderActive, setBorderActive] = useState(false)
    const [errorInput, setErrorInput] = useState("")
    const [errorBorder, setErrorBorder] = useState(false)
    const [isBlur, setIsBlur] = useState(false)

    const refOption = useRef(null)

    useEffect(() => {
        setValueDropdown(objChoice)
    }, [JSON.stringify(objChoice)])

    useEffect(() => {
        setErrorInput(error)
    }, [error]);
    
    useEffect(() => {
        if(!isShowValueDropdown && !valueDropdown) {
            setTypeInput(SELECT)
            setBorderActive(false)
            if (errorInput){
                setTypeInput(SELECT_ERROR)
            }
            if(isBlur && required){
                setTypeInput(SELECT_ACTIVE)
                setErrorBorder(true)
                const callback = (textErrorInput) => {
                    setErrorInput(textErrorInput)
                }
                handleValidationInput && handleValidationInput(objChoice, callback)
            }
            return;
        }

        if(!isShowValueDropdown && valueDropdown) {
            setTypeInput(SELECT_ACTIVE)
            setBorderActive(false)
            return;
        }

        if(isShowValueDropdown) {
            setTypeInput(SELECT_ACTIVE)
            setBorderActive(true)
            setErrorBorder(false)
            setErrorInput("")
            return;
        }
    }, [isShowValueDropdown, valueDropdown, errorInput])
   
    //handle typeInput show icon
     const handleTypeInput = (type) => {
        if (!iconInput) return
        switch (type) {
            case SELECT:
                return <iconInput.icon className="icon-left" />
            case SELECT_ACTIVE:
                return <iconInput.iconActive className="icon-left" />
            case SELECT_ERROR:
                return <iconInput.iconError className="icon-left" />
            default: return;
        }
    }
    const iconTypeInput = handleTypeInput(typeInput)

    const _handleSetValueSelect = (val) => {
        setIsShowValueDropdown(!isShowValueDropdown)
        setValueDropdown(val)
        setErrorInput("")
        setErrorBorder(false)
        getValueDropdown && getValueDropdown(val)
    }

    const _handleShowValueDropdown = () =>{
        setIsShowValueDropdown(!isShowValueDropdown)
        setIsBlur(true)

    }
     
    const textDropdown = valueDropdown ? valueDropdown.text : placeholder

    const classTextDropdown = valueDropdown ? "text-dropdown" : "placeholder"

    useOnClickOutside(refOption, () => {
        setIsShowValueDropdown(false)
        setIsBlur(true)
    })

    return (
        <WrapInputDropdown ref={refOption} className={`${className}`}>
            {title && (
                 <div className='input-dropdown-title'>
                    <span>{title}</span>
                    {(required) ? <span className='notice'>*</span> : ''}
                </div>
            )}
            <div className={`wrap-input${borderActive ? " active" : ""}${isDisable ? " disable" : ""}${errorBorder || errorInput ? " error-input" : ""}`} onClick={_handleShowValueDropdown} >
                {iconInput &&
                        iconTypeInput
                }
                <p className={classTextDropdown} title={valueDropdown?.text}>
                    {textDropdown?.length > 40 ? `${textDropdown?.substring(0,40)}...` : textDropdown}
                </p>
                <IcArrowDownGray className="arrow-down"/>
                {
                    isShowValueDropdown &&
                    <DropdownList
                        isShowCheckStatus={{isShow: true, isSingleChoice: true}}
                        className="wrap-dropdown"
                        listItem={dataFilterOption}                     
                        valueSelected={valueDropdown}
                        setValueSelect={_handleSetValueSelect}
                        maxItemShow={maxItemShow}
                        handleScrollEnd={handleScrollEnd}
                        isLengthStringBig = {isLengthStringBig}
                    />
                }
            </div>
            {
                errorInput &&
                <div className="error">{errorInput}</div>
            }
        </WrapInputDropdown>
    )
}

export default InputDropdown
