import styled from 'styled-components'

const WrapSideMenu = styled.div`
    box-shadow: inset -2px 0px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 130px;
    .link-item{
        text-decoration: none;
        width: 100%;
        margin-bottom: 25px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-left: 5px solid transparent;
        .icon{
            display: block;
        }
        .icon.active{
            display: none;
        }
        p{
            color: #313131;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            margin-top: 4px;
            width: 80px;
            text-align: center;
        }
        &.selected{
            border-left: 5px solid #A31E23;
            /* box-sizing: border-box; */
            .icon.active{
                display: block;
            }
            .icon{
                display: none;
            }
        }

    }
`;

export {
    WrapSideMenu
}