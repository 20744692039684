
// routes
import * as routes from '../constant/routes'

// images
import IcUserPlus from '../assets/images/IcUserPlus'
import IcUserPlusActive from '../assets/images/IcUserPlusActive'
import IcUserCheck from '../assets/images/IcUserCheck'
import IcUserCheckActive from '../assets/images/IcUserCheckActive'
import IcKiosk from '../assets/images/IcKiosk'
import IcKioskActive from '../assets/images/IcKioskActive'
import IcCabinet from '../assets/images/IcCabinet'
import IcCabinetActive from '../assets/images/IcCabinetActive'

const dataMenu = [
    {
        path: `/${routes.main}/${routes.newFaceBiometric}`,
        Icon: IcUserPlus,
        IconActive: IcUserPlusActive,
        name: 'Thêm STH khuôn mặt',
        titleBreadcumb: 'Thêm mới',
        key: routes.newFaceBiometric
    },
    {
        path: `/${routes.main}/${routes.faceBiometric}`,
        Icon: IcUserCheck,
        IconActive: IcUserCheckActive,
        name: 'Dữ liệu STH khuôn mặt',
        titleBreadcumb: 'Dữ liệu STH',
        key: routes.faceBiometric
    },
    {
        path: `/${routes.main}/${routes.kioskManage}`,
        Icon: IcKiosk,
        IconActive: IcKioskActive,
        name: 'Quản lý Kiosk',
        titleBreadcumb: 'Danh sách Kiosk',
        key: routes.kioskManage
    },
    {
        path: `/${routes.main}/${routes.cabinetRangeManage}`,
        Icon: IcCabinet,
        IconActive: IcCabinetActive,
        name: 'Quản lý dãy tủ',
        titleBreadcumb: 'Danh sách dãy tủ',
        key: routes.cabinetRangeManage
    },
    // {
    //     path: `/${routes.main}/${routes.advertismentManage}`,
    //     Icon: IcUserPlus,
    //     IconActive: IcUserPlusActive,
    //     name: 'Quản lý quảng cáo',
    //     titleBreadcumb: 'Danh sách quảng cáo'
    // },
    
]

export default dataMenu