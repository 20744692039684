import styled from "styled-components";

const WrapFace = styled.div`
    height: 100%;
    width: 100%;

    .wrap-face-default{
        width: 320px;
        height: 320px;
        border-radius: unset;
    }
    .wrap-face-zoom{
        width: calc(320px - 4px);
        height: calc(320px - 4px);
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
        border: 2px solid #EFEFEF;
        border-radius: 4px;
        object-fit: contain;
    }
    .face-img-item{
        display: flex;
        margin-top: 10px;
        width: 320px;
        height: 120px;
        .img-item-check{
            width: calc(100% - 10px);
            flex-grow: 1;
            overflow: overlay;
            position: relative;
            z-index: 0;
            height: 100%;
            .list-item{
                position: absolute;
                display: flex;
                height: 100%;
                gap: 10px;
                .wrap-face-item{
                    cursor: pointer;
                    width: calc(110px - 4px);
                    height: calc(110px - 4px);
                    object-fit: contain;
                    opacity: 0.4;
                    border: 2px solid transparent;
                    &.active{
                        opacity: 1;
                        border: 2px solid #A31E23;
                        border-radius: 4px;
                    }
                }

            }
        }
    }
`

export {WrapFace};