import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

//imgs
import IcClose from '../../../../../assets/images/IcClose'

//control
import Button from '../../../../Control/Button'
import InputPassword from '../../../../Control/InputPassword'

//styles
import { OverlayFullScreen } from '../../../../MainStyles/main'
import { WrapPopupConfirmPINCode } from './index.styles'

//helper
import { checkValidation, getStrValidation } from "../../../../../helper/validation"
import Input from '../../../../Control/Input'
import IcInputUser from '../../../../../assets/images/IcInputUser'
import IcInputUserActive from '../../../../../assets/images/IcInputUserActive'
import IcInputUserError from '../../../../../assets/images/IcInputUserError'
import IcConfirmPinCode from '../../../../../assets/images/IcConfirmPinCode'
import IcCheckConfirmWhite from '../../../../../assets/images/IcCheckConfirmWhite'

const iconUser = {
    icon: IcInputUser,
    iconActive: IcInputUserActive,
    iconError: IcInputUserError,
}

export default function PopupConfirmPINCode({onClickIconClose, handleRegister,isDuplicate, handleUpdate}){

    const dispatch = useDispatch()
    const [pinCode, setPinCode] = useState('')
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")


    const _handleChangePinCode = (event) => {
        const {name, value} = event.target
        setPinCode(value)
        setError(false)
        setErrorText("")
    }

    const _handleValidationPinCode = (value, callback) => {
        if (!value?.trim() || 0 === value.length) {
            setError(true)
            callback("Thông tin không được để trống")
            return
        }
        if(value.length < 6 || checkValidation.limitSpace(value) || checkValidation.errorStrMustHaveNumAndChar(value)){
            setError(true)
            callback(getStrValidation.errorFormat)
            return
        }
    }

    const _handleSubmit = () => {
        if (!pinCode){
            setErrorText("Thông tin không được để trống")
            return
        }
        if ( error ) return
        if (isDuplicate){
            handleUpdate && handleUpdate(pinCode)
            return
        }
        handleRegister && handleRegister(pinCode)
    }


  return (
    <OverlayFullScreen onClick={(e)=>e.stopPropagation()}>
        <div className="wrap-inner-screen">
                <WrapPopupConfirmPINCode autoComplete = "off" >
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <div className="title">Nhập mã PIN</div>
                    <div className="description">
                        Mã dùng để xác minh hội viên
                    </div>
                    <div className='label-input'>
                        <span>Mã bảo mật (6 ký tự chữ và số)</span>
                        <span className='notice'>*</span>
                    </div>
                    <Input
                        autoComplete = "new-pincode"
                        placeholder="Nhập mã PIN"
                        inputType="TEXT"
                        name="pincode"
                        required
                        value={pinCode}
                        onChange={_handleChangePinCode}
                        handleValidationInput={_handleValidationPinCode}
                        maxLength={6}
                        iconInput={iconUser}
                        error={errorText}
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                if (!pinCode){
                                    setErrorText("Thông tin không được để trống")
                                    return
                                }
                                if(pinCode.length < 6 || checkValidation.limitSpace(pinCode) || checkValidation.errorStrMustHaveNumAndChar(pinCode)){
                                    setErrorText(getStrValidation.errorFormat)
                                    return
                                }
                                _handleSubmit()
                            }
                        }}
                    />
                    <div className='btn-container'>
                        <Button
                            text="Đồng ý"
                            iconBtn={ <IcCheckConfirmWhite/> }
                            className="btn-edit"
                            onClick={_handleSubmit}
                        />
                    </div>
                </WrapPopupConfirmPINCode>
            </div>
    </OverlayFullScreen>
  )
}
