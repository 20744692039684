import React, { useState } from 'react'
import IcSaveWhite from '../../../../../../../../assets/images/IcSaveWhite'
import { updateLockerAction } from '../../../../../../../../redux/locker/action';
import FormOptionCabinet from '../FormOptionCabinet';

export default function PopupEditCabinet({ onClickIconClose, dataCabinet, dataCluster }) {

  const apiEditCabinet = updateLockerAction

  return (
    <FormOptionCabinet
      dataCluster={dataCluster}
      onClickIconClose={onClickIconClose}
      titleOption="Chỉnh sửa Hộc tủ"
      dataCabinetEdit={dataCabinet}
      isDisable={true}
      textBtn="Lưu"
      iconBtn={<IcSaveWhite />}
      apiHandleEdit={apiEditCabinet}
    />
  )
}
