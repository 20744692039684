const M = 'M'
const W = 'W'
const V = 'V'
const ALL = ''
export const listRoom = {
    [ALL]:{
        key: ALL,
        text: 'Tất cả'
    },
    [M]:{
        key: 'M',
        text: 'Phòng Nam'
    },
    [W]:{
        key: 'W',
        text: 'Phòng Nữ'
    },
    [V]:{
        key: 'V',
        text: 'Phòng VIP'
    }
}