import React, { useState, useEffect } from 'react'

//style
import { WrapItem } from './index.style'

//image
import IcFacePrintDefault from '../../../../../assets/images/IcFacePrintDefault'

//tool
import { convertDateTime } from '../../../../../helper/convertDateTime'
import { apiGetAvatar } from '../../../../../constant/host'
import FaceZoomAvt from '../../../../Control/FaceZoomAvt'


const ItemFaceBiometric = (props) => {

    const { className, item, index,refList } = props

    const [isErrImg, setIsErrImg] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [item?.ID])

    return (
        <WrapItem className={className} >
            <div>{index < 9 ? `${index + 1}` : index + 1}</div>
            <div title={item.memberSFID} className="member-value" >{item?.memberSFID || "-"}</div>
            <div className="wrap-avatar">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="avatar" />
                        :
                        <FaceZoomAvt
                            // src={item?.faceID ? apiGetAvatar(item?.faceID) : ""}
                            url={apiGetAvatar(item?.ID)}
                            className="avatar"
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                            refList={refList}
                        />
                }
            </div>
            <div title={item?.fullname} className="item-name">
                {item?.fullname.length > 50 ? item?.fullname.substr(0,63) + "..." : item?.fullname || "-"}
                </div>
            <div title={item?.clubName}>{item?.clubName.length > 30 ? item.clubName.substr(0,30) + "..." : item?.clubName || "-"}</div>
            <div>{item?.createAt ? convertDateTime(item?.createAt, "DD/MM/YYYY HH:mm") : ""}</div>
            <div>
                {
                    // item?.status === true ?
                        <p className="status active">Kích hoạt </p>
                        // :
                        // <p className="status">Chưa kích hoạt </p>
                }
            </div>
        </WrapItem>
    )
}

export default ItemFaceBiometric
