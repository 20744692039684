import { type } from '@testing-library/user-event/dist/type'
import moment from 'moment'
import * as types from './types'

const defaultStartToDay = moment(new Date()).utc().startOf("day").toDate()
const defaultEndToDay = moment(new Date()).utc().endOf("day").toDate()

const defaultStartTime = moment().utc().startOf("month").toDate()
const defaultEndTime = moment(new Date()).endOf("day").toDate()

const initialState = {
    data: {},
    list: [],
    identifyList: [],
    filterInfoToDay: {
        limit: 15,
        cursor: "",
        startTime: defaultStartToDay,
        endTime: defaultEndToDay,
        quickSearchData: ""
    },
    filterInfo: {
        limit: 15,
        cursor: "",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
        quickSearchData: ""
    },
    totalUpdateToday: 0,
    totalUpdate: 0,
    isSearch: true,
    isSearching: false
}

const memberReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SEARCH_MEMBER:
            return {
                ...state,
                ...action.payload
            }
        case types.RESET_SEARCH_MEMBER: {
            return {
                ...state,
                data: initialState.data,
                identifyList: []
            }
        }
        case types.UPDATE_FACE_MEMBER_SUCCESS: {
            if (!Object.keys(state.data).length) {
                return state
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    list: [],
                    facePhotoPath: action.payload.facePhotoPath,
                }
            }
        }

        case types.GET_LIST_UPDATE:
            return {
                ...state,
                ...action.payload,
                list: action.payload.isScroll
                    ?   [...state.list, ...action.payload.list]
                    :   action.payload.list,
                isSearch: true,
                isSearching : 
                    (action.payload.filterInfo.startTime.toString() !== initialState.filterInfo.startTime.toString() ||
                    action.payload.filterInfo.endTime.toString() !== initialState.filterInfo.endTime.toString() ||
                    action.payload.filterInfo.quickSearchData !== initialState.filterInfo.quickSearchData )
                    ? true : false
            }
        

        case types.CLEAR_LIST_UPDATE:
            return {
                ...state,
                list: [],
                filterInfo: initialState.filterInfo,
                isSearching: false,
            }
            
        case types.RESET_VALUE_SEARCH: {
            return {
                ...state,
                isSearch: false,
                isSearching: false,
            }
        }

        case types.SET_FACE_UPDATE: 
            return {
                ...state,
                data: {
                    ...state.data,
                    facePhotoPath: action.payload.facePhotoPath,
                    avatarPhotoPath: action.payload.avatarPhotoPath
                }
            }
        case types.UPDATE_FACE_MEMBER_FAIL: 
            return {
                ...state,
                identifyList: action.payload.identifyList
            }
        case types.GET_LIST_DUPLICATE: 
            return {
                ...state,
                identifyList: action.payload.identifyList
            }
             
        default:
            return state;
    }

}

export default memberReducer