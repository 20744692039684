import styled from "styled-components";

const TableKiosk = styled.div`
    display: grid;
    grid-template-columns: 100px 1fr;
    height: 100%;
    .detail-cabinet-left{
        background-color: #F9F9F9;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .list-range{
            display: flex;
            flex-direction: column;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }

        .bold{
            font-size: 20px;
            font-weight: 700;
        }
        .detail-cabinet-item{   
            border-left: 5px solid transparent;
            cursor: pointer;
            height: 56px;
            &.selected{
                border-left: 5px solid #A31E23;
                background-color: #fff;
                /* box-sizing: border-box; */
            }
            .range-value{
                height: 100%;
                width: 100%;
                font-size: 11px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center
            }
        }
    }

    .detail-cabinet-right{
        display: flex;
        flex-direction: column;
        .detail-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #EFEFEF;
            padding: 10px 20px;
            .btn-filter{
                background: #FFFFFF
                height: 36px;
            }
            .title-bold{
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: -0.01em;
            }
        }
        .detail-container{
            padding: 15px 20px 0px 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            .info-container{
                display: grid;
                grid-template-columns: 100px 120px 120px 1fr;
                p{
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                }
                .value{
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #828282;
                }
            }
            .info-title{
                padding: 20px 0;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: -0.01em;        
            }
            .wrap-empty{
                height: 100%;
            }
            .wrap-list{
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                width: 100%;
                height: 100%;
                .wrap-list-cabinet-kiosk{
                    width: calc(100% + 20px);
                    flex-grow: 1;
                    overflow-x: hidden;
                    position: relative;
                    z-index: 0;
                    margin-left: 20px;
                    .wrap-list-cabinet{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 17px;
                        position: absolute;
                        .list-cabinet{
                            position: relative;
                            width: 230px;
                            height: 200px;
                            background-color: #F9F9F9;
                            border: 0.5px solid #BDBDBD;
                            border-radius: 8px;
                            overflow: hidden;
                            .item-cabinet{
                                cursor: pointer;
                            }
                            img{
                                position: absolute;
                                top: -26px;
                                right: -14px;                            
                                border-radius: 0 8px 0 0;
                            }
                            .item-check{
                                width: calc(100% - 15px);
                                background-color: #fff;
                                height: 40px;
                                position: absolute;
                                bottom: 0;
                                border-radius: 0 0 8px 8px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1), 5px 5px 40px rgba(174, 174, 192, 0.2);
                                padding-right: 15px;
                                gap: 4px;
                                p{
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 15px;
                                    text-transform: uppercase;
                                }
                            }
                            .item-value{
                                position: absolute;
                                top: 110px;
                                left: 20px;
                                p{
                                    font-weight: 700;
                                    font-size: 20px;
                                    line-height: 27px;
                                    letter-spacing: -0.01em;
                                }
                                .value{
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: 15px;
                                    letter-spacing: -0.02em;
                                }
                            }
                            .item-time{
                                position: absolute;
                                width: 74px;
                                height: 19px;
                                left: 20px;
                                top: 20px;
                                background-color: #EFEFEF;
                                border-radius: 6px;
                                p{
                                    color: #33ADFF;
                                    font-weight: 600;
                                    font-size: 11px;
                                    line-height: 15px;
                                    letter-spacing: -0.02em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const WrapButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-filter{
        background: #FFFFFF;
        color: #A31E23;
        height: 36px;
    }
    .btn-edit{
        width: 110px;
        border: 1px solid #3D8B4C;
        background-color: #fff;
        color: #3D8B4C;
        height: 36px;
        margin-left: 10px;

    }
`

export {
    WrapButton,
    TableKiosk
}