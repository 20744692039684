import styled from 'styled-components'

const WrapLogin = styled.div`
    min-width: 100vw;
    width: 1280px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #313131;
    padding: 50px 60px 56px;
    box-sizing: border-box;

    .wrap-inner-login {
        display: flex;
        height: 100%;

        .login-form{
            width: 440px;
            background: #FFFFFF;
            text-align: center;
            .icon-logo{
                margin-top: 72px;
                width: 100%;
            }
            .title{
                margin-top: 66px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                color: #313131;
                letter-spacing: -0.01em;
            }
            .note{
                margin-top: 28px;
                color: #777777;
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                font-family: "Open Sans";
                letter-spacing: -0.01em;
            }
            .form{
                margin-top: 47px;
                display: flex;
                flex-direction: column;
                padding: 0 62px;
                .error-submit{
                    color: #F45C5C;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 15px;
                    margin-bottom: -30px;
                    margin-top: 30px;
                    text-align: center;
                }
                .error{
                    margin-bottom: 22px;
                }
                .btn-login{
                    margin-top: 49px;
                }
            }
        }
        .bg_login{
            position: relative;
            max-width: 720px;
            img {
                height: 100%;
                width: 720px;
                object-fit: cover;
            }
        }
    }
`;

export {
    WrapLogin
}