import React, { useState, useEffect } from 'react'

// css
import { TablelInfoKiosk } from './index.styles'

// img
import IcEdit from "../../../../../../assets/images/IcEdit";
import IcConnect from "../../../../../../assets/images/IcConnect";
import testPNG from "../../../../../../assets/images/test.png";

// component
import Button from "../../../../../Control/Button";
import FaceZoom from '../../../../../Control/FaceZoom'
import PopupEditKiosk from '../../Listing/OptionKiosk/PopupEditKiosk'

// data
import dataImgKiosk from '../../../../../../mockdata/dataImgKiosk'
import Portal from '../../../../../Control/Portal';
import { listRoom } from '../../../../../../data/listRoom';
import { CONNECTED, DISCONNECTED } from '../../../../../../data/listKioskStatus';
import IcInputWifiOff from '../../../../../../assets/images/IcInputWifiOff';

// host
import { apiGetPhoto } from '../../../../../../constant/host';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoLocker } from '../../../../../../redux/kiosk/action';

const DetailInfoKiosk = ({ refList, kioskId, isGetNewInfor }) => {

  const dispatch = useDispatch()
  const [isShowPopupEdit, setIsShowPopupEdit] = useState(false)
  const [listImage, setListImage] = useState([])
  const { kioskInfo } = useSelector((state) => ({
    kioskInfo: state.kioskReducer.kioskInfo,
  }))

  useEffect(()=>{
    isGetNewInfor && dispatch(getInfoLocker({docID: kioskId}))
  },[])

  useEffect(() => {
    if(!kioskInfo) return
    if(!kioskInfo.photoList || kioskInfo.photoList.length === 0) return
    let list = []
    kioskInfo.photoList.forEach(item => {
      list.push(apiGetPhoto(item))
    });
    setListImage(list)
  
    return () => {
      
    }
  }, [kioskInfo])

    return (
        <TablelInfoKiosk>   
              <div className="detail-content-item">
                <p className="title">Hình thực tế</p>
                <FaceZoom url={testPNG} alt="" refList={refList} listItem={listImage}/>
              </div>
              <div className="detail-content-item">
                <p className="title">Thông tin</p>
                <div className="detail-value">
                  <p>ID Kiosk</p>
                  <p className="bold">{kioskInfo.kioskId ? kioskInfo.kioskId : "-"}</p>
                </div>
                <div className="detail-value">
                  <p>Tên Kiosk</p>
                  <p className="bold" title={kioskInfo?.kioskName}>{kioskInfo.kioskName?.length > 60 ? kioskInfo.kioskName.substr(0,60) + "...": kioskInfo.kioskName}</p>
                </div>
                <div className="detail-value">
                  <p>Vị trí</p>
                  <p className="bold">{kioskInfo?.roomType ? listRoom[kioskInfo?.roomType]?.text : "-"}</p>
                </div>
                <div className="detail-value">
                  <p>Trạng thái kết nối</p>
                    {
                      kioskInfo.status === CONNECTED && (
                        <div className="detail-status">
                          <IcConnect />
                          <span className="title-connect">Đã kết nối</span>
                      </div>
                      )
                    }
                    {
                      kioskInfo.status === DISCONNECTED && (
                        <div className="detail-status">
                          <IcInputWifiOff />
                          <span className="title-disconnect">Chưa kết nối</span>
                      </div>
                      )
                    }
                </div>
                <div className="detail-value">
                  <p>Số lần nhận diện</p>
                  <p className="bold">{kioskInfo.totalNumberOfIdentifiers || 0}</p>
                </div>
              </div>
              <Button
                iconBtn={<IcEdit />}
                text="Chỉnh sửa"
                className="edit-btn"
                onClick={() => setIsShowPopupEdit(true)}
            />
            {
              isShowPopupEdit && (
                <Portal>
                  <PopupEditKiosk onClickIconClose={() => setIsShowPopupEdit(false)} dataKioskEdit={kioskInfo}/>
                </Portal>
              )
            }
        </TablelInfoKiosk>
    )
}

export default DetailInfoKiosk
