import React from "react";

export default function IcOnlineDevice(props) {
  return (
    <svg
      {...props}
      width={9}
      height={9}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.57386 8.11364C4.05777 8.11364 3.57244 8.01657 3.1179 7.82244C2.66572 7.62831 2.26681 7.35961 1.92116 7.01634C1.57789 6.67069 1.308 6.27178 1.11151 5.8196C0.917377 5.36742 0.821496 4.8821 0.823864 4.36364C0.826231 3.84517 0.924479 3.35985 1.11861 2.90767C1.3151 2.45549 1.58499 2.05777 1.92827 1.71449C2.27154 1.36884 2.66927 1.09896 3.12145 0.904829C3.57363 0.710701 4.05777 0.613636 4.57386 0.613636C5.09233 0.613636 5.57765 0.710701 6.02983 0.904829C6.48438 1.09896 6.8821 1.36884 7.22301 1.71449C7.56629 2.05777 7.83499 2.45549 8.02912 2.90767C8.22325 3.35985 8.3215 3.84517 8.32386 4.36364C8.32623 4.8821 8.23035 5.36742 8.03622 5.8196C7.84209 6.27178 7.57339 6.67069 7.23011 7.01634C6.88684 7.35961 6.48793 7.62831 6.03338 7.82244C5.57884 8.01657 5.09233 8.11364 4.57386 8.11364Z"
        fill="url(#paint0_linear_8895_85795)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8895_85795"
          x1={5}
          y1={-2}
          x2={5}
          y2={10}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41C728" />
          <stop offset={1} stopColor="#1FC000" />
        </linearGradient>
      </defs>
    </svg>
  );
}
