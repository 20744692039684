import React from 'react'

// styles
import { WrapPage } from '../../../MainStyles/main'
import { WrapAdvertismentManage } from './index.styles'

// component
import Breadcrumb from '../../Breadcrumb'
import Hero from '../../Hero'

export default function AdvertismentManage() {
  return (
    <WrapPage>
      <Hero />
      <Breadcrumb />
      <WrapAdvertismentManage>
        Advertisment Manage Content
      </WrapAdvertismentManage>
    </WrapPage>
  )
}
