import React from "react";

export default function IcRefeshDoor(props) {
  return (
    <svg
      {...props}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.583328 11.6666V8.16663H4.08333"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4167 2.33337V5.83337H9.91666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.0475 5.24996C2.34334 4.41392 2.84616 3.66645 3.50902 3.07729C4.17188 2.48813 4.97318 2.07649 5.83816 1.88078C6.70314 1.68507 7.6036 1.71166 8.45552 1.95808C9.30744 2.2045 10.0831 2.66271 10.71 3.28996L13.4167 5.8333M0.583328 8.16663L3.29 10.71C3.91693 11.3372 4.69255 11.7954 5.54447 12.0418C6.39639 12.2883 7.29685 12.3149 8.16183 12.1191C9.02681 11.9234 9.82811 11.5118 10.491 10.9226C11.1538 10.3335 11.6566 9.586 11.9525 8.74996"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
