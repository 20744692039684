import styled from "styled-components";
import BgPopupControl from "../../../../assets/images/bg_popupautodoor.png"

const WrapPopupControl = styled.div`
    display: none;
    width: 295px;
    height: 137px;
    box-sizing: border-box;
    padding: 11px 0 22px 0;
    background-image: url(${BgPopupControl});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    position:absolute;
    right: 0;
    bottom: 0;
    cursor: move;
    .top{
        border-bottom: 1px dashed #BDBDBD;
        padding: 0 20px 14px 20px;
        
        .header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: #FFFFFF;
            }
            .action{
                display: flex;
                align-content: center;
                .icon{
                    margin: 0 5px;
                    cursor: pointer;
                }
            }
            
        }
        .device{
            color: #41C728;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            display: flex;
            align-items: center;
            margin-top: 6px;
            .item{
                display: flex;
                align-items: center;
                span{
                    margin-left: 5px;
                    &.offline{
                        color: #828282;
                    }
                }
                &.item1{
                    margin-right: 72px;
                }
            }
        }
       
    }
    .btn-container{
        display: flex;
        align-items: center;
        padding: 20px 20px;
        justify-content: space-between;
        .btn-auto{
            width: 116px;
            border: none;
        }
    }
`

export {
    WrapPopupControl
}