import React from 'react'

// styles

import { WrapPopupWarning } from './index.styles'

//images
import IcCheckConfirmWhite from '../../../assets/images/IcCheckConfirmWhite'
import IcClose from '../../../assets/images/IcClose'

//component
import Button from '../Button'
import { OverlayFullScreen } from '../../MainStyles/main'


const PopupWarning = ({ title, subTitle, handleClose }) => {
   
    return (
        <OverlayFullScreen onClick={e => e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupWarning>
                    <IcClose className="icon-close" onClick={handleClose} />
                    <div className="title">{title}</div>
                    <div className="sub-title">{subTitle}</div>
                    <div className='btn-container'>
                        <Button
                            text="Đã hiểu"
                            iconBtn={<IcCheckConfirmWhite />}
                            className="btn-warning"
                            onClick={handleClose}
                        />
                    </div>
                </WrapPopupWarning>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupWarning
