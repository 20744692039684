import styled from 'styled-components'

const WrapItem = styled.div`
    display: grid;
    grid-template-columns: 180px 150px 295px 228px 220px auto;
    padding: 0 20px;
    box-sizing: border-box;
    height: 60px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #313131;
    align-items: center;
    &:hover {
        border: 1px solid #33ADFF;
    }
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .wrap-avatar{
        height: 36px;
        width: 36px;
        border-radius: 50%;
        margin-left: 6px;
        .avatar{
            width: 36px;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            
            &:hover {
                cursor: pointer;
            }
        }
    }
    .status{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        color: #A31E23;
        &.active{
            color: #15BA39;
        }
    }
`
export {
    WrapItem
}