import React from 'react'

//images
import IcKioskBlue from '../../../../../../../../assets/images/IcKioskBlue'
import IcKioskGray from '../../../../../../../../assets/images/IcKioskGray'

//control
import Input from '../../../../../../../Control/Input'

const iconStore = {
    icon: IcKioskGray,
    iconActive: IcKioskBlue,
    iconError: IcKioskBlue,
}

export default function InputIndentifyCodeKiosk({value, handleGetValueInput}) {
  const _handleChangeValue = (event) => {
    const { value } = event.target;
    handleGetValueInput && handleGetValueInput(value)
  }

  return (
    <Input
        heightInput="63px"
        title="Mã định danh"
        iconInput={iconStore}
        inputType = "TEXT"
        value = {value}
        isRequired = {false}
        onChange = {_handleChangeValue}
        placeholder="Mã định danh"
    />
  )
}
