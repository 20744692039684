
export const GET_LIST_LOCKER = 'GET_LIST_LOCKER'
export const GET_LIST_LOCKER_ERR = 'GET_LIST_LOCKER_ERR'

export const RESET_LIST_LOCKER = 'RESET_LIST_LOCKER'
export const RESET_LIST_LOCKER_ALL = 'RESET_LIST_LOCKER_ALL'

export const ADD_LOCKER_SUCCESS = "ADD_LOCKER_SUCCESS"
export const ADD_LOCKER_ERR = "ADD_LOCKER_ERR"

export const DEL_LOCKER_SUCCESS = 'DEL_LOCKER_SUCCESS'
export const DEL_LOCKER_ERR = 'DEL_LOCKER_ERR'

export const UPDATE_LOCKER_SUCCESS = 'UPDATE_LOCKER_SUCCESS'
export const UPDATE_LOCKER_ERR = 'UPDATE_LOCKER_ERR'

export const RESET_VALUE_SEARCH = 'RESET_VALUE_SEARCH'


export const GET_LOCKER_LIST_BY_CLUSTER = 'GET_LOCKER_LIST_BY_CLUSTER'
export const GET_LOCKER_LIST_BY_CLUSTER_ERROR = 'GET_LOCKER_LIST_BY_CLUSTER_ERROR'

export const GET_INFO_LOCKER = 'GET_INFO_LOCKER'
export const GET_INFO_LOCKER_ERROR = 'GET_INFO_LOCKER_ERROR'

export const GET_LOCKER_HISTORY = 'GET_LOCKER_HISTORY'
export const GET_LOCKER_HISTORY_ERR = 'GET_LOCKER_HISTORY_ERR'
export const RESET_LOCKER_HISTORY = 'RESET_LOCKER_HISTORY'
