import React, { useState } from "react";
import { useDispatch } from "react-redux";

//images
import IcBtnUpdateKioskRed from "../../../../../../../assets/images/IcBtnUpdateKioskRed";
import IcInputWifiBlue from "../../../../../../../assets/images/IcInputWifiBlue";
import IcInputWifiOff from "../../../../../../../assets/images/IcInputWifiOff";
import IcLink from "../../../../../../../assets/images/IcLink";

//data
import {
  CONNECTED,
  DISCONNECTED,
} from "../../../../../../../data/listKioskStatus";

//action
import { resetCodeIdentify } from "../../../../../../../redux/kiosk/action";


//component
import Button from "../../../../../../Control/Button";
import Portal from "../../../../../../Control/Portal";
import { INDENTIFY } from "../../OptionKiosk";
import PopupIndentifyKiosk from "../../OptionKiosk/PopupIndentifyKiosk";

export default function KioskStatus({ item, onClick }) {

  const dispatch = useDispatch()
  const [keyShowForm, setKeyShowForm] = useState("");
  const [isClickBtn, setIsClickBtn] = useState(false)
  const handleClickBtn = () => {
    if (!isClickBtn) return
    if (isClickBtn){
      setKeyShowForm(INDENTIFY);
    }
    
  };
  const onClose = () => {
      setKeyShowForm('')
      dispatch(resetCodeIdentify())
  }
 
  return (
    <>
      <div className="kiosk-bottom" style={{cursor: "pointer"}} onClick={()=>{
        if (isClickBtn)return
        onClick()
      }} >
        <span className="kiosk-id">{item?.kioskId}</span>
        {item.status === CONNECTED && (
          <div className="kiosk-status">
            <IcInputWifiBlue />
            <span className="title-status">Đã kết nối</span>
            <IcBtnUpdateKioskRed  onMouseEnter={()=> setIsClickBtn(true)} onMouseLeave={()=> setIsClickBtn(false)} onClick={handleClickBtn} style = {{cursor: 'pointer'}} />
          </div>
        )}
        {item.status === DISCONNECTED && (
          <div className="kiosk-status">
            <IcInputWifiOff />
            <Button
              onMouseEnter={()=> setIsClickBtn(true)}
              onMouseLeave={()=> setIsClickBtn(false)}
              text="Kết nối thiết bị"
              iconBtn={<IcLink />}
              className="btn-connect"
              onClick={handleClickBtn}
            />
          </div>
        )}
      </div>
      { keyShowForm === INDENTIFY && (
          <Portal>
              <PopupIndentifyKiosk
                onClickIconClose={onClose}
                dataKiosk={item}
              />
          </Portal>
      )}
    </>
  );
}
