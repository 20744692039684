import React from "react";

export default function IcStatusActive(props) {
  return (
    <svg
      {...props}
      width={30}
      height={18}
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.32765 0.0535075C10.3512 -0.00455676 11.3735 8.83736e-05 12.3971 8.83736e-05C12.4041 8.83736e-05 17.5835 8.83736e-05 17.5835 8.83736e-05C18.6271 8.83736e-05 19.6494 -0.00455676 20.6724 0.0535075C21.6024 0.105765 22.5082 0.217249 23.41 0.466344C25.3082 0.990083 26.9659 2.08401 28.1641 3.63491C29.3553 5.17593 30 7.06244 30 8.99946C30 10.9388 29.3553 12.8242 28.1641 14.3652C26.9659 15.9155 25.3082 17.01 23.41 17.5337C22.5082 17.7828 21.6024 17.8937 20.6724 17.9466C19.6494 18.0046 18.6271 17.9994 17.6035 17.9994C17.5965 17.9994 12.4159 18 12.4159 18C11.3735 17.9994 10.3512 18.0046 9.32765 17.9466C8.39824 17.8937 7.49235 17.7828 6.59059 17.5337C4.69235 17.01 3.03471 15.9155 1.83647 14.3652C0.645294 12.8242 0 10.9388 0 9.00004C0 7.06244 0.645294 5.17593 1.83647 3.63491C3.03471 2.08401 4.69235 0.990083 6.59059 0.466344C7.49235 0.217249 8.39824 0.105765 9.32765 0.0535075Z"
        fill="#3D8B4C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 17C25.4183 17 29 13.4183 29 9C29 4.58172 25.4183 1 21 1C16.5817 1 13 4.58172 13 9C13 13.4183 16.5817 17 21 17Z"
        fill="white"
      />
    </svg>
  );
}
