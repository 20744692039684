import styled from 'styled-components'

import { WrapCreateAndEditPopup } from '../../../../../../MainStyles/main'

const WrapChangePassword = styled(WrapCreateAndEditPopup)`
    width: 400px;
    box-sizing: border-box;
    .title{
        margin-bottom: 12px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
    }
    .description{
        margin-bottom: 24px;
    }
    .label-input{
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #191919;
        margin-bottom: 8px;
        .notice{
            color: #A31E23;
            margin-left: 5px;
        }
    }
    .btn-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 24px;
        .btn-edit{
            width: 100%;
            // margin-top:36px;
        }
    }
`;

export {
    WrapChangePassword
}