import styled from 'styled-components'
import { WrapPopupDel } from '../../../../../../MainStyles/main';


const WrapPopupDeleteKiosk = styled(WrapPopupDel)`
    width: 400px;
    box-sizing: border-box;
    .sub-title {
        margin-top: 10px;
        font-size: 13.5px;
        line-height: 18px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
    }
    .notice {
        margin-top: 20px;
    }
    .btn-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        .btn-del, .btn-cancel-del {
            width: 174px;
            height: 36px;
        }
        
    }
`;

export {
    WrapPopupDeleteKiosk
}