import { getStorage, ref as storageRef, getDownloadURL, uploadBytes } from "firebase/storage";


export const getFileStorage = async (pathFile, callbackSuccess, callbackError) => {
    // console.log(pathFile)
    const storage = getStorage();
    // console.log(storage)
    getDownloadURL(storageRef(storage, pathFile))
    .then((url) => {
        // console.log(url)
        // `url` is the download URL for 'images/stars.jpg'
        callbackSuccess && callbackSuccess(url)
        // This can be downloaded directly:
        // const xhr = new XMLHttpRequest();
        // xhr.responseType = 'blob';
        // xhr.onload = (event) => {
        //   const blob = xhr.response;
        // };
        // xhr.open('GET', url);
        // xhr.send();

        // Or inserted into an <img> element
        // const img = document.getElementById('myimg');
        // img.setAttribute('src', url);
    })
    .catch((error) => {
        // Handle any errors
        console.log(error)
        callbackError && callbackError(error)
    });
}

export const uploadFileStorage = (file, pathFile, callbackSuccess, callbackError) => {
    const storage = getStorage();
    uploadBytes(storageRef(storage, pathFile), file).then((snapshot) => {
        // console.log(snapshot);
        callbackSuccess && callbackSuccess(snapshot)
    })
    .catch((error) => {
        // Handle any errors
        console.log(error)
        callbackError && callbackError(error)
    });
}