import React from 'react'
import { useMatch } from "react-router-dom";

// styles
import { WrapPage } from '../../../MainStyles/main'
import { WrapKioskManage } from './index.styles'

// component
import Breadcrumb from '../../Breadcrumb'
import Hero from '../../Hero'
import ListKiosk from './Listing';
import DetailKiosk from './Detail'

// routes
import {
  main,
  kioskManage,
  kioskDetails,
} from "../../../../constant/routes";

export default function KioskManage() {
  return (
    <WrapPage>
      <Hero />
      <Breadcrumb />
      <WrapKioskManage>
        { useMatch(`/${main}/${kioskManage}`) && <ListKiosk /> }
        { useMatch(`/${main}/${kioskManage}/${(kioskDetails())}`) && <DetailKiosk /> }
      </WrapKioskManage>
    </WrapPage>
  )
}
