import React, { useState, useEffect } from 'react'

// styles
import { Bound } from './index.styles'

//host
// import { apiDownLoadAvatar } from '../../../../../../constant/host'

//help
import { convertDateTime } from '../../../../../helper/convertDateTime'

//image
import IcFacePrintDefault from '../../../../../assets/images/IcFacePrintDefault'

// component
// import ShowZoomFace from '../../Control/ShowZoomFace'
// import Portal from '../../../../../Control/Portal'
import { apiGetAvatar, apiGetFace } from '../../../../../constant/host'
import FaceZoomAvt from '../../../../Control/FaceZoomAvt'



export default function ItemNewFaceBiometric(props) {

    const { className, item, index , refList} = props

    const [isErrImg, setIsErrImg] = useState(false)

    useEffect(() => {
        setIsErrImg(false)
    }, [item?.ID])
    
    return (
        <Bound className={className}>
            <div>{index < 9 ? `${index + 1}` : index + 1}</div>
            <div title={item.memberSFID} className="member-value">{item?.memberSFID || "-"}</div>
            <div className="wrap-avatar">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="avatar" />
                        :
                        <FaceZoomAvt
                            url={apiGetAvatar(item?.ID)}
                            className="avatar"
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                            refList={refList}
                        />
                }
            </div>
            <div title={item?.fullname}>{item?.fullname || "-"}</div>
            <div>{item?.createAt ? convertDateTime(item?.createAt, "DD/MM/YYYY HH:mm") : ""}</div>
        </Bound>
    )
}
