import React from "react";

export default function IcGetCodeIndentify(props) {
  return (
    <svg
      {...props}
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.83337 10.5L13.3334 7L9.83337 3.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16663 3.5L1.66663 7L5.16663 10.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
