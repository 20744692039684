import React, { useMemo } from 'react'

//style
import { Bound } from './index.styles'

//component

const VIP = "VIP"
const NORMAL = "NORMAL"

const CheckBoxControl = ({
    children,
    isChecked,
    iconStore,
    isCheckedSome,
    handleCheck,
    extendStyles,
    isDisabled,
    _onClickDisableItem,
    ...props
}) => {
    const _handleChecked = () => {
        if (isDisabled) {
            _onClickDisableItem && _onClickDisableItem()
            return;
        }

        if (isChecked || isCheckedSome) {
            const newCheck = false
            handleCheck && handleCheck(newCheck)
            return; 
        }
        const newCheck = true
        handleCheck && handleCheck(newCheck)
    };

    const renderCheckBox = useMemo(() => {
        if (isChecked) {
            return <iconStore.iconActive className="icon" />
        }
        return <iconStore.icon className="icon" />
    }, [isChecked, isCheckedSome, isDisabled])

    return (
        <Bound onClick={_handleChecked} extendStyles={extendStyles} {...props}>
            {renderCheckBox}
            <div className="text">{children}</div>
        </Bound>
    )
}

export default CheckBoxControl
