import React from 'react'
import { useDispatch } from 'react-redux';
//styles
import { WrapPopupLogout } from './index.styles';
import { OverlayFullScreen } from '../../../MainStyles/main';

//images
import IcClose from '../../../../assets/images/IcClose';
import IcLogout from '../../../../assets/images/IcLogout';

//action
import { logoutAction } from '../../../../redux/login/action';
import Button from '../../../Control/Button';
import {resetSearchMemberCode } from '../../../../redux/member/action';



const PopupLogout = ({ handleClose }) => {

    const dispatch = useDispatch()
    
    const _handleLogout = () =>{
       dispatch(logoutAction())
       dispatch(resetSearchMemberCode())
    }

    return (
            <OverlayFullScreen onClick={(e)=> e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupLogout>
                <IcClose className="icon-close" onClick={handleClose} />
                    <div className="title">Đăng xuất tài khoản</div>
                    <div className="sub-title">
                        Bạn có chắc chắn muốn đăng xuất khỏi hệ thống?
                    </div>
                    <div className='btn-container'>
                        <Button
                            text="Hủy bỏ"
                            className="btn-cancel-del"
                            onClick={handleClose}
                        />
                        <Button
                            text="Đăng xuất"
                            iconBtn={<IcLogout />}
                            className="btn-del"
                            onClick={_handleLogout}
                        />
                    </div>
                </WrapPopupLogout>
            </div>
        </OverlayFullScreen>        
    )
}

export default PopupLogout
