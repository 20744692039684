import React, { useEffect, useState } from 'react'
import IcNameKioskBlue from '../../../../../../../../../assets/images/IcNameKioskBlue'
import IcNameKioskGray from '../../../../../../../../../assets/images/IcNameKioskGray'
import InputDropdown from '../../../../../../../../Control/InputDropdown'
//component


const iconStore = {
    icon: IcNameKioskGray,
    iconActive: IcNameKioskBlue,
    iconError: IcNameKioskBlue,
}

const InputDropdownNameCabinetRange = ({ handleGetValueDropdown, objChoice }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }
    
    return (
        <InputDropdown
            title="Thuộc dãy tủ"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder={"Dãy tủ"}
            required={false}
            isDisable={true}
            objChoice={objChoice}
        />
    )
}

export default InputDropdownNameCabinetRange
