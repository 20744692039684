import React from "react";

export default function IcLink(props) {
  return (
    <svg
      {...props}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74992 4.08333H10.4999C10.8829 4.08333 11.2622 4.15877 11.6161 4.30535C11.9699 4.45193 12.2915 4.66677 12.5623 4.9376C12.8332 5.20844 13.048 5.52997 13.1946 5.88384C13.3411 6.23771 13.4166 6.61698 13.4166 7C13.4166 7.38302 13.3411 7.76229 13.1946 8.11616C13.048 8.47002 12.8332 8.79156 12.5623 9.06239C12.2915 9.33323 11.9699 9.54807 11.6161 9.69465C11.2622 9.84122 10.8829 9.91667 10.4999 9.91667H8.74992M5.24992 9.91667H3.49992C3.1169 9.91667 2.73762 9.84122 2.38376 9.69465C2.02989 9.54807 1.70836 9.33323 1.43752 9.06239C0.890543 8.51541 0.583252 7.77355 0.583252 7C0.583252 6.22645 0.890543 5.48459 1.43752 4.9376C1.9845 4.39062 2.72637 4.08333 3.49992 4.08333H5.24992"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66675 7H9.33342"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
