import React from 'react'
import { useMatch } from "react-router-dom";

// styles
import { WrapPage } from '../../../MainStyles/main'
import { WrapCabinetRangeManage } from './index.styles'

// component
import Breadcrumb from '../../Breadcrumb'
import Hero from '../../Hero'
import ListCabinetRange from './Listing';
import DetailCabinet from './Detail'

// routes
import {
  main,
  cabinetRangeManage,
  cabinetRangeDetails,
} from "../../../../constant/routes";

export default function CabinetRangeManage() {
  return (
    <WrapPage>
      <Hero />
      <Breadcrumb />
      <WrapCabinetRangeManage>
        { useMatch(`/${main}/${cabinetRangeManage}`) && <ListCabinetRange /> }
        { useMatch(`/${main}/${cabinetRangeManage}/${(cabinetRangeDetails())}`) && <DetailCabinet /> }
      </WrapCabinetRangeManage>
    </WrapPage>
  )
}
