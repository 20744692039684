import styled from 'styled-components'

import { TableContent, TableHeader, WrapPageItem } from '../../../MainStyles/main'

const WrapNewFaceBiometric = styled(WrapPageItem)`
   padding: 0 30px;
`;

const WrapPageHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 22px 0 12px 0;
    .title-header{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        color: #313131;
        padding-left: 8px;
    }
`

const TableHeaderEnroll = styled(TableHeader)`
    grid-template-columns:
        80px
        225px
        140px
        500px
        110px;
    padding: 11px 17px 10px 17px;
`;


const TableContentEnroll = styled(TableContent)`

`;

const WrapFormSubmit = styled.div`
    width: 100%;
    min-height: 206px;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    padding: 11px 0 24px 0;
    box-sizing: border-box;
    .wrap-content{
        display: flex;
        gap: 20px;

        .form-id{
            display: flex;
            align-items: center;
            .form-title{
            color: #313131;
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            }
            .notify{
                color: #A31E23;
                margin-left: 2px;
            }
        }
        
        .form-content{
            background-color: #fff;
            padding: 20px;
            height: calc(210px - 40px);
            .input-enroll{
                margin: 17px 0 56px 0;
                width: 209px;
                input{
                    font-weight: 400;
                    /* color: #191919; */
                }
            }
            .content-btn{
                display: flex;
                .btn-search{
                    width: 100%;
                    height: 34px;
                }
                .btn-cancel{
                    margin-left: 12px;
                    width: 98px;
                    height: 34px;
                    color: #A31E23;
                    border: 1px solid;
                    background-color: transparent;
                }

            
            }
        }
        .form-info{
            display: flex;
            background-color: #fff;
            width: 100%;
            padding: 14px 20px 18px 14px;
            position: relative;
            .face-content{
                margin-right: 90px;
                .form-title{
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .facePrint-image{
                    margin-top: 18px;
                    height: 130px;
                    width: 130px;
                    position: relative;
                    border: 2px solid ${props => props.isErrorFace ? "#D62B00" : "#33ADFF"};
                    box-sizing: border-box;
                    border-radius: 4px;
                    background: #F7F7F7;
                    display: inline-block;
                    &.face-error {
                        border: 2px solid #D62B00;
                    }
                    .remove-face {
                        position: absolute;
                        cursor: pointer;
                        bottom: 7px;
                        right: 7px;
                        padding: 5px;
                        border-radius: 4px;
                    }
                    .remove-face-hide{
                        display: none;
                    }
                    .loading-image{
                        animation: spinner 4s linear infinite;
                        width: 82px;
                        height: 90px;
                        margin-top: 15px;
                    }
                    @keyframes spinner {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                    &.default{
                        border: 1px dashed #B7B7B7;
                        box-sizing: border-box;
                        border-radius: 4px;
                        background: #F7F7F7;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .icon_faceUser{
                            padding: 16px;
                            border-radius: 50%;
                            background-color: rgba(183, 183, 183, 0.1);
                            margin-top: 18px;
                        }
                        .update{
                            font-family: "Open Sans";
                            font-size: 11px;
                            line-height: 15px;
                            color: #A31E23;
                            margin-top: 19px;
                            cursor: pointer;
                            text-decoration: underline;
                        }
                        .update-hide{
                            display: none;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        background: #F7F7F7;
                    }
                }
                .error-check-face {
                    display: flex;
                    align-items: center;
                    margin-top: 4px;
                    position: absolute;
                    bottom: 3px;
                    .error-face {
                        font-family: "Open Sans";
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 13px;
                        letter-spacing: -0.02em;
                        color: #F45C5C;
                        width: 110px;
                    }
                    .error-popup{
                        width: 77px;
                        font-weight: 700;
                        font-size: 11px;
                        line-height: 13px;
                        letter-spacing: -0.01em;
                        color: #F45C5C;
                        cursor: pointer;
                        border-bottom: 1px solid #F45C5C;
                    }
                }

            }
            .info-content{
                margin-right: 136px;
                .form-title{
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: -0.01em;
                    color: #313131;
                }
                .info{
                    margin-top: 18px;
                    display: flex;
                    align-items: center;
                    &:not(:first-of-type) {
                        margin-top: 10px;
                    }
                        .title{
                            font-family: "Open Sans";
                            font-weight: 400;
                            font-size: 11px;
                            line-height: 15px;
                            letter-spacing: -0.02em;
                            color: #191919;
                        }
                        .notify{
                            color: #A31E23;
                            margin-left: 2px;
                        }
                }
                .input-info{
                    margin-top: 8px;
                    width: 266px;
                }
                .info-check-member{
                    width: 100%;
                }
            }
            .info-btn{
                display: flex;
                align-items: flex-end;
                gap: 10px;
                .wrap-btn-update {
                    position: relative;

                    .wrap-confirm {
                        position: absolute;
                        left: calc(-100% - 20px);
                        top: -76px;
                        padding: 20px;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        width: 229px;
                        box-sizing: border-box;
                        background: #FFFFFF;
                        z-index: 1;

                        .title {
                            font-family: "Open Sans";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 20px;
                            letter-spacing: -0.01em;
                            color: #313131;
                            margin-bottom: 10px;
                        }

                        .desc {
                            font-family: "Open Sans";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13.5px;
                            line-height: 18px;
                            letter-spacing: -0.01em;
                            color: #313131;
                        }

                        .btn-accept {
                            margin-top: 30px;
                            background: linear-gradient(180deg, #41C728 0%, #1FC000 100%);
                            width: 100%;
                        }

                        .btn-reject {
                            margin-top: 10px;
                            background: #F7F7F7;
                            width: 100%;
                            color: #777777;
                            border: none;
                        }
                    }
                }
                .btn-cancel{
                    width: 68px;
                    border: 1px solid #A31E23;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    color: #A31E23;
                }
                .btn-search{
                    width: 116px;
                    font-weight: 600;
                    font-size: 13.5px;
                    line-height: 18px;
                    letter-spacing: -0.01em;
                    color: #FFFFFF;
                }
            }
            .update-pin{
                position: absolute;
                top: 14px;
                right: 20px;
                .btn-update-pin{
                    border: 1px solid #33ADFF;
                    border-radius: 4px;
                    color: #33ADFF;
                    background-color: white;
                    &.disable{
                        color: #BDBDBD;
                        border: 1px solid #BDBDBD;
                        background-color: #F7F7F7;  
                    }
                }
            }
        }
    }
`;

export {
    WrapNewFaceBiometric,
    WrapPageHeader,
    WrapFormSubmit,
    TableHeaderEnroll,
    TableContentEnroll,
}