import React from "react";

export default function IcPinOn(props) {
  return (
    <svg
      {...props}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.59947 0.631715C8.65697 0.631612 8.71391 0.642842 8.76706 0.664762C8.82021 0.686682 8.86852 0.718863 8.90922 0.759465L13.2405 5.09071C13.3225 5.17276 13.3686 5.28402 13.3686 5.40003C13.3686 5.51604 13.3225 5.6273 13.2405 5.70934C12.8205 6.12934 12.3025 6.22384 11.9253 6.22384C11.7705 6.22384 11.6322 6.20809 11.5228 6.18971L8.7806 8.93197C8.85279 9.22282 8.89963 9.51939 8.9206 9.81834C8.96085 10.4326 8.8926 11.2945 8.2906 11.8965C8.20856 11.9785 8.09729 12.0246 7.98129 12.0246C7.86528 12.0246 7.75402 11.9785 7.67197 11.8965L5.1966 9.42197L2.41235 12.2062C2.24172 12.3768 1.34572 12.9955 1.1751 12.8248C1.00447 12.6542 1.6231 11.7573 1.79372 11.5876L4.57797 8.80334L2.10347 6.32796C2.02145 6.24592 1.97538 6.13466 1.97538 6.01865C1.97538 5.90264 2.02145 5.79138 2.10347 5.70934C2.70547 5.10734 3.56735 5.03821 4.1816 5.07934C4.48055 5.10029 4.77712 5.14713 5.06797 5.21934L7.81022 2.47796C7.78733 2.34472 7.77563 2.20979 7.77522 2.07459C7.77522 1.69834 7.86972 1.18034 8.2906 0.759465C8.37259 0.677679 8.48366 0.631739 8.59947 0.631715Z"
        fill="white"
      />
    </svg>
  );
}
