import React, { useEffect, useRef, useReducer, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

//style
import { OverlayFullScreen } from '../../../../MainStyles/main'
import { WrapPopupFilter } from './index.style'

//image
import IcClose from '../../../../../assets/images/IcClose'
import IcFilterWhite from '../../../../../assets/images/IcFilterWhite'

//component
import Button from '../../../../Control/Button'
import DatePickerInput from '../../../../Control/DatePickerInput'
import { getUpdateMemberList, resetValueSearchMember } from '../../../../../redux/member/action'


const PopupFilterFaceBiometric = ({ onClose, valueSearch }) => {

    const dispatch = useDispatch()
    const filterRef = useRef(null)

    const { branchCode, totalUpdate, filterInfo  } = useSelector((state) => ({
        branchCode: state.login.dataLogin.branchCode,
        totalUpdate: state.memberReducer.totalUpdate,
        filterInfo: state.memberReducer.filterInfo,
    }))
    const [filter, setFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            startTime: filterInfo.startTime,
            endTime: filterInfo.endTime,
            limit: 15,
            cursor: "",
            quickSearchData: ""
        }
    )
    const _onClosePopup = () => {
        if (filterRef) {
            filterRef.current.classList.remove('show-right-popup')
            filterRef.current.classList.add('hide-right-popup')
        }
        setTimeout(() => {
            onClose()
        }, 300);
    }

    const _handleGetFromDate = (date) => {
        setFilter({ startTime: date ? moment(date).utc().startOf('day').toDate() : undefined })
    }

    const _handleGetToDate = (date) => {
        setFilter({ endTime: date ? moment(date).endOf('day').toDate() : undefined })
    }

    const _handleReset = () => {
        setFilter({
            startTime: moment().utc().startOf("month").toDate(),
            endTime: moment(new Date()).endOf("day").toDate(),
        })
        dispatch(resetValueSearchMember())
        dispatch(getUpdateMemberList({
            branchCode: branchCode,
            filterInfo: {
                limit: 15,
                cursor: "",
                startTime: moment().utc().startOf("month").toDate(),
                endTime: moment(new Date()).endOf("day").toDate(),
                quickSearchData: ""
            }
        }, false, callbackSuccess, callbackError
        ))
    }

    const callbackSuccess = () => {}
    const callbackError = () => {}

    const _handleFilter = () => {
        dispatch(getUpdateMemberList({
            branchCode: branchCode,
            filterInfo: {
                cursor: "",
                startTime: filter.startTime,
                endTime: filter.endTime,
                limit: totalUpdate ? totalUpdate : 15,
                quickSearchData: valueSearch
            }
        }, false, callbackSuccess, callbackError
        ))
        _onClosePopup()
    }

    return (
        <OverlayFullScreen notUseOverlay={true}>
            <WrapPopupFilter className='show-right-popup' ref={filterRef}>
                <div className="wrap-header">
                    <IcClose
                        className='btn-close'
                        onClick={_onClosePopup}
                    />
                    <div className="wrap-btn">
                        <Button
                            className="btn-reset"
                            onClick={_handleReset}
                            text="Reset"
                        />
                        <Button
                            className="btn-filter"
                            iconBtn={<IcFilterWhite />}
                            onClick={_handleFilter}
                            text="Lọc dữ liệu"
                        />
                    </div>
                </div>
                <div className="select-filter">
                    <p className="title">Từ ngày</p>
                    <DatePickerInput
                        className="input-dropdown"
                        selected={filter.startTime}
                        onChange={_handleGetFromDate}
                        maxDate={filter.endTime}
                        placeholder="Chọn ngày"
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                    />
                </div>
                <div className="select-filter">
                    <p className="title">Đến ngày</p>
                    <DatePickerInput
                        className="input-dropdown"
                        selected={filter.endTime}
                        onChange={_handleGetToDate}
                        maxDate={new Date()}
                        minDate={filter.startTime}
                        placeholder="Chọn ngày"
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect={false}
                    />
                </div>

            </WrapPopupFilter>
        </OverlayFullScreen>

    )
}

export default PopupFilterFaceBiometric
