import React from 'react'

export default function IcSingleChoiceSelected(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="7.5" stroke="#33ADFF"/>
      <circle cx="9" cy="9" r="5" fill="#33ADFF"/>
    </svg>


  )
}
