import React, { useState, useRef, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

// styles
import { WrapPage } from "../../../MainStyles/main";
import {
  WrapFaceBiometric,
  TableHeaderFaceBiometric,
  TableContentFaceBiometric,
} from "./index.styles";

// component
import Breadcrumb from "../../Breadcrumb";
import Hero from "../../Hero";
import EmptyDataControl from "../../../Control/EmptyDataControl";
import ItemFaceBiometric from "./ItemFaceBiometric";

//mockData
import IcNoData from "../../../../assets/images/IcNoData";

// hook
import useScrollEnd from "../../../../hook/useScrollEnd";

// redux
import {
  clearUpdateMemberList,
  getUpdateMemberList,
} from "../../../../redux/member/action";
import IcNoResult from "../../../../assets/images/IcNoResult";

const dataHeader = [
  "STT",
  "Salesforce ID",
  "Hình STH",
  "Tên hội viên",
  "Câu lạc bộ",
  "Thời gian cập nhật",
  "Trạng thái",
];

export default function FaceBiometric() {
  const listRef = useRef(null);
  const dispatch = useDispatch();

  const { branchCode, list, filterInfo, isSearching } = useSelector(
    (state) => ({
      branchCode: state.login.dataLogin.branchCode,
      list: state.memberReducer.list,
      filterInfo: state.memberReducer.filterInfo,
      isSearching: state.memberReducer.isSearching,
    })
  );
  const callbackSuccess = () => {};
  const callbackError = () => {};

  useEffect(() => {
    dispatch(
      getUpdateMemberList(
        {
          branchCode: branchCode,
          filterInfo: {
            ...filterInfo,
          },
        },
        false,
        callbackSuccess,
        callbackError
      )
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearUpdateMemberList());
    };
  }, []);

  useScrollEnd(
    listRef,
    (callback) => {
      dispatch(
        getUpdateMemberList(
          {
            branchCode: branchCode,
            filterInfo: {
              cursor: "",
              startTime: filterInfo.startTime,
              endTime: filterInfo.endTime,
              cursor: list[list.length - 1].ID,
              limit: 15,
              quickSearchData: filterInfo.quickSearchData,
            },
          },
          true,
          callback
        )
      );
    },
    [list],
    [list.length]
  );

  const tableContent = useMemo(() =>
    (list.length === 0 ? 
      <div className="wrap-empty">
        {!!isSearching ? 
          <EmptyDataControl
            IconNonData={<IcNoResult />}
            labelDes="Không có kết quả"
          />
          :
          <EmptyDataControl
            IconNonData={<IcNoData />}
            labelDes="Hiện tại chưa có dữ liệu STH"
          />
        }
      </div>
      : 
      <div className="wrap-list-trans" ref={listRef}>
        <div className="list-trans">
          {list.map((item, i) => (
            <ItemFaceBiometric
              className="history-item"
              key={i}
              index={i}
              item={item}
              refList={listRef}
            />
          ))}
        </div>
      </div>)
    ,
    [JSON.stringify(list), isSearching]
  );

  return (
    <WrapPage>
      <Hero />
      <Breadcrumb />
      <WrapFaceBiometric>
        <TableHeaderFaceBiometric>
          {list.length !== 0 ? (
            dataHeader.map((item, i) => (
              <div className="table-header" key={i}>
                {item}
              </div>
            ))
          ) : (
            <></>
          )}
        </TableHeaderFaceBiometric>
        <TableContentFaceBiometric>{tableContent}</TableContentFaceBiometric>
      </WrapFaceBiometric>
    </WrapPage>
  );
}
