import React, { useRef, useState, useEffect } from 'react'

// component
import IcSearchRed from '../../../assets/images/IcSearchRed';

// styles
import { WrapInputSearch } from './index.styles'
// import { BodyRegular } from '../../../ControlStyles/Text'

// hook
import useDebounce from '../../../hook/useDebounce';

const InputSearch = ({
    value,
    onChange,
    isOnChange,
    setValue,
    ...props
}) => {
    const inputRef = useRef(null)
    const [valueInput, setValueInput] = useState("");
    const [valueDebounce, clearDebounce] = useDebounce(valueInput, 500)

    useEffect(() => {
        inputRef.current.value = value
        setValueInput(value)
    }, [value])
    
    useEffect(() => {
        const inputEle = inputRef.current
        inputEle.setAttribute('size', inputEle.getAttribute('placeholder').length);
        if(isOnChange){
            onChange(valueDebounce)
            return
        }
       
    }, [valueDebounce]);

    const _handleChange = (e) => {
        setValueInput(e.target.value)
        setValue(e.target.value)
    }

    const _handleSearch = () => {
        clearDebounce()
        onChange(valueInput)
    }

    return (
        <WrapInputSearch>
            <input
                className='input-search'
                onChange={_handleChange}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        _handleSearch()
                    }
                }}
                {...props}
                ref={inputRef}
            />
            <IcSearchRed
                className="icon-search"
                onClick={_handleSearch}
            />
        </WrapInputSearch>
    )
}

export default InputSearch
