import React from 'react'

const IcArrowDown = (props) => {
    return (
        <svg {...props} width={7} height={4} viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.5 4L1.27146e-07 1.35122e-07L7 -4.76837e-07L3.5 4Z" fill="#313131" />
        </svg>
    )
}

export default IcArrowDown