import React from 'react'

const IcPictureUser = ({...props}) => {
    return (
        <svg {...props} width={38} height={38} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.6667 33.25V30.0833C31.6667 28.4036 30.9994 26.7927 29.8117 25.605C28.6239 24.4173 27.013 23.75 25.3333 23.75H12.6667C10.987 23.75 9.37606 24.4173 8.18833 25.605C7.0006 26.7927 6.33334 28.4036 6.33334 30.0833V33.25" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19 17.4167C22.4978 17.4167 25.3333 14.5811 25.3333 11.0833C25.3333 7.58553 22.4978 4.75 19 4.75C15.5022 4.75 12.6667 7.58553 12.6667 11.0833C12.6667 14.5811 15.5022 17.4167 19 17.4167Z" stroke="#B7B7B7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcPictureUser
