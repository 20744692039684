import React, { useEffect, useState } from 'react'
import IcRoomBlue from '../../../../../../../../assets/images/IcRoomBlue'
import IcRoomGray from '../../../../../../../../assets/images/IcRoomGray'

import InputDropdown from '../../../../../../../Control/InputDropdown'

const iconStore = {
    icon: IcRoomGray,
    iconActive: IcRoomBlue,
    iconError: IcRoomBlue,
}

const InputDropdownRoomKiosk = ({ handleGetValueDropdown, listDropDown, objChoice, isDisable, required, handleValidationInput, error }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }
    
    return (
        <InputDropdown
            title="Phòng"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Phòng"
            required={required}
            isDisable={isDisable}
            dataFilterOption={listDropDown}
            objChoice={objChoice}
            handleValidationInput={handleValidationInput}
            error={error}
        />
    )
}

export default InputDropdownRoomKiosk
