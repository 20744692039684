import React from 'react'

export default function IcSingleChoice(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="9" cy="9" r="7.5" stroke="#B7B7B7"/>
    </svg>

  )
}
