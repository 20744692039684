import React, { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";

// styles
import { WrapFormOptionKiosk } from "./index.styles";
import { OverlayFullScreen } from "../../../../../../MainStyles/main";

//component
import Button from "../../../../../../Control/Button";
import InputDropdownRoomKiosk from "../FormOptionKiosk/InputDropdownRoomKiosk";
import InputIdKiosk from "./InputIdKiosk";
import InputNameKiosk from "./InputNameKiosk";

// image
import IcAddItem from "../../../../../../../assets/images/IcAddItem";
import IcClose from "../../../../../../../assets/images/IcClose";

//mockData
import { listRoom } from "../../../../../../../data/listRoom";
import DragAndDropImage from "../../../../../../Control/DragAndDropImage";
import IcCloseWhite from "../../../../../../../assets/images/IcCLoseWhite";
import { WrapDropImage } from "../../../../../../Control/DragAndDropImage/index.styles";
import {
  getInfoLocker,
  getListKioskAction,
} from "../../../../../../../redux/kiosk/action";
import {
  checkValidation,
  getStrValidation,
} from "../../../../../../../helper/validation";

//firebase
import { uploadFileStorage } from "../../../../../../../firebase/storage";

//helper
import { mapKioskPathName } from "../../../../../../../helper/mapStoragePathName";
import { useParams } from "react-router-dom";

// host
import { apiGetPhoto } from "../../../../../../../constant/host";

// action
import { showNotify } from "../../../../../../../redux/notifies/action";
import { OVER_SIZE_ERR } from "../../../../../../../redux/notifies/types";
import { enableLoading } from "../../../../../../../redux/loading/action";

const listRoomKiosk = listRoom;

const MAX_NUM_IMG = 3;

const MAX_SIZE = 3000000; // 3Mb

const FormOptionKiosk = ({
  onClickIconClose,
  titleOption,
  dataKioskEdit,
  isDisable,
  textBtn,
  iconBtn,
  objLstImg,
  setObjLstImg,
  objLstUrlImg,
  setObjLstUrlImg,
  apiHandleAdd,
  apiHandleUpdate,
}) => {
  const dispatch = useDispatch();
  const { branchCode } = useSelector((state) => state.login.dataLogin);
  const { kioskId } = useParams();
  const configRoomType = {
    key: dataKioskEdit?.roomType,
    text: listRoomKiosk[dataKioskEdit?.roomType]?.text,
  };

  const [dataKiosk, setDataKiosk] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      idKiosk: dataKioskEdit?.kioskId || undefined,
      nameKiosk: dataKioskEdit?.kioskName || undefined,
      roomKiosk: dataKioskEdit ? configRoomType : undefined,
      photoList: dataKioskEdit?.photoList || undefined,
      errorSubmitId: false,
      errorSubmitName: false,
      errorSubmitRoom: false,
      error: "",
    }
  );

  useEffect(() => {
    return () => {};
  }, [objLstImg, objLstUrlImg]);

  const _handleGetFileImg = (file) => {
    const idImg = nanoid();
    const { name, type, size } = file;
    if (size > MAX_SIZE) {
      dispatch(showNotify(OVER_SIZE_ERR));
      return;
    }
    setObjLstImg((pre) => ({
      ...pre,
      [idImg]: {
        key: idImg,
        file,
        name,
        type,
      },
    }));

    setObjLstUrlImg((pre) => ({
      ...pre,
      [idImg]: {
        key: idImg,
        url: URL.createObjectURL(file),
        isUploaded: false,
      },
    }));
  };

  const _handleRemoveImg = (key) => () => {
    const newObjLstImg = { ...objLstImg };
    const newObjLstUrlImg = { ...objLstUrlImg };
    delete newObjLstImg[key];
    URL.revokeObjectURL(newObjLstUrlImg[key]);
    delete newObjLstUrlImg[key];
    setObjLstImg(newObjLstImg);
    setObjLstUrlImg(newObjLstUrlImg);
  };

  const _handleSetValueRoomKiosk = (val) => {
    setDataKiosk({ roomKiosk: val, errorSubmitRoom: false });
  };

  const _handleSetValueNameKiosk = (val) => {
    setDataKiosk({ nameKiosk: val, errorSubmitName: false });
  };

  const _handleSetValueIdKiosk = (val) => {
    setDataKiosk({ idKiosk: val, errorSubmitId: false });
  };

  const _handleValidationInputID = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      setDataKiosk({ errorSubmitId: true });
      callback("Thông tin không được để trống");
      return;
    }
    if (checkValidation.errorNumberAndMax(value, 10)) {
      setDataKiosk({ errorSubmitId: true });
      callback(getStrValidation.errorFormat);
    }
  };

  const _handleValidationInputName = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      setDataKiosk({ errorSubmitName: true });
      callback("Thông tin không được để trống");
      return;
    }
    if (checkValidation.limitRange(value, 0, 100)) {
      setDataKiosk({ errorSubmitName: true });
      callback(getStrValidation.errorFormat);
    }
  };

  const _handleValidationInputDropdown = (objChoice, callback) => {
    if (!objChoice) {
      setDataKiosk({ errorSubmitRoom: true });
      callback("Thông tin không được để trống");
    }
  };

  const onSubmit = () => {
    if (!dataKiosk.idKiosk && !dataKiosk.nameKiosk && !dataKiosk.roomKiosk) {
      setDataKiosk({ error: "Thông tin không được để trống" });
      return;
    }
    if (
      dataKiosk.errorSubmitId ||
      dataKiosk.errorSubmitName ||
      dataKiosk.errorSubmitRoom
    )
      return;
    //upload img
    let subFolder = `${new Date().getTime()}`;
    const _handleSubmit = () => {
      let configPhotoList = Object.values(objLstUrlImg).map((item) => {
        if (item.isUploaded) return item.url;
        else {
          if (objLstImg[item.key]) {
            return mapKioskPathName(
              `${subFolder}/${item.key}_${objLstImg[item.key].name}`
            );
          }
        }
      });
      const callbackSuccess = () => {
        onClickIconClose();
        if (kioskId) {
          dispatch(getInfoLocker({ docID: kioskId }));
        } else {
          dispatch(
            getListKioskAction({
              branchCode: branchCode,
              filterFrom: "",
              filterSize: 100,
              roomType: "",
              status: "",
              quickSearchData: "",
            })
          );
        }
      };

      const callbackError = (code) => {};

      {
        apiHandleAdd &&
          dispatch(
            apiHandleAdd(
              {
                branchCode: branchCode,
                kioskID: dataKiosk?.idKiosk,
                kioskName: dataKiosk?.nameKiosk,
                roomType: dataKiosk?.roomKiosk?.key,
                photoList: configPhotoList || [],
              },
              callbackSuccess,
              callbackError
            )
          );
      }

      {
        apiHandleUpdate &&
          dispatch(
            apiHandleUpdate(
              {
                id: dataKioskEdit?.id,
                kioskName: dataKiosk?.nameKiosk,
                photoList: configPhotoList || [],
              },
              callbackSuccess,
              callbackError
            )
          );
      }
    };
    if (objLstImg) {
      let listImg = Object.values(objLstImg);
      let countImg = listImg.length;
      if (countImg === 0) {
        _handleSubmit();
        return;
      }
      dispatch(enableLoading());
      listImg.forEach((imgObj) => {
        const handleUploadSuccess = () => {
          countImg--;
          if (countImg === 0) {
            _handleSubmit();
          }
        };
        const handleUploadFail = () => {
          countImg--;
          if (countImg === 0) {
            _handleSubmit();
          }
        };
        let pathName = mapKioskPathName(
          `${subFolder}/${imgObj.key}_${imgObj.name}`
        );
        uploadFileStorage(
          imgObj.file,
          pathName,
          handleUploadSuccess,
          handleUploadFail
        );
      });
    } else {
      _handleSubmit();
    }
  };

  return (
    <OverlayFullScreen onClick={(e) => e.stopPropagation()}>
      <div className="wrap-inner-screen">
        <WrapFormOptionKiosk>
          <IcClose className="icon-close" onClick={onClickIconClose} />
          <div className="title">{titleOption}</div>
          <div className="description">Thông tin chi tiết Kiosk</div>
          <InputIdKiosk
            handleGetValueInput={_handleSetValueIdKiosk}
            value={dataKiosk.idKiosk}
            isDisable={isDisable}
            isRequired={true}
            handleValidationInput={_handleValidationInputID}
            error={dataKiosk.error}
          />
          <InputNameKiosk
            handleGetValueInput={_handleSetValueNameKiosk}
            value={dataKiosk.nameKiosk}
            handleValidationInput={_handleValidationInputName}
            error={dataKiosk.error}
          />
          <InputDropdownRoomKiosk
            handleGetValueDropdown={_handleSetValueRoomKiosk}
            listDropDown={Object.values(listRoomKiosk).slice(1)}
            objChoice={dataKiosk.roomKiosk}
            isDisable={isDisable}
            required={true}
            handleValidationInput={_handleValidationInputDropdown}
            error={dataKiosk.error}
          />
          <div className="upload-img">
            <div className="title-upload">Hình ảnh Kiosk tại địa điểm</div>
            <div className="wrap-list-image">
              {Object.values(objLstUrlImg).length < MAX_NUM_IMG && (
                <DragAndDropImage handleDropFiles={_handleGetFileImg} />
              )}
              {Object.values(objLstUrlImg).map(({ key, url, isUploaded }) => (
                <div className="wrap-images" key={key}>
                  <WrapDropImage key={key}>
                    <IcCloseWhite
                      className="remove-img"
                      onClick={_handleRemoveImg(key)}
                    />
                    <div className="wrap-img">
                      <img
                        src={isUploaded ? apiGetPhoto(url) : url}
                        alt="img"
                      />
                    </div>
                  </WrapDropImage>
                </div>
              ))}
            </div>
          </div>
          <div className="btn-container">
            <Button
              text={textBtn}
              iconBtn={iconBtn}
              className="btn-edit"
              onClick={onSubmit}
            />
          </div>
        </WrapFormOptionKiosk>
      </div>
    </OverlayFullScreen>
  );
};

export default FormOptionKiosk;
