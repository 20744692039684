import IcGridInfo from "../assets/images/IcGridInfo";
import IcInfo from "../assets/images/IcInfo";
import { CABINET_INFO, CABINET_RANGE } from "../constant/keys";

const dataHeaderCabinet = [
    {   
        key: CABINET_INFO,
        name: "Thông tin dãy tủ",
        Icon: IcInfo,
      },
      {
        key: CABINET_RANGE,
        name: "Hộc tủ đang quản lý",
        Icon: IcGridInfo,
      },
  ]

export default dataHeaderCabinet