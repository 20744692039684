import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns:
        80px
        225px
        140px
        500px
        110px;
    padding: 11px 17px 10px 17px;
    grid-gap: 5px;
    box-sizing: border-box;
    /* width: calc(100% - 12px); */
    height: 60px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #313131;
    align-items: center;
    &:hover {
        /* cursor: pointer; */
        /* width: calc(100% - 42px);
        height: 58px; */
        border: 1px solid #33ADFF;
    }
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: inline-block;
    }
    .wrap-avatar{
        height: 36px;
        width: 36px;
        border-radius: 50%;
        .avatar{
            width: 36px;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            
            &:hover {
                cursor: pointer;
            }
        }
    }
    .member-value{
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
`;

export {
    Bound
}