import React from "react";

const IcCloseOutlineRed = (props) => {
  return (
    <svg
      {...props}
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 18.3333C15.1022 18.3333 18.8332 14.6024 18.8332 9.99999C18.8332 5.39762 15.1022 1.66666 10.4998 1.66666C5.89746 1.66666 2.1665 5.39762 2.1665 9.99999C2.1665 14.6024 5.89746 18.3333 10.4998 18.3333Z"
        fill="#FF2028"
        stroke="#FF2028"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 7.5L8 12.5M8 7.5L13 12.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcCloseOutlineRed;
