import { combineReducers } from "redux";
import login from './login'
import memberReducer from './member'
import notifies from './notifies'
import loading from './loading'
import kioskReducer from "./kiosk/kioskReducer";
import clusterReducer from "./cluster/clusterReducer";
import lockerReducer from "./locker/lockerReducer";
import controlAutoDoor from "./autoDoor";

const app = combineReducers({
    login,
    memberReducer,
    notifies,
    loading,
    kioskReducer,
    clusterReducer,
    lockerReducer,
    controlAutoDoor
});

const rootReducer = (state, action) => {
    return app(state, action);
};

export default rootReducer;