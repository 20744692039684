import styled from "styled-components";

const WrapKioskItem = styled.div`
    width: 360px;
    height: 245px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    font-family: 'Open Sans';
    font-style: normal;
    letter-spacing: -0.01em;
    overflow: hidden;
    .kiosk-option{
        position: absolute;
        right: 20px;
        top: 20px;
        .wrap-option{
            top: 30px;
            right: 0px;
        }
    }
    .kiosk-img{
        cursor: pointer;
        position: absolute;
        width: 74px;
        height: 170px;
        left: 236px;
        top: 27px;
    }
    .kiosk-name{
        position: absolute;
        left: 20px;
        bottom: 105px;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #010101;
    }
    .kiosk-room{
        position: absolute;
        left: 20px;
        bottom: 86px;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #828282;
    }
    .kiosk-bottom{
        position: absolute;
        left: 0%;
        right: 0%;
        top: 69.39%;
        bottom: 0%;
        background: #FFFFFF;
        box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1), 5px 5px 40px rgba(174, 174, 192, 0.2);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        .kiosk-id{
            padding: 4px 10px;
            background: #EDEDED;
            border-radius: 6px;  
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px; 
            color: #010101;
        }
        .kiosk-status{
            display: flex;
            align-items: center;
            .title-status{
                margin: 0 10px;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                color: #3D8B4C;
            }
            .btn-connect{
                background: #3D8B4C;
                margin-left: 16px;
            }
        }
    }
    

`

export {WrapKioskItem}