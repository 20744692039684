import styled from 'styled-components'
import { TableContent } from '../../../../../MainStyles/main'

const TableHistoryKiosk = styled.div`
    width: calc(1280px - 40px);
    height: calc(100% - 100px);
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .history-header{
        display: flex;
        justify-content: space-between;
        .value{
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.01em;
            color: #191919;
        }
        .btn-close{
            cursor: pointer;
        }
    }
    span{
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        color: #191919;
        margin: 12px 0 20px 0;
    }
    .history-info{
        display: grid;
        grid-template-columns: 120px 120px 120px 120px 120px 140px auto;
        .bold{
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #191919;
            margin-bottom: 4px;
        }
        p{
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #828282;
        }
    }
    .history-filter{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        .btn-filter{
            background-color: #fff;
            border: 1px solid #A31E23;
            border-radius: 4px;
            width: 110px;
            height: 36px;
            color: #A31E23;
        }
    }
    .history-table{
        border: 0.5px solid #BDBDBD;
        border-radius: 4px;
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .history-title{
            display: grid;
            grid-template-columns: 180px 150px 295px 228px 220px auto;
            box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
            align-items: center;
            padding: 0 20px;
            height: 32px;
            .table-title{
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: -0.02em;
                color: #191919;
            }
        }

`

const TableContentHistory = styled(TableContent)`
    .wrap-list-trans{
        margin-top: 0;
        .list-trans {
            .history-item {
                margin-bottom: 0;
            }

        }
    }
    
`

export {
    TableContentHistory,
    TableHistoryKiosk
}