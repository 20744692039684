import React, { useState, useReducer, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";

// styles
import { WrapFormOptionCabinetRange } from "./index.styles";
import { OverlayFullScreen } from "../../../../../../MainStyles/main";

//component
import Button from "../../../../../../Control/Button";
import InputNameCabinetRange from "./InputNameCabinetRange";
import DragAndDropImage from "../../../../../../Control/DragAndDropImage";
import { WrapDropImage } from "../../../../../../Control/DragAndDropImage/index.styles";
import InputIdCabinetRange from "./InputIdCabinetRange";
import InputDropdownIdKiosk from "./InputDropdownIdKiosk";

// image
import IcClose from "../../../../../../../assets/images/IcClose";
import IcCloseWhite from "../../../../../../../assets/images/IcCLoseWhite";
import ImageZoomDefault from "../../../../../../../assets/images/zoom_img_default.png";

//mockData
import { listRoom } from "../../../../../../../data/listRoom";

//action
import { getListKioskAction } from "../../../../../../../redux/kiosk/action";
import { getInfoByDocID, getListClusterAction } from "../../../../../../../redux/cluster/action";

//validation
import {
  checkValidation,
  getStrValidation,
} from "../../../../../../../helper/validation";

// helper
import { mapClusterPathName } from "../../../../../../../helper/mapStoragePathName";

//firebase
import { uploadFileStorage } from "../../../../../../../firebase/storage";

//const 
import { apiGetPhoto } from "../../../../../../../constant/host";

// action
import { showNotify } from '../../../../../../../redux/notifies/action'
import { OVER_SIZE_ERR } from '../../../../../../../redux/notifies/types'
import { enableLoading } from '../../../../../../../redux/loading/action'

const listRoomKiosk = listRoom;

const MAX_NUM_IMG = 3;
const MAX_SIZE = 3000000 // 3Mb

const FormOptionCabinetRange = ({
  onClickIconClose,
  titleOption,
  dataCabinetRangeEdit,
  isDisable,
  textBtn,
  iconBtn,
  objLstImg,
  setObjLstImg,
  objLstUrlImg,
  setObjLstUrlImg,
  apiHandleAdd,
  apiHandleEdit,
}) => {
  const dispatch = useDispatch();
  const { listKiosks, branchCode, total } = useSelector((state) => ({
    listKiosks: state.kioskReducer.listKiosks,
    total: state.kioskReducer.total,
    branchCode: state.login.dataLogin.branchCode,
  }));

  const [imageZoomDefault, setImageZoomDefault] = useState(
    Object.values(objLstUrlImg)[0]?.isUploaded? apiGetPhoto(Object.values(objLstUrlImg)[0]?.url):Object.values(objLstUrlImg)[0]?.url || ImageZoomDefault
  );
  //fix list info Kiosk de hien thi dropdown UI khi add
  const configListIdKiosk = listKiosks?.map((item, i) => {
    return {
      key: item?.kioskId,
      text: `${item?.kioskId} - ${listRoomKiosk[item?.roomType]?.text}`,
      roomType: item?.roomType,
    };
  });
  // sort array dropdown by anphabet
  configListIdKiosk.sort((a,b)=>{ return a.key - b.key })

  //fix lại list info kiosk nếu là Edit
  const configIdKioskEdit = {
    key: dataCabinetRangeEdit?.kioskId,
    text: `${dataCabinetRangeEdit?.kioskId} - ${
      listRoomKiosk[dataCabinetRangeEdit?.roomType]?.text
    }`,
    roomType: dataCabinetRangeEdit?.roomType,
  };

  const [dataCabinetRange, setDataCabinetRange] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      idCabinetRange: dataCabinetRangeEdit?.clusterId || undefined,
      nameCabinetRange: dataCabinetRangeEdit?.clusterName || undefined,
      idKiosk: dataCabinetRangeEdit ? configIdKioskEdit : undefined,
      photoList: dataCabinetRangeEdit?.photoList || undefined,
      errorSubmitId: false,
      errorSubmitName: false,
      error: ""
    }
  );

  const _handleGetFileImg = (file) => {
    const idImg = nanoid();
    const { name, type, size } = file
      if(size > MAX_SIZE){
          dispatch(showNotify(OVER_SIZE_ERR))
          return
      }
    setObjLstImg((pre) => ({
      ...pre,
      [idImg]: {
        key: idImg,
        file,
        name,
        type,
      },
    }));

    setObjLstUrlImg((pre) => ({
      ...pre,
      [idImg]: {
        key: idImg,
        url: URL.createObjectURL(file),
        isUploaded: false
      },
    }));
    setImageZoomDefault(URL.createObjectURL(file));
  };

  const _handleRemoveImg = (key) => () => {
    const newObjLstImg = { ...objLstImg };
    const newObjLstUrlImg = { ...objLstUrlImg };
    delete newObjLstImg[key];
    URL.revokeObjectURL(newObjLstUrlImg[key]);
    delete newObjLstUrlImg[key];
    setObjLstImg(newObjLstImg);
    setObjLstUrlImg(newObjLstUrlImg);
    setImageZoomDefault(
      Object.values(newObjLstUrlImg)[0]?.isUploaded? apiGetPhoto(Object.values(newObjLstUrlImg)[0]?.url):Object.values(newObjLstUrlImg)[0]?.url || ImageZoomDefault
    );
  };
  
  const handleZoomImage = (url) => {
    setImageZoomDefault(url);
  };

  const _handleSetValueRoomKiosk = (val) => {
    setDataCabinetRange({ idKiosk: val});
  };

  const _handleSetValueNameCabinetRange = (val) => {
    setDataCabinetRange({ nameCabinetRange: val, errorSubmitName: false });
  };

  const _handleSetValueIdCabinetRange = (val) => {
    setDataCabinetRange({ idCabinetRange: val, errorSubmitId: false });
  };

  const _handleValidationInputID = (value, callback) => {
    if (!value?.trim() || 0 === value.length ) {
      setDataCabinetRange({errorSubmitId: true})
      callback("Thông tin không được để trống");
      return
    }
    if (checkValidation.errorNumberAndMax(value, 10)) {
      setDataCabinetRange({errorSubmitId: true})
      callback(getStrValidation.errorFormat);
    }
  };

  const _handleValidationInputName = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      setDataCabinetRange({errorSubmitName: true})
      callback("Thông tin không được để trống");
      return
    }
    if (checkValidation.limitRange(value, 0, 100)) {
      setDataCabinetRange({errorSubmitName: true})
      callback(getStrValidation.errorFormat);
    }
  };
  //SUBMIT
  const onSubmit = () => {
    if (!dataCabinetRange.idCabinetRange && !dataCabinetRange.nameCabinetRange){
      setDataCabinetRange({error: "Thông tin không được để trống"})
      return
    }
    if (dataCabinetRange.errorSubmitId || dataCabinetRange.errorSubmitName) return
    //upload img
    let subFolder = `${new Date().getTime()}`
   
    const _handleSubmit = () => {
      let configPhotoList = Object.values(objLstUrlImg).map((item) => {
        if(item.isUploaded) return item.url
        else{ 
          if(objLstImg[item.key]){
            return mapClusterPathName(`${subFolder}/${item.key}_${objLstImg[item.key].name}`)}
          }
      });
      const callbackSuccess = () => {
        onClickIconClose();
        dispatch(
          getListClusterAction({
            branchCode: branchCode,
            filterFrom: "",
            filterSize: 100,
            quickSearchData: "",
            roomType: "",
            kioskName: ""
          })
        );
       
        apiHandleEdit && dispatch(getInfoByDocID({docID: dataCabinetRangeEdit?.id}))
      };
      const callbackError = (code) => {
        
      };
      {
        apiHandleAdd &&
          dispatch(
            apiHandleAdd(
              {
                branchCode: branchCode,
                kioskID: dataCabinetRange?.idKiosk?.key || "",
                roomType: dataCabinetRange?.idKiosk?.roomType || "",
                clusterID: dataCabinetRange?.idCabinetRange,
                clusterName: dataCabinetRange?.nameCabinetRange,
                photoList: configPhotoList || [],
              },
              callbackSuccess,
              callbackError
            )
          );
      }
      {
        apiHandleEdit &&
          dispatch(
            apiHandleEdit(
              {
                id: dataCabinetRangeEdit?.id,
                clusterName: dataCabinetRange?.nameCabinetRange,
                roomType: dataCabinetRange?.idKiosk?.roomType || "",
                kioskID: dataCabinetRange?.idKiosk?.key || "",
                photoList: configPhotoList || [],
              },
              callbackSuccess,
              callbackError
            )
          );
      }
    }
    if(objLstImg){
      let listImg = Object.values(objLstImg)
      let countImg = listImg.length
      if(countImg === 0){
        _handleSubmit()
        return;
      }
      dispatch(enableLoading())
      listImg.forEach(imgObj => {
        const handleUploadSuccess = () => {
          countImg--;
          if(countImg === 0){
            _handleSubmit()
          }
        }
        const handleUploadFail = () => {
          countImg--;
          if(countImg === 0){
            _handleSubmit()
          }
        }
        let pathName = mapClusterPathName(`${subFolder}/${imgObj.key}_${imgObj.name}`)
        uploadFileStorage(imgObj.file, pathName, handleUploadSuccess, handleUploadFail)
      });
    }
    else{
      _handleSubmit()
    }
  };

  useEffect(() => {
    dispatch(
      getListKioskAction({
        branchCode: branchCode,
        filterFrom: "",
        filterSize: total ? total : 10,
        quickSearchData: "",
        roomType: "",
        status: ""
      })
    );
  }, [total]);

  return (
    <OverlayFullScreen onClick={(e) => e.stopPropagation()}>
      <div className="wrap-inner-screen">
        <WrapFormOptionCabinetRange>
          <IcClose className="icon-close" onClick={onClickIconClose} />
          <div className="title">{titleOption}</div>
          <div className="description">Thông tin chi tiết Dãy tủ</div>
          <div className="form-content">
            <div className="form-content-left">
              <div className="title-content">Hình ảnh thực tế dãy tủ</div>
              <div className="upload-img">
                <div className="wrap-zoom-image">
                  <img src={imageZoomDefault} alt="citigym" />
                </div>
                <div className="wrap-list-image">
                  {Object.values(objLstUrlImg).length < MAX_NUM_IMG && (
                    <DragAndDropImage handleDropFiles={_handleGetFileImg} />
                  )}
                  {Object.values(objLstUrlImg).map(({ key, url, isUploaded }) => (
                    <div className="wrap-images" key={key}>
                      <WrapDropImage>
                        <IcCloseWhite
                          className="remove-img"
                          onClick={_handleRemoveImg(key)}
                        />
                        <div
                          className="wrap-img"
                          onClick={() => handleZoomImage(isUploaded?apiGetPhoto(url):url)}
                        >
                          <img src={isUploaded?apiGetPhoto(url):url} alt="citigym" />
                        </div>
                      </WrapDropImage>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="form-content-right">
              <div className="title-content">Thông tin chi tiết</div>
              <InputIdCabinetRange
                handleGetValueInput={_handleSetValueIdCabinetRange}
                value={dataCabinetRange.idCabinetRange}
                isDisable={dataCabinetRangeEdit ? true : false}
                isRequired={true}
                handleValidationInput={_handleValidationInputID}
                error={dataCabinetRange.error}
              />
              
              <InputNameCabinetRange
                handleGetValueInput={_handleSetValueNameCabinetRange}
                value={dataCabinetRange.nameCabinetRange}
                handleValidationInput={_handleValidationInputName}
                error={dataCabinetRange.error}
              />
              
              <InputDropdownIdKiosk
                handleGetValueDropdown={_handleSetValueRoomKiosk}
                listDropDown={configListIdKiosk}
                objChoice={dataCabinetRange.idKiosk}
                isDisable={isDisable}
                required={false}
              />
              <div className="btn-container">
                <Button
                  text={textBtn}
                  iconBtn={iconBtn}
                  className="btn-edit"
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </WrapFormOptionCabinetRange>
      </div>
    </OverlayFullScreen>
  );
};

export default FormOptionCabinetRange;
