import styled from 'styled-components'
import { TableContentEnroll, TableHeaderEnroll } from '../index.styles'

const WrapNewCheckFaceAccess = styled.div`
    width: calc(1280px - 40px);
    height: calc(100% - 100px);
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const WrapHeaderCheckFace = styled.div`
    margin-bottom: 20px;
    .check-face-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-value{
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.01em;
            color: #313131;
            margin-bottom: 12px;
        }
        .btn-close{
            cursor: pointer;
        }
    }
    span{
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #191919;
    }
`

const WrapContentCheckFace = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const WrapContentCheckFaceImg = styled.div`
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    .check-face-title{
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #313131;
    }
    .check-face-img{
        height: 130px;
        width: 130px;
        position: relative;
        box-sizing: border-box;
        border-radius: 4px;
        background: #F7F7F7;
        display: inline-block;
        border: 0.5px dashed #B7B7B7;
        margin: 16px 0 20px 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    }
`


const TableHeaderCheckFaceEnroll = styled(TableHeaderEnroll)`
    display: grid;
    grid-template-columns:
        80px
        225px
        140px
        600px
        105px;
`

const TableContentCheckFaceEnroll = styled(TableContentEnroll)`
`

export {
    WrapNewCheckFaceAccess,
    WrapContentCheckFace,
    WrapContentCheckFaceImg,
    WrapHeaderCheckFace,
    TableHeaderCheckFaceEnroll,
    TableContentCheckFaceEnroll
}