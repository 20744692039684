import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

//images
import IcEdit from '../../../../../../assets/images/IcEdit'
import IcMoreOptionGray from '../../../../../../assets/images/IcMoreOptionGray'
import IcTrash from '../../../../../../assets/images/IcTrash'
import IcUpdatePassword from '../../../../../../assets/images/IcUpdatePassword'
import { useOnClickOutside } from '../../../../../../hook/useClickOutside'

//component
import MoreOption from '../../../../../Control/MoreOption'
import PopupWarning from '../../../../../Control/PopupWarning'
import Portal from '../../../../../Control/Portal'
import PopupAddKiosk from './PopupAddKiosk'
import PopupChangePassword from './PopupChangePassword'
import PopupDeleteKiosk from './PopupDeleteKiosk'
import PopupEditKiosk from './PopupEditKiosk'

export const EDIT_KIOSK = 'EDIT_KIOSK'
export const DEL_KIOSK = "DEL_KIOSK"
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const INDENTIFY = 'INDENTIFY'
export const WARNING = 'WARNING'

const OptionKiosk = ({ dataKiosk, refList }) => {

    const dispatch = useDispatch()
    const [keyShowForm, setKeyShowForm] = useState('')
    let arrLockerKiosk = dataKiosk?.lockerClusterList?.map(clus=>clus.lockerList) 
    let arrLockersFlatten = arrLockerKiosk.flat(Infinity)
    let usingStatus = arrLockersFlatten.map(item=>item.useStatus).includes(true)
   
    const handleEdit = () => {
        setKeyShowForm(EDIT_KIOSK)
    }

    const handleDelete = () => {
        if (usingStatus){
            setKeyShowForm(WARNING)
            return
        }
        setKeyShowForm(DEL_KIOSK)
    }

    const handleUpdatePassword = () => {
        setKeyShowForm(CHANGE_PASSWORD)
    }

    const handleClose = (e) => {
        // e.stopPropagation()
        setKeyShowForm('')
        
    }

    const dataMoreOption = [
        {
            Icon: IcEdit,
            text: "Chỉnh sửa",
            handleOption: handleEdit
        },
        {
            Icon: IcTrash,
            text: "Xoá",
            handleOption: handleDelete
        },
        {
            Icon: IcUpdatePassword,
            text: "Cấp mới mật khẩu",
            handleOption: handleUpdatePassword
        },
    ]
    
    return (
        <>
            <MoreOption
                dataOption={dataMoreOption}
                refList={refList}
                iconOption={<IcMoreOptionGray/>}
            />
            { keyShowForm === EDIT_KIOSK && (
                <Portal>
                    <PopupEditKiosk
                        onClickIconClose = {handleClose}
                        dataKioskEdit = {dataKiosk}
                    />
                </Portal>
            )}
            { keyShowForm === CHANGE_PASSWORD && (
                <Portal>
                    <PopupChangePassword 
                        onClickIconClose = {handleClose}
                        dataKiosk={dataKiosk}
                    />
                </Portal>
            )}
            { keyShowForm === DEL_KIOSK && (
                <Portal>
                    <PopupDeleteKiosk
                        dataKiosk={dataKiosk}
                        handleClose={handleClose}
                    />
                </Portal>
            )}
            { keyShowForm === WARNING && (
                <Portal>
                    <PopupWarning 
                        title="Cảnh báo xóa Kiosk"
                        subTitle="Kiosk hiện có hộc tủ đang sử dụng. Bạn không thể xóa. Vui lòng kiểm tra và thử lại sau"
                        handleClose={handleClose}
                    />
                </Portal>
            )}
        </>
    )
}

export default OptionKiosk
