import styled from 'styled-components'

const WrapImage = styled.div`
    width: 110px;
    height: 110px;
    box-sizing: border-box;
    border-radius: 4px;
   
`

const WrapDropImage = styled(WrapImage)`
    position: relative;
    .wrap-img {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
            display: block;
        }
    }

    .remove-img {
        position: absolute;
        right: 8px;
        top: 8px;

        &:hover {
            cursor: pointer;
        }
    }
`

const DragAndDropArea = styled(WrapImage)`
    border: 0.5px dashed #828282;
    background: #F7F7F7;
    text-align: center;
    margin-right: 10px;

    .ic-upload {
        margin: 8px 0;
        padding: 17px;
        border-radius: 50%;
        background: rgba(130, 130, 130, 0.1);
    }

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #828282;
        user-select: none;
    }

    &:hover {
        cursor: pointer;
        background: #EFF9FF;
        border: 1px dashed #33ADFF;
    }
`;

export {
    WrapDropImage,
    DragAndDropArea
}