import styled from "styled-components";

const WrapCabinetRangeItem = styled.div`
    width: 360px;
    height: 245px;
    background: #b4b4b4;
    border-radius: 10px;
    position: relative;
    font-family: 'Open Sans';
    font-style: normal;
    letter-spacing: -0.01em;
    overflow: hidden;
    cursor: pointer;
    .cabinet-range-option{
        position: absolute;
        width: 22px;
        height: 22px;
        right: 20px;
        top: 20px;
        background: rgba(163, 30, 35, 0.3);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 32px;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        .wrap-option{
            top: 30px;
            right: -3px;
        }
    }
    .cabinet-img-default{
        position: absolute;
        left: 156px;
        top: 65px;
    }
    .cabinet-range-img{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: -2px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 12px;
        }
    }
    .cabinet-range-name{
        position: absolute;
        left: 20px;
        bottom: 105px;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #FFFFFF;
    }
    .cabinet-range-room{
        position: absolute;
        left: 20px;
        bottom: 86px;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #FFFFFF;
    }
    .cabinet-range-bottom{
        position: absolute;
        left: 0%;
        right: 0%;
        top: 69.39%;
        bottom: 0%;
        background: #FFFFFF;
        box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1), 5px 5px 40px rgba(174, 174, 192, 0.2);
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        .cabinet-range-id{
            padding: 4px 10px;
            background: #EDEDED;
            border-radius: 6px;  
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px; 
            color: #010101;
        }
        p{
            font-weight: 400;
            font-size: 13.5px;
            line-height: 18px; 
            color: #191919;
        }
        .cabinet-range-free{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-weight: 600;
            font-size: 11px;
            line-height: 15px;
            color: #41C728
            
        }
    }
    

`

export {WrapCabinetRangeItem}