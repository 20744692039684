import React, { useEffect, useState } from 'react'

//images
import IcNameKioskBlue from '../../../../../../../../assets/images/IcNameKioskBlue'
import IcNameKioskGray from '../../../../../../../../assets/images/IcNameKioskGray'
import InputDropdown from '../../../../../../../Control/InputDropdown'

const iconStore = {
    icon: IcNameKioskGray,
    iconActive: IcNameKioskBlue,
    iconError: IcNameKioskBlue,
}

const InputDropdownIdKiosk = ({ handleGetValueDropdown, listDropDown, objChoice, isDisable, required }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }
    
    return (
        <InputDropdown
            title="Thông tin Kiosk"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder={"Chọn Kiosk (Phòng)"}
            required={required}
            isDisable={isDisable}
            dataFilterOption={listDropDown}
            objChoice={objChoice}
            isLengthStringBig = {true}
        />
    )
}

export default InputDropdownIdKiosk
