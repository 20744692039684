import styled from 'styled-components'

import { WrapCreateAndEditPopup } from '../../../../../../MainStyles/main'

const WrapFormOptionCabinetRange = styled(WrapCreateAndEditPopup)`
    width: 740px;
    box-sizing: border-box;
    .title{
        margin-bottom: 8px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
    }
    .description{
        margin-bottom: 20px;
    }
    .form-content{
        display: grid;
        grid-template-columns: 320px 352px;
        grid-column-gap: 20px;
        .title-content{
            margin-bottom: 20px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #191919;
        }
        .upload-img{
            display: flex;
            flex-direction: column;
            max-width: calc(400px - 48px);
            overflow: hidden;
            .wrap-zoom-image{
                width: calc(320px - 4px);
                height: calc(320px - 4px);
                margin-bottom: 10px;
                filter: drop-shadow(0px 8px 16px rgba(0,0,0,0.1));
                border: 2px solid #EFEFEF;
                img{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    border-radius: 4px;
                    object-fit: contain;
                }
            }
            .wrap-list-image {
                display: flex;
                align-items: center;
                overflow: auto;
                .wrap-images{
                    position: relative;
                    margin-right: 10px;
                    &:last-child{
                        margin-right: 0px;
                    }               
                }
            }
        }
        .submit-error{
            color: #F45C5C;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            text-align: left;
            margin: -15px 0 0px 15px;
        }
        .btn-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 231px;
            .btn-edit{
                width: 100%;
            }
        }
    }
    
    
`;



export {
    WrapFormOptionCabinetRange,
    
}