import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

// styles
import { WrapChangePassword } from './index.styles'
import { OverlayFullScreen } from '../../../../../../MainStyles/main'

//component
import Button from '../../../../../../Control/Button'
import InputPassword from '../../../../../../Control/InputPassword'

// image
import IcClose from '../../../../../../../assets/images/IcClose'

//mockData
import IcSaveWhite from '../../../../../../../assets/images/IcSaveWhite'
import { changePasswordKiosk } from '../../../../../../../redux/kiosk/action'
import { sha256 } from 'js-sha256'
import { checkValidation, getStrValidation } from '../../../../../../../helper/validation'

const PopupChangePassword = ({ onClickIconClose, dataKiosk }) => {

    const dispatch = useDispatch()
    const [newPassword, setNewPassword] = useState('')
    const [error, setError] = useState(false)

    const _handleChangePassword = (event) => {
        const {name, value} = event.target
        setNewPassword(value)
        setError(false)
    }

    const _handleValidationPassword = (value, callback) => {
        if (!value?.trim() || 0 === value.length) {
            setError(true)
            callback("Thông tin không được để trống")
            return
        }
        if(checkValidation.errorNumberAndMax(value,6) || value.length < 6){
            setError(true)
            callback(getStrValidation.errorFormat)
            return
        }

    }

    const _handleSubmit = () => {
        if ( error ) return
        
        const callbackSuccess = () => {
            onClickIconClose()
        }

        const callbackError = () => {

        }
        dispatch(changePasswordKiosk({kioskDocID: dataKiosk?.id, pwd: btoa(sha256(newPassword))}, callbackSuccess,callbackError))
    }

    return (
        <OverlayFullScreen onClick={(e)=> e.stopPropagation()} >
            <div className="wrap-inner-screen">
                <WrapChangePassword autoComplete = "off" >
                    <IcClose className="icon-close" onClick={onClickIconClose} />
                    <div className="title">Cấp mới mật khẩu</div>
                    <div className="description">
                        Mật khẩu dùng để truy cập các chức năng quản trị trên Kiosk
                    </div>
                    <div className='label-input'>
                        <span>Nhập mật khẩu mới</span>
                        <span className='notice'>*</span>
                    </div>
                    <InputPassword
                        autoComplete = "new-password"
                        placeholder="Nhập mật khẩu"
                        type="password"
                        inputType="PWD"
                        name="new-password"
                        required
                        value={newPassword}
                        onChange={_handleChangePassword}
                        handleValidationPassword={_handleValidationPassword}
                    />
                    <div className='btn-container'>
                        <Button
                            text="Xác nhận"
                            iconBtn={<IcSaveWhite/>}
                            className="btn-edit"
                            onClick={_handleSubmit}
                        />
                    </div>
                </WrapChangePassword>
            </div>
        </OverlayFullScreen>

    )
}

export default PopupChangePassword
