
import React from 'react'

const IcCheckConfirmWhite = (props) => {
    return (
        <svg {...props} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1667 3.5L5.75004 9.91667L2.83337 7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcCheckConfirmWhite
