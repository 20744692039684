//host
import { host } from "../../constant/host";

//axios
import Axios from "axios";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";

//types
import * as types from "./types";
import checkSessionLogin from "../../helper/checkSessionLogin";
import { logoutAction } from "../login/action";
import { disableLoading, enableLoading } from "../loading/action";
import { CITYGYM_ADMIN_LOGIN } from "../../constant/keys";

const apiAddLocker = `${host}/locker/add`;
const apiUpdateLocker = `${host}/locker/update`;
const apiDelLocker = `${host}/locker/delete`;
const apiFilterLocker = `${host}/locker/filter`;
const apiGetLockerListByCluster = `${host}/locker/getLockerListByClusterID`
const apiLockerHistory = `${host}/lockerHistory/filter`
const apiGetDetailLocker = `${host}/locker/getInfoByDocID`

// const dataLogin = JSON.parse(localStorage.getItem(CITYGYM_ADMIN_LOGIN))

const headerDataRequest = {
  cid: "web",
  reqId: `${new Date().getTime()}`,
  langCode: "vn",
};

let isCalling = false

export const resetListLocker = (callbackSuccess) => async (dispatch) => {
  dispatch({
    type: types.RESET_LIST_LOCKER,
  });

  callbackSuccess && callbackSuccess();
};

export const resetListLockerAll = (callbackSuccess) => async (dispatch) => {
  dispatch({
    type: types.RESET_LIST_LOCKER_ALL,
  });

  callbackSuccess && callbackSuccess();
};

export const getListLockerAction =
  (
    dataGetList = {
      branchCode: "",
      filterFrom: "",
      filterSize: 9,
      quickSearchData: "",
      lockerType: 0,
      kioskID: "",
      clusterID: "",
      lockerStatus: 0
    },
    isScroll = true,
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    !isScroll && dispatch(enableLoading())
    let data = { ...dataGetList };
    const condFilters = {...data};
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    try {
      const res = await Axios.post(apiFilterLocker, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.GET_LIST_LOCKER,
          payload: {
            listLockers: data.data.lockerList || [],
            // totalLockers: data.data.total || 0,
            condFilters,
            isScroll,
          },
        });
        isCalling = false
        callbackSuccess && callbackSuccess();

      } else {
        dispatch({
          type: types.GET_LIST_LOCKER_ERR,
          payload: {
            ...data,
          },
        });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.GET_LIST_LOCKER_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const addLockerAction =
  (
    dataLocker = {
      branchCode: "",
      clusterID: "",
      activePort: "",
      lockerID: "",
      vipStatus: true,
      activeStatus: true,
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataLocker };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiAddLocker, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        await dispatch({ type: types.ADD_LOCKER_SUCCESS });
        isCalling = false
        callbackSuccess && callbackSuccess();
       

      } else {
        dispatch({ type: types.ADD_LOCKER_ERR, payload:{code: data.code, error: data.message} });
        callbackError && callbackError(data.code);
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.ADD_LOCKER_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const updateLockerAction =
  (
    dataLocker = {
      docID: "",
      activePort: "",
      lockerID: "",
      vipStatus: true,
      activeStatus: true
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataLocker };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiUpdateLocker, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({ type: types.UPDATE_LOCKER_SUCCESS, payload:{code: 0} });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({ type: types.UPDATE_LOCKER_ERR, payload:{code: data.code } });
        callbackError && callbackError(data.code);
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.UPDATE_LOCKER_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const delLockerAction =
  (
    dataLocker = {
      id: "",
      branchCode: "",
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataLocker };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiDelLocker, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        dispatch({ type: types.DEL_LOCKER_SUCCESS });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({ type: types.DEL_LOCKER_ERR });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.DEL_LOCKER_ERR));
    }
    
    isCalling = false
    dispatch(disableLoading())
  };

export const resetValueSearchCluster = () => async (dispatch) => {
  await dispatch({
    type: types.RESET_VALUE_SEARCH
  })
  
}

export const getLockerListByCluster = (
  data = {
    branchCode: "",
    clusterId: "",
    startCursor: "",
    limit: 0
  },
  callbackSuccess,
  callbackError
) => async (dispatch, getState) => {
  let dataLogin = getState().login.dataLogin;
  if (!checkSessionLogin(dataLogin)) {
    dispatch(logoutAction());
    return;
  }
  let dataRequest = {...headerDataRequest, data: data, cid: dataLogin.id}
  try {
    const resp = await Axios.post(apiGetLockerListByCluster, dataRequest)
    const {data} = resp
    if(checkIsSuccessAction(data.code)){
      dispatch({
        type: types.GET_LOCKER_LIST_BY_CLUSTER,
        payload: {
          dataLockerListByCluster: data.data.lockerList || []
        }
      })
      callbackSuccess && callbackSuccess()
    }else{
      dispatch({
        type: types.GET_LOCKER_LIST_BY_CLUSTER_ERROR,
        payload: {
          ...data
        }
      })
      callbackError && callbackError()
    }
  } catch (error) {
    dispatch(mapErrors(error, types.GET_LOCKER_LIST_BY_CLUSTER_ERROR));
  
  }
}


export const getLockerHistory = (
    data = {
        branchCode: "",
        kioskID: "",
        clusterID: "",
        lockerID: "",
        filterFrom: "",
        filterSize: 15,
        userType: "",
        actionType: "",
        startTime: "",
        endTime: "",
        quickSearchData: "" 
      },
    isScroll = true,
    callbackSuccess,
    callbackError
) => async (dispatch, getState) => {
  let dataLogin = getState().login.dataLogin
  if(!checkSessionLogin(dataLogin)){
    dispatch(logoutAction())
    return
  }
  if(isCalling) return
  isCalling = true
  let dataRequest = {...headerDataRequest, data: data, cid: dataLogin.id}
  let filterLockerHistory = {...data}
  try {
    const resp = await Axios.post(apiLockerHistory, dataRequest)
    const { data } = resp
    if(checkIsSuccessAction(data.code)){
      dispatch({
        type: types.GET_LOCKER_HISTORY,
        payload: {
            lockerHistory : data.listLockerHistory || [],
            filterLockerHistory,
            isScroll
        }
      })
      isCalling = false
      callbackSuccess && callbackSuccess()
    }else{
      dispatch({
        type: types.GET_LOCKER_HISTORY_ERR,
        payload: {
          ...data
        }
      })
      callbackError && callbackError()
    }
  } catch (error) {
    dispatch(mapErrors(error, types.GET_LOCKER_HISTORY_ERR))
  }
  isCalling = false
}

export const resetLockerHistory = () => async (dispatch) => {
  dispatch({type: types.RESET_LOCKER_HISTORY})
}


export const getDetailLocker = (
  data = {
    docID : ""
  },
  callbackSuccess,
  callbackError
) => async (dispatch, getState) => {
  let dataLogin = getState().login.dataLogin
  if(!checkSessionLogin(dataLogin)){
    dispatch(logoutAction())
    return
  }
  let dataRequest = {...headerDataRequest, data: data, cid: dataLogin.id}
  try {
    const resp = await Axios.post(apiGetDetailLocker, dataRequest)
    const { data } = resp
    if(checkIsSuccessAction(data.code)){
      dispatch({
        type: types.GET_INFO_LOCKER,
        lockerInfo: data.data.lockerInfo
      })
      callbackSuccess && callbackSuccess()
    }else{
      dispatch({
        type: types.GET_INFO_LOCKER_ERROR
      })
      callbackError && callbackError()
    }
  } catch (error) {
    dispatch(mapErrors(error, types.GET_INFO_LOCKER_ERROR))
    
  }
}