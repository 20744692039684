import React, { useEffect, useState } from 'react'
import {WrapCabinetRangeItem} from "./index.styles"
import BgCabinetRange from "../../../../../../assets/images/cumtu.png"
import IcCabinetFree from '../../../../../../assets/images/IcCabinetFree'
import OptionCabinetRange from '../OptionCabinetRange'
import { listRoom } from '../../../../../../data/listRoom'
import { useDispatch, useSelector } from 'react-redux'
import IcCabinetDefaultBg from '../../../../../../assets/images/fi_cabinet.png'
import { apiGetPhoto } from '../../../../../../constant/host'


export default function CabinetRangeItem(props) {
    const dispatch = useDispatch()
    const { className, item, refList, onClick } = props
    const [listImage, setListImage] = useState([])
    let numberOfFreeLocker = item?.lockerList?.filter(locker=>locker.useStatus === false).length

    useEffect(() => {
        
        if(!item) return
        if(!item.photoList || item.photoList.length === 0) return
        let list = []
        item.photoList.forEach(item => {
          list.push(apiGetPhoto(item))
        });
        setListImage(list)
      
        return () => {
          
        }
    }, [item])

  return (
    <WrapCabinetRangeItem onClick={onClick}>
        <div className='cabinet-range-option'>
            <OptionCabinetRange dataCabinetRange={item} refList={refList} />
        </div>
        {!item?.photoList.length && (
            <div className='cabinet-img-default'>
                {/* <IcCabinetDefaultBg/> */}
                <img src={IcCabinetDefaultBg} alt="cabinet-default" />
            </div>
        )}
        {item?.photoList.length > 0 && (
            <div className='cabinet-range-img'>
                <img src={listImage[0]} alt='citygym' />
            </div>
        )}
        <div className='cabinet-range-name' title={item?.clusterName}>
            {item?.clusterName?.length > 20 ? `${item?.clusterName?.substring(0,20)}...` : item?.clusterName}
        </div>
        <div className='cabinet-range-room' style={{display:'flex'}}>
            <span title={item?.kioskName}>{item?.kioskName.length > 15 ? `${item?.kioskName.substring(0,15)}...` : item?.kioskName}</span>
            { item?.roomType && (
                <div style={{position:'relative', marginLeft:'15px'}}>
                    <span style={{fontSize: '20px',position: 'absolute',top: '-5px',left: '-10px'}}>.</span>
                    <span >{item?.roomType ? listRoom[item?.roomType]?.text : ""}</span>
                </div>
            )}
        </div>
        <div className='cabinet-range-bottom'>
            <span className='cabinet-range-id'>{item?.clusterId}</span>
            <div className='cabinet-range-amount'>
                <p>Số lượng hộc tủ {item?.lockerList?.length ? item?.lockerList?.length : 0}</p>
                <div className='cabinet-range-free'>
                    <IcCabinetFree/>
                    <span style={{marginLeft: '3px'}}>Đang trống {numberOfFreeLocker ? numberOfFreeLocker : 0}</span>
                </div>
                
            </div>
        </div>
    </WrapCabinetRangeItem>
  )
}
