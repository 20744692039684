import React from 'react'

export default function IcRoomGray(props) {
  return (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 0.75V15.4122L11.5419 17.25V15.4122M3 0.75L11.5419 2.42797V15.4122M3 0.75H15V15.4122H11.5419" stroke="#BDBDBD" strokeLinejoin="round"/>
        <line x1="0.5" y1="-0.5" x2="2.5" y2="-0.5" transform="matrix(-1.00293e-06 1 -1 -1.9051e-09 9 8.25)" stroke="#BDBDBD" strokeLinecap="round"/>
    </svg>

  )
}
