import React from 'react'

export default function IcUserPlusActive(props) {
  return (
    <svg {...props} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3708_16928)">
            <path d="M16.5 21V19C16.5 17.9391 16.0786 16.9217 15.3284 16.1716C14.5783 15.4214 13.5609 15 12.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21" fill="#A31E23"/>
            <path d="M20.5 8V14" stroke="#A31E23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23.5 11H17.5" stroke="#A31E23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" fill="#A31E23"/>
        </g>
        <defs>
            <clipPath id="clip0_3708_16928">
                <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
            </clipPath>
        </defs>
    </svg>

  )
}
