import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, signOut } from "firebase/auth";
import { getDatabase, ref , set} from 'firebase/database'
import { env, DEV_ENV, QC_ENV, PROD_ENV } from '../constant/environmentType'

export const FIREBASE_NODE_DEVICE = "BioDevice"
export const FIREBASE_NODE_RECEPTION = "WebPortal"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const { firebaseConfig } = (() => {
    switch(env) {
        case DEV_ENV: {
            return {
                firebaseConfig: {
                    apiKey: "AIzaSyAjAKQ3kH3OV4xFG_MqOecZ_mC_Lk5aZf8",
                    authDomain: "citigym-86e46.firebaseapp.com",
                    databaseURL: "https://citigym-86e46.firebaseio.com",
                    projectId: "citigym-86e46",
                    storageBucket: "citigym-86e46.appspot.com",
                    messagingSenderId: "979436574365",
                    appId: "1:979436574365:web:831d512ef44b0c287a2674",
                    measurementId: "G-MRWNJ8DYDW"
                }
            }
        }
        case QC_ENV:{
            return {
                firebaseConfig: {
                    apiKey: "AIzaSyAmNAQ2VHOrzrZgQlVIfrL8qqGgGjDOz2A",
                    authDomain: "citigym-qc-5cab3.firebaseapp.com",
                    databaseURL: "https://citigym-qc-5cab3-default-rtdb.asia-southeast1.firebasedatabase.app",
                    projectId: "citigym-qc-5cab3",
                    storageBucket: "citigym-qc-5cab3.appspot.com",
                    messagingSenderId: "382895519223",
                    appId: "1:382895519223:web:eb81ad1e4d5c2c64586af2"
                }
            }
        }
        case PROD_ENV:{
            return {
                firebaseConfig: {
                    apiKey: "AIzaSyDv2HAF6ko3CAuNEnt6SYtkZi3BOeyGITg",
                    authDomain: "citigym-9600c.firebaseapp.com",
                    databaseURL: "https://citigym-9600c-default-rtdb.asia-southeast1.firebasedatabase.app",
                    projectId: "citigym-9600c",
                    storageBucket: "citigym-9600c.appspot.com",
                    messagingSenderId: "539744869996",
                    appId: "1:539744869996:web:f6fc7d97cf8c44e36e0249",
                    measurementId: "G-P13S0GV085"
                }
            }
        }
        default:
            return {
                firebaseConfig: {
                    apiKey: "AIzaSyAjAKQ3kH3OV4xFG_MqOecZ_mC_Lk5aZf8",
                    authDomain: "citigym-86e46.firebaseapp.com",
                    databaseURL: "https://citigym-86e46.firebaseio.com",
                    projectId: "citigym-86e46",
                    storageBucket: "citigym-86e46.appspot.com",
                    messagingSenderId: "979436574365",
                    appId: "1:979436574365:web:831d512ef44b0c287a2674",
                    measurementId: "G-MRWNJ8DYDW"
                }
            }
    }
})()
// console.log(firebaseConfig)
// export const firebaseConfig = {
//     apiKey: "AIzaSyAjAKQ3kH3OV4xFG_MqOecZ_mC_Lk5aZf8",
//     authDomain: "citigym-86e46.firebaseapp.com",
//     databaseURL: "https://citigym-86e46.firebaseio.com",
//     projectId: "citigym-86e46",
//     storageBucket: "citigym-86e46.appspot.com",
//     messagingSenderId: "979436574365",
//     appId: "1:979436574365:web:831d512ef44b0c287a2674",
//     measurementId: "G-MRWNJ8DYDW"
// };

// const firebaseConfig = {
//     apiKey: "AIzaSyAmNAQ2VHOrzrZgQlVIfrL8qqGgGjDOz2A",
//     authDomain: "citigym-qc-5cab3.firebaseapp.com",
//     databaseURL: "https://citigym-qc-5cab3-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "citigym-qc-5cab3",
//     storageBucket: "citigym-qc-5cab3.appspot.com",
//     messagingSenderId: "382895519223",
//     appId: "1:382895519223:web:eb81ad1e4d5c2c64586af2"
// };

// const analytics = getAnalytics(app);

export const signInFirebase = async (token) => {
    if(!token || token.length === 0) return false
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    signInWithCustomToken(auth, token)
    .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // console.log(user)
        return true
        // ...
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
        // ...
        return false
    })
}

export const setFirebase = async (pathNode, data) => {
    if(!pathNode || pathNode.length === 0) return
    if(!data) return
    const db = getDatabase()
    set(ref(db , pathNode), data)
        .then(() => {
            // console.log('write success')
        })
        .catch((error) => {
            console.log(error)
        })
}


// export const signOutFirebase = () => {
//     signOut(auth).then(() => {
//         // Sign-out successful.
//     }).catch((error) => {
//         // An error happened.
//     });
// }