import React from 'react'
import InputDropdown from '../../../../../../../Control/InputDropdown'

import IcMember from '../../../../../../../../assets/images/IcMember'



const iconStore = {
    icon: IcMember,
    iconActive: IcMember,
    iconError: IcMember,
}

const InputDropdownHistoryMember = ({ handleGetValueDropdown, listDropDown, objChoice }) => {
    
    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }

    return (
        <InputDropdown
            title= "Đối tượng"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            required={false}
            dataFilterOption={listDropDown}
            objChoice={objChoice}
        />
    )
}

export default InputDropdownHistoryMember
