import React, { useState } from 'react'
import IcSaveWhite from '../../../../../../../assets/images/IcSaveWhite'
import { updateClusterAction } from '../../../../../../../redux/cluster/action';
import FormOptionCabinetRange from '../FormOptionCabinetRange'

export default function PopupEditCabinetRange({ onClickIconClose, dataCabinetRange }) {

  const [objLstImg, setObjLstImg] = useState({});
  const configPhotoList = { ...dataCabinetRange?.photoList?.map((item, i)=> {return {key: i, url: item, isUploaded: true}}) }
  const [objLstUrlImg, setObjLstUrlImg] = useState(configPhotoList);
  const apiEditCabinetRange = updateClusterAction
  
  return (
    <FormOptionCabinetRange
      onClickIconClose={onClickIconClose}
      titleOption="Chỉnh sửa Dãy tủ"
      dataCabinetRangeEdit={dataCabinetRange}
      isDisable={dataCabinetRange?.kioskId ? true : false}
      textBtn="Lưu"
      objLstImg={objLstImg}
      setObjLstImg={setObjLstImg}
      objLstUrlImg={objLstUrlImg}
      setObjLstUrlImg={setObjLstUrlImg}
      iconBtn={<IcSaveWhite />}
      apiHandleEdit={apiEditCabinetRange}
    />
  )
}
