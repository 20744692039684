import React from 'react'
import InputDropdown from '../../../../../../../Control/InputDropdown'

import IcHistoryStatus from '../../../../../../../../assets/images/IcHistoryStatus'
//control



const iconStore = {
    icon: IcHistoryStatus,
    iconActive: IcHistoryStatus,
    iconError: IcHistoryStatus,
}

const InputDropdownHistoryStatus = ({ handleGetValueDropdown, listDropDown, objChoice }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }

    return (
        <InputDropdown
            title= "Thao tác"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            required={false}
            dataFilterOption={listDropDown}
            objChoice={objChoice}
            isLengthStringBig={true}
        />
    )
}

export default InputDropdownHistoryStatus
