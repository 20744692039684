import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// css
import { WrapPopupFilterCabinet } from './index.styles'
import { OverlayFullScreen } from '../../../../../../MainStyles/main'

// img
import IcClose from '../../../../../../../assets/images/IcClose'
import IcFilterWhite from '../../../../../../../assets/images/IcFilterWhite'

// hook
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'

// component
import Button from '../../../../../../Control/Button'
import InputDropdownStatusRange from '../../../../KioskManage/Detail/DetailKioskRange/PopupFilterRange/InputDropdownStatus'
import InputDropdownRoom from '../../../../KioskManage/Detail/DetailKioskRange/PopupFilterRange/inputDropdownRoom'
import InputDropdownStatusDoor from './InputDropdownDoor'

//data
import { dataListRange } from '../../../../../../../data/dataListDoor'
import { listKioskStatus } from '../../../../../../../data/listKioskStatus'
import { listRoom } from '../../../../../../../data/listRoom'
import { listTypeCabinet } from '../../../../../../../data/listTypeCabinet'
import { listUseStatus } from '../../../../../../../data/listUseStatus'
import { getListLockerAction } from '../../../../../../../redux/locker/action'
import { resetValueSearchCluster } from '../../../../../../../redux/cluster/action'

const PopupFilterCabinet = ({onClose, valueSearch, setIsSearching}) => {

    const dispatch = useDispatch()
    const filterRef = useRef(null)
    const { listLockers, branchCode, condFilters } =
    useSelector((state) => ({
        listLockers: state.lockerReducer.listLockers,
        branchCode: state.login.dataLogin.branchCode,
        condFilters: state.lockerReducer.condFilters,
    }));
  
    const [filter, setFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
           lockerType: listTypeCabinet[condFilters?.lockerType],
           status: listUseStatus[condFilters?.lockerStatus]
        }
    )
    
    const _onClosePopup = () => {
        if (filterRef) {
            filterRef.current.classList.remove('show-right-popup')
            filterRef.current.classList.add('hide-right-popup')
        }
        setTimeout(() => {
            onClose()
        }, 300);
    }

    const _handleGetValueRoomKiosk = (val) => {
        setFilter({lockerType: val })
    }

    const _handleGetValueStatus = (val) => {
        setFilter({ status: val })
    }

    // const _handleGetValueStatusDoor = (val) => {
    //     setFilter({ statusDoor: val })
    // }

    const _handleReset = () => {
        setIsSearching()        
        setFilter({
            lockerType: Object.values(listTypeCabinet)[0],
            status: Object.values(listUseStatus)[0]
        })
        dispatch(resetValueSearchCluster())
        dispatch(getListLockerAction(
            {branchCode: branchCode,
                filterFrom: "", 
                filterSize: 100, 
                quickSearchData: "", 
                lockerType: 0, 
                kioskID: condFilters?.kioskID, 
                clusterID: condFilters?.clusterID, 
                lockerStatus: 0},false))
    }

    const _handleFilter = () => {
        setIsSearching()
        const { lockerType, status } = filter
        let dataFilter = {
            branchCode: branchCode,
            filterFrom: "",
            filterSize: 100,
            quickSearchData: valueSearch,
            lockerType: lockerType?.key,
            kioskID: condFilters?.kioskID,
            clusterID: condFilters?.clusterID,
            lockerStatus: status?.key
        }
        dispatch(getListLockerAction(dataFilter, false))
    }

    useOnClickOutside(filterRef, ()=>{
        _onClosePopup()
    })

  return (
    <OverlayFullScreen notUseOverlay={true}>
            <WrapPopupFilterCabinet className='show-right-popup' ref={filterRef}>
                <div className="wrap-header">
                    <IcClose
                        className='btn-close'
                        onClick={_onClosePopup}
                    />
                    <div className="wrap-btn">
                        <Button
                            className="btn-reset"
                            onClick={_handleReset}
                            text="Reset"
                        />
                        <Button
                            className="btn-filter-range"
                            iconBtn={<IcFilterWhite />}
                            onClick={_handleFilter}
                            text="Lọc dữ liệu"
                        />
                    </div>
                </div>
                <InputDropdownRoom
                    handleGetValueDropdown={_handleGetValueRoomKiosk}
                    listDropDown={Object.values(listTypeCabinet)}
                    objChoice={filter.lockerType}
                    required={false}
                />
                <InputDropdownStatusRange
                    handleGetValueDropdown={_handleGetValueStatus}
                    listDropDown={Object.values(listUseStatus)}
                    objChoice={filter.status}
                />
                {/* <InputDropdownStatusDoor
                    handleGetValueDropdown={_handleGetValueStatusDoor}
                    listDropDown={Object.values(dataListRange)}
                    objChoice={filter.statusDoor}
                /> */}

            </WrapPopupFilterCabinet>
        </OverlayFullScreen>
  )
}

export default PopupFilterCabinet