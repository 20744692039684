import styled from 'styled-components'

const WrapDatePickerInput = styled.div`
    .react-datepicker__day-names{
        
        .react-datepicker__day-name{
            color: #33ADFF;
        };
    }
    .react-datepicker__header {
        background-color: #fff;
        border-bottom: none;
    }
    .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
        border-color: #191919;
        border-style: solid;
        border-width: 1.5px 1.5px 0 0;
        content: "";
        display: block;
        height: 5px;
        position: absolute;
        top: 9px;
        width: 5px;
    }
    .react-datepicker-popper{
        /* transform: translate(29px, 150px)!important; */
        padding-top: 0;
    }
`
export {
    WrapDatePickerInput
}