import React, { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// styles
import { WrapIndentifyKiosk } from "./index.styles";
import { OverlayFullScreen } from "../../../../../../MainStyles/main";

//component
import Button from "../../../../../../Control/Button";
import InputIdKiosk from "../FormOptionKiosk/InputIdKiosk";
import InputIndentifyCodeKiosk from "./InputIndentifyCodeKiosk";

// image
import IcClose from "../../../../../../../assets/images/IcClose";
import IcGetCodeIndentify from "../../../../../../../assets/images/IcGetCodeIndentify";
import { CONNECTED, DISCONNECTED } from "../../../../../../../data/listKioskStatus";
import IcInputWifiOff from "../../../../../../../assets/images/IcInputWifiOff";
import IcInputWifiBlue from "../../../../../../../assets/images/IcInputWifiBlue";

//action
import { getIdentifyCode } from "../../../../../../../redux/kiosk/action";


const PopupIndentifyKiosk = ({ onClickIconClose, dataKiosk }) => {
  const dispatch = useDispatch();
  let [minute, setMinute] = useState(4);
  let [second, setSecond] = useState(59);
  const {identifierCode}= useSelector(state=>state.kioskReducer);
  const [codeIdentify, setCodeIdentify] = useState('')
  const [textEndTime, setTextEndTime] = useState(false)
  
  useEffect(()=>{
    setCodeIdentify(identifierCode)
  },[identifierCode])

  const _handleGetCode = () => {
    setTextEndTime(false)
    const callbackSuccess = () => {
      let startCount = () => {
        let timeout = setTimeout(() => {
          setSecond(second--);
          startCount();
        }, 1000);
        if (second === -1) {
          setMinute((minute -= 1));
          setSecond((second += 60));
        }
        if (minute === -1) {
          clearTimeout(timeout);
          setCodeIdentify("")
          setTextEndTime(true)
          setMinute(4)
          setSecond(59)
        }
      };
      startCount();
    };

    const callbackError = () => {};

    dispatch(getIdentifyCode({kioskId:dataKiosk?.kioskId},callbackSuccess,callbackError))
  };

  return (
    <OverlayFullScreen onClick={(e) => e.stopPropagation()}>
      <div className="wrap-inner-screen">
        <WrapIndentifyKiosk>
          <IcClose className="icon-close" onClick={onClickIconClose} />
          <div className="title">Định danh Kiosk</div>
          <div className="description">
            <div>Trạng thái định danh Kiosk</div>
            <div className="description-item">
              {dataKiosk?.status === DISCONNECTED && (
                <>
                  <IcInputWifiOff className="wifi-item" />
                  <p>Chưa kết nối</p>
                </>
              )}
              {dataKiosk?.status === CONNECTED && (
                <>
                  <IcInputWifiBlue className="wifi-item" />
                  <p>Đã kết nối</p>
                </>
              )}
            </div>
          </div>
          <InputIdKiosk
            value={dataKiosk?.kioskId}
            isDisable={true}
            isRequired={false}
          />
          <InputIndentifyCodeKiosk
            value={codeIdentify}
          />
          {codeIdentify && (
            <div className="time-count">
              <span>Hiệu lực mã định danh </span>
              <span style={{ color: "#A31E23" }}>{minute}:</span>
              <span style={{ color: "#A31E23" }}>
                {second <= 9 ? `0${second}` : second}
              </span>
            </div>
          )}
          {textEndTime && (
            <div className="time-count">
              <span>Mã hết hiệu lực</span>
            </div>
          )}
          <div className="btn-container">
            <Button
              isDisable={codeIdentify ? true : false}
              text="Lấy mã định danh"
              iconBtn={<IcGetCodeIndentify />}
              className={codeIdentify ? "btn-code-disabled" : "btn-edit"}
              onClick={_handleGetCode}
            />
          </div>
        </WrapIndentifyKiosk>
      </div>
    </OverlayFullScreen>
  );
};

export default PopupIndentifyKiosk;
