import React, { useState } from 'react'
import IcSaveWhite from '../../../../../../../assets/images/IcSaveWhite'
import { updateKioskAction } from '../../../../../../../redux/kiosk/action';
import FormOptionKiosk from '../FormOptionKiosk'

export default function PopupEditKiosk({ onClickIconClose, dataKioskEdit }) {
  const [objLstImg, setObjLstImg] = useState({});
  const configPhotoList = { ...dataKioskEdit?.photoList?.map((item, i)=> {return {key: i, url: item, isUploaded: true}}) }
  const [objLstUrlImg, setObjLstUrlImg] = useState(configPhotoList);
  const apiEditKiosk = updateKioskAction

  return (
    <FormOptionKiosk
      onClickIconClose={onClickIconClose}
      titleOption="Chỉnh sửa Kiosk"
      dataKioskEdit={dataKioskEdit}
      isDisable={true}
      textBtn="Lưu"
      objLstImg={objLstImg}
      setObjLstImg={setObjLstImg}
      objLstUrlImg={objLstUrlImg}
      setObjLstUrlImg={setObjLstUrlImg}
      iconBtn={<IcSaveWhite />}
      apiHandleUpdate={apiEditKiosk}
    />
  )
}
