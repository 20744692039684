import styled from "styled-components";
import { WrapPopupFilter } from "../../../PopupFilterKiosk/index.style";

const WrapPopupHistoryKiosk = styled(WrapPopupFilter)`
    .show-right-popup{
        height: calc(100% - 50px);
        box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1), 5px 5px 40px rgba(174, 174, 192, 0.2);
    }

    .wrap-btn{
        display: flex;
        gap: 10px;
        .btn-reset{
            height: 36px;
        }
        .btn-filter-range{
            height: 36px;
        }
    }

    .select-filter{
        display: flex;
        flex-direction: column;
        .title{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 15px;
            color: #191919;
            padding-bottom: 8px;
        }
    }

`

export {
    WrapPopupHistoryKiosk
}