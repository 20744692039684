import React from 'react'

export default function IcNoData(props) {
  return (
    <svg {...props} width={140} height={140} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx={70} cy={70} r={70} fill="#BDBDBD" />
      <path d="M48.1665 47.0333L36.6665 69.9999V89.9999C36.6665 91.768 37.3689 93.4637 38.6191 94.714C39.8694 95.9642 41.5651 96.6666 43.3332 96.6666H96.6665C98.4346 96.6666 100.13 95.9642 101.381 94.714C102.631 93.4637 103.333 91.768 103.333 89.9999V69.9999L91.8332 47.0333C91.2812 45.9225 90.4304 44.9878 89.3764 44.3342C88.3223 43.6805 87.1068 43.3339 85.8665 43.3333H54.1332C52.8929 43.3339 51.6774 43.6805 50.6233 44.3342C49.5693 44.9878 48.7184 45.9225 48.1665 47.0333Z" fill="#BDBDBD" stroke="#BDBDBD" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M103.333 70H83.3332L76.6665 80H63.3332L56.6665 70H36.6665" stroke="#EFEFEF" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
