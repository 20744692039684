import styled from "styled-components";

const TablelInfoKiosk = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 360px 1fr auto;
  color: #191919;
  .detail-content-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .title {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      padding-bottom: 20px;
    }
    .detail-value {
      display: grid;
      grid-template-columns: 150px auto;
      margin-bottom: 10px;
      p {
        font-size: 13px;
      }

      .detail-status {
        display: flex;
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 600;
        font-size: 13.5px;
        line-height: 18px;
        letter-spacing: -0.01em;
        .title-connect {
          color: #3d8b4c;
        }
        .title-disconnect {
          color: #828282;
        }
      }

      .bold {
        font-weight: 600;
      }
    }
  }
  .edit-btn {
    width: 110px;
    height: 36px;
    border: 1px solid #3d8b4c;
    background-color: #fff;
    color: #3d8b4c;
  }
`;
export { TablelInfoKiosk };
