import React, { useState } from 'react'
import IcAddItem from '../../../../../../../assets/images/IcAddItem'
import { addKioskAction } from '../../../../../../../redux/kiosk/action';
import FormOptionKiosk from '../FormOptionKiosk'

export default function PopupAddKiosk({ onClickIconClose }) {

  const [objLstImg, setObjLstImg] = useState({});
  const [objLstUrlImg, setObjLstUrlImg] = useState({});
  const apiAddKiosk = addKioskAction
  
  return (
    <FormOptionKiosk
      textBtn="Thêm mới"
      iconBtn={<IcAddItem />}
      objLstImg={objLstImg}
      setObjLstImg={setObjLstImg}
      objLstUrlImg={objLstUrlImg}
      setObjLstUrlImg={setObjLstUrlImg}
      onClickIconClose={onClickIconClose}
      titleOption="Thêm mới Kiosk"
      apiHandleAdd={apiAddKiosk}
    />
  )
}
