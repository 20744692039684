const converTime = (newTime , DateTime , formart) => {
    let time = Date.parse(DateTime)
    let ms = (newTime - time)
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor(ms / 1000 / 60 / 60);
    const totalMinutes = minutes + (hours * 60)
    // console.log(totalMinutes)
    let humanized = ""
    if(formart === "phút"){
        humanized = totalMinutes + " phút"
    }else{
        humanized = pad(hours.toString(), 2) + " giờ " + pad(minutes.toString(), 2) + " phút"
    }
    return humanized;
}

function pad(numberString, size) {
    let padded = numberString;
    while (padded.length < size) padded = `0${padded}`;
    return padded;
 }

export default converTime