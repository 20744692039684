import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WrapControlAutoDoor } from "./index.style";

//img
import BgZoomOutAutoDoor from "../../../assets/images/img_zoomout_autodoor.png";

//component
import PopupControlAutoDoor from "./PopupControlDoor";
import { useOnClickOutside } from "../../../hook/useClickOutside";

//action
import { getListDevice } from "../../../redux/autoDoor/action";

export default function ControlAutoDoor() {
  let timer = null
  const dispatch = useDispatch()
  const { branchCode} = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
  }))
  const [isHover, setIsHover] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const ref = useRef();

  useEffect(() => {
    // Make the DIV element draggable:
    dragElement(document.getElementById("controlAuto"));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + "Door")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "Door").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
  });

  useEffect(()=>{
    dispatch(getListDevice({branchCode: branchCode}))
  },[])

  const handleRefesh = () => {
    dispatch(getListDevice({branchCode: branchCode}))
  }

  const handlePin = () => {
    setIsPin(pre=>!pre)
  }

  const _setIsHover = (isMouseEnter) => {
    if(timer !== null){
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(() => {
      setIsHover(isMouseEnter)
    }, 200);
  }

  // useOnClickOutside(ref, () => _setIsHover(false));

  return (
    <WrapControlAutoDoor id="controlAuto">
      <div id="controlAutoDoor">
        <div
          className={isHover || isPin ? "hiden-block" : "zoom-out-block"}
          onMouseEnter={() => _setIsHover(true)}
        >
          <img src={BgZoomOutAutoDoor} alt="citigym" />
        </div>
        <PopupControlAutoDoor refPopup={ref} isPin={isPin}
          className={isHover || isPin ? "popup-control" : ""} 
          handleRefesh={handleRefesh} 
          handlePin={handlePin}  
          handleMouseLeave={()=>_setIsHover(false)}
          handleMouseEnter= {()=>_setIsHover(true)}
        />
      </div>
    </WrapControlAutoDoor>
  );
}
