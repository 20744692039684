import styled from "styled-components";

const WrapTitlePage = styled.div`
    display: flex;
    width: 100%;
    height: 112px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    .hero-container{
        display: flex;
        width: calc(1280px - 160px);
        padding: 0 30px;
        align-items: center;
        justify-content: space-between;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #313131;

        .title-hero{
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #191919;
        }
    }
`

export {
    WrapTitlePage
}