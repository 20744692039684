import React, { useEffect, useState } from 'react'
import IcFacePrintDefault from '../../../../../../../assets/images/IcFacePrintDefault'
import { apiGetPhoto } from '../../../../../../../constant/host'
import { listHistoryMember } from '../../../../../../../data/listHistoryMember'
import { listHistoryStatus } from '../../../../../../../data/listHistoryStatus'
import { convertDateTime } from '../../../../../../../helper/convertDateTime'
import FaceZoomAvt from '../../../../../../Control/FaceZoomAvt'
import { WrapItem } from './index.styles'

const ItemHistoryKiosk = (props) => {
    const { className, item , refList} = props
    const [isErrImg, setIsErrImg] = useState(false)
    
    useEffect(() => {
        setIsErrImg(false)
    }, [item?.faceID])

    let textStatusAction = "";
    let textStatusUser = "";
    switch (item?.action) {
      case 1: {
        textStatusAction = "Mượn tủ";
        break;
      }
      case 2: {
        textStatusAction = "Trả tủ";
        break;
      }
      case 3: {
        textStatusAction = "Mở tủ tạm thời";
        break;
      }
      case 4: {
        textStatusAction = "Mở tất cả các tủ";
        break;
      }
      case 5: {
        textStatusAction = "Kiểm tra trạng thái tủ";
        break;
      }
    }

    switch (item?.userType) {
      case 1: {
        textStatusUser = "Hội viên";
        break;
      }
      case 2: {
        textStatusUser = "Hội viên VIP";
        break;
      }
      case 3: {
        textStatusUser = "Admin chi nhánh";
        break;
      }
      case 4: {
        textStatusUser = "Quản lý phòng";
        break;
      }
    }

  return (
    <WrapItem className={className}>
            <div>{item?.citiGymMemberCode || "-"}</div>
            <div className="wrap-avatar">
                {
                    isErrImg ?
                        <IcFacePrintDefault className="avatar" />
                        :
                        <FaceZoomAvt
                            // src={item?.faceID ? apiGetAvatar(item?.faceID) : ""}
                            url={apiGetPhoto(item?.avatarPhotoPath)}
                            className="avatar-zoom"
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                            refList={refList}
                        />
                }
            </div>
            <div title={item?.fullname}>{item?.fullname || "-"}</div>
            <div>{item?.userType ? textStatusUser : "-"}</div>
            <div>{item?.action ? textStatusAction : "-"}</div>
            <div>{item?.createAt ? convertDateTime(item?.createAt, "DD/MM/YYYY HH:mm") : ""}</div>
        </WrapItem>
  )
}

export default ItemHistoryKiosk