import React, { useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sha256 } from 'js-sha256';

//style
import { WrapLogin } from './index.styles'

//image
import LogoCityGymRed from '../../assets/images/LogoCityGymRed'
import bg_login from '../../assets/images/bg_login.png'
import IcInputUserError from '../../assets/images/IcInputUserError';
import IcInputUserActive from '../../assets/images/IcInputUserActive';
import IcInputUser from '../../assets/images/IcInputUser';

//constant
import { main } from '../../constant/routes';

//component
import Input from '../Control/Input';
import InputPassword from '../Control/InputPassword';
import Button from '../Control/Button';
import IcButtonLogin from '../../assets/images/IcButtonLogin';

//action
import { loginAction } from '../../redux/login/action';


const iconUser = {
  icon: IcInputUser,
  iconActive: IcInputUserActive,
  iconError: IcInputUserError,
}

export default function Login() {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  
  const [stateForm, setStateForm] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      username: "",
      password: "",
      error: "",
      errorSubmit: "",
      isSubmit: false
    }
  )

  const { loggedIn } = useSelector(state => ({
    loggedIn: state.login.loggedIn
  }))

  useEffect(() => {
    if (!loggedIn) return;
    navigate(`/${main}`)

  }, [loggedIn]);

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    setStateForm({
      [name]: value
    })
    setStateForm({errorSubmit: "", isSubmit: false})
  }

  const _handleValidationUser = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      callback("Thông tin không được để trống")
      setStateForm({isSubmit: true})
      return
    }
    // if (value.indexOf(' ') >= 0) {
    //   callback("Thông tin không được để trống")
    //   return
    // }
  }

  const _handleValidationPassword = (value, callback) => {
    if (!value?.trim() || 0 === value.length) {
      callback("Thông tin không được để trống")
      setStateForm({isSubmit: true})
    }
  }

  const SubmitFormSuccess = () => {
    
  }

  const SubmitFormError = () => { 
    setStateForm({errorSubmit: "Thông tin đăng nhập không chính xác"})
    
  }

  const submitForm = (e) => {
    if (!stateForm.username && !stateForm.password){
      setStateForm({error: "Thông tin không được để trống"})
      return
    }
    if (stateForm.isSubmit) return
    const dataLogin = {
      username: stateForm.username,
      pwd: btoa(sha256(stateForm.password)),
    };
    dispatch(loginAction(navigate, dataLogin, SubmitFormSuccess, SubmitFormError))
  }
  
  return (
    <WrapLogin
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          submitForm()
        }
      }}>
      <div className="wrap-inner-login">
        <div className="login-form">
          <LogoCityGymRed className="icon-logo" />
          <p className="title">Chào mừng bạn quay trở lại</p>
          <p className="note">Đăng nhập tài khoản</p>
          <div className="form" autoComplete="off">
            <Input
              error={stateForm.error}
              errorLogin = {true}
              className={stateForm.error ? 'input-username' : ""}
              placeholder="Username"
              autoComplete="username"
              inputType="TEXT"
              name="username"
              value={stateForm.username}
              required
              onChange={handleChangeValue}
              iconInput={iconUser}
              handleValidationInput={_handleValidationUser}
            />
                  
            <InputPassword
              error={stateForm.error}
              autoComplete="new-password"
              placeholder="Mật khẩu"
              type="password"
              inputType="PWD"
              name="password"
              required
              value={stateForm.password}
              onChange={handleChangeValue}
              handleValidationPassword={_handleValidationPassword}
            />
            { stateForm?.errorSubmit && (
              <div className='error-submit'>{stateForm.errorSubmit}</div>
            )}
            <Button
              onClick={submitForm}
              text="Đăng nhập"
              iconBtn={<IcButtonLogin />}
              className="btn-login"
            />
          </div>
        </div>
        <div className="bg_login">
          <img src={bg_login} alt="bg_login" />
        </div>
      </div>
    </WrapLogin>
  )
}
