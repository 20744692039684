import React from "react";

export default function IcNoResult(props) {
  return (
    <svg
      {...props}
      width={140}
      height={140}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx={70} cy={70} r={70} fill="#BDBDBD" />
      <path
        d="M66.6667 93.3333C81.3943 93.3333 93.3333 81.3943 93.3333 66.6667C93.3333 51.9391 81.3943 40 66.6667 40C51.9391 40 40 51.9391 40 66.6667C40 81.3943 51.9391 93.3333 66.6667 93.3333Z"
        stroke="#BDBDBD"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 100L85.5 85.5"
        stroke="#BDBDBD"
        strokeWidth={6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
