import styled from "styled-components";


const WrapFaceItem = styled.div`
    width: 1280px;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    .show-face{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        img{
            width: 480px;
            height: 480px;
            border-radius: 3px;
        }
    }
    .face-icon{
        display: flex;
        justify-content: flex-end;
        margin: 0 140px 15px 0;
        .btn-close{
            cursor: pointer;
        }
    }
`

export {
    WrapFaceItem
}