import React from 'react'

const IcBtnUpdate = ({...props}) => {
    return (
        <svg {...props} width={15} height={14} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5833 12.25H3.41667C3.10725 12.25 2.8105 12.1271 2.59171 11.9083C2.37292 11.6895 2.25 11.3928 2.25 11.0833V2.91667C2.25 2.60725 2.37292 2.3105 2.59171 2.09171C2.8105 1.87292 3.10725 1.75 3.41667 1.75H9.83333L12.75 4.66667V11.0833C12.75 11.3928 12.6271 11.6895 12.4083 11.9083C12.1895 12.1271 11.8928 12.25 11.5833 12.25Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4166 12.25V7.58334H4.58325V12.25" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.58325 1.75V4.66667H9.24992" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcBtnUpdate
