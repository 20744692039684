import styled from 'styled-components'

import { WrapPageItem } from '../../../MainStyles/main'

const WrapCabinetRangeManage = styled(WrapPageItem)`
    background: #EFEFEF;
    padding: 30px;
`;

export{
    WrapCabinetRangeManage
}