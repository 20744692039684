import styled from 'styled-components'

import { WrapCreateAndEditPopup } from '../../../../../../MainStyles/main'

const WrapIndentifyKiosk = styled(WrapCreateAndEditPopup)`
    width: 400px;
    box-sizing: border-box;
    .title{
        margin-bottom: 12px;
    }
    .icon-close{
        top: 24px;
        right: 24px;
    }
    .description{
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        .description-item{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            .wifi-item{
                margin: 0px 4px;
            }
        }
    }
    .time-count{
        margin: -12px 0 20px 0;
    }
    .btn-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        .btn-edit, .btn-code-disabled{
            width: 100%;
            // margin-top:36px;
        }
    }
`;

export {
    WrapIndentifyKiosk
}