const main = "main"
const newFaceBiometric = "new-face-biometric"
const faceBiometric = "face-biometric"
const kioskManage = "kiosk-manage"
const cabinetRangeManage = "cabinet-range-manage"
const advertismentManage = "advertisment-manage"
const kioskDetails = (kioskId=':kioskId') => `kiosk-details/${kioskId}`
const cabinetRangeDetails = (cabinetRangeId=':cabinetRangeId') => `cabinet-range-details/${cabinetRangeId}`
const memberDetails = (salesforceID=':salesforceID') => salesforceID


export {
    main,
    newFaceBiometric,
    faceBiometric,
    kioskManage,
    cabinetRangeManage,
    advertismentManage,
    kioskDetails,
    cabinetRangeDetails,
    memberDetails
}