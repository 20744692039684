import React from 'react'

//images
import IcNameCabinetRangeBlue from '../../../../../../../../assets/images/IcNameCabinetRangeBlue'
import IcNameCabinetRangeGray from '../../../../../../../../assets/images/IcNameCabinetRangeGray'

//component
import Input from '../../../../../../../Control/Input'

const iconStore = {
    icon: IcNameCabinetRangeGray,
    iconActive: IcNameCabinetRangeBlue,
    iconError: IcNameCabinetRangeBlue,
}

export default function InputNameCabinetRange({value, handleGetValueInput, handleValidationInput, error}) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleGetValueInput && handleGetValueInput(value)
    }
  return (
    <Input
        error={error}
        heightInput="63px"
        title="Tên dãy tủ"
        iconInput={iconStore}
        inputType = "TEXT"
        value = {value}
        isRequired = {true}
        required
        onChange = {_handleChangeValue}
        placeholder="Tên dãy tủ"
        handleValidationInput={handleValidationInput}
    />
  )
}
