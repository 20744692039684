import React, { useRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// component
import DragAndDrop from '../DragAndDrop'
import IcCloseWhite from '../../../assets/images/IcCLoseWhite'
import IcUpload from '../../../assets/images/IcUpload'

// styles
import { DragAndDropArea } from './index.styles'


// const MOCK_FACE = "https://picsum.photos/100/100"

const WIDTH = 1125
const HEIGHT = 1350

export const IMAGE_INVALID_DIMENSION = 'IMAGE_INVALID_DIMENSION'

const DragAndDropImage = ({
    handleDropFiles
}) => {

    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const imageUrl = useRef(null)

    const _handleClickUploadFile = () => {
        // if(process.env.NODE_ENV !== "development") return;
        inputRef.current.click()
    }

    // const _checkDimension = (file) => new Promise((resolve, reject) => {
    //     const image = new Image()
    //     image.onload = function() {
    //         const { width, height } = this
    //         const isNotMatchRatio = width/height !== WIDTH/HEIGHT
    //         if(isNotMatchRatio || width < WIDTH) {
    //             dispatch({
    //                 type: 'IMAGE_ERR',
    //                 payload: {
    //                     code: IMAGE_INVALID_DIMENSION
    //                 }
    //             })
    //             URL.revokeObjectURL(imageUrl.current)
    //             resolve(false)
    //             return;
    //         }

    //         URL.revokeObjectURL(imageUrl.current)
    //         resolve(true)
    //     }
    //     imageUrl.current = URL.createObjectURL(file)
    //     image.src = imageUrl.current
    // })    

    const _handleDropFiles = async (files) => {
        if (files.length !== 1) {
            inputRef.current.value = '';
            return;
        }

        const file = files[0]
        const { type, size } = file

        // check format
        const validFormat = ["image/png", "image/jpg", "image/jpeg"]
        if (!validFormat.includes(type)) {
            inputRef.current.value = '';
            return
        }

        // check dimension
        // const isValid = await _checkDimension(file)
        // if(!isValid) {
        //     return;
        // }

        handleDropFiles(file)
        inputRef.current.value = '';
    }

    useEffect(() => {
        const inputFile = inputRef.current = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = (e) => {
            _handleDropFiles(e.target.files)
        }
    }, [])

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(imageUrl.current)
        }
    }, []);

    return (
        <DragAndDrop handleDropFiles={_handleDropFiles}>
            <DragAndDropArea onClick={_handleClickUploadFile}>
                <IcUpload className="ic-upload" />
                <p>Image size max 3Mb</p>
            </DragAndDropArea>
        </DragAndDrop>
    )
}

export default DragAndDropImage
