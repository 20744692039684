import React, { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// action
import { popNotify } from '../../../redux/notifies/action'
import { logoutAction } from '../../../redux/login/action'

// constant
import { AUTHENTICATED_ERR } from '../../../redux/notifies/types'

// styles
import { WrapNotify } from './index.styles'

// component
import Toast from './Toast'
// import Toast1107 from './Toast1107.js'

// constant
import { toastTypes } from './Toast/index.styles'

// helpper
import checkIsSuccessAction from '../../../helper/checkIsSuccessAction'
import { CITYGYM_ADMIN_LOGIN } from '../../../constant/keys'
import { LOGOUT } from '../../../redux/login/types'

const messages = {
    UPDATE_FACE_SUCCESS: 'Cập nhật thành công',
    // CLIENT_ENROLL_SUCCESS: "Enroll Successful",
    NETWORK_ERR: "Mất kết nối với hệ thống",
    SYSTEM_ERR: "Đã xảy ra lỗi hệ thống. Vui lòng thử lại!",
    ADD_KIOSK_SUCCESS: "Thêm Kiosk thành công",
    UPDATE_KIOSK_SUCCESS: "Cập nhật Kiosk thành công",
    DEL_KIOSK_SUCCESS: "Xóa Kiosk thành công",
    ADD_CLUSTER_SUCCESS: "Thêm dãy tủ thành công",
    UPDATE_CLUSTER_SUCCESS: "Cập nhật dãy tủ thành công",
    DEL_CLUSTER_SUCCESS: "Xóa dãy tủ thành công",
    ADD_LOCKER_SUCCESS: "Thêm hộc tủ thành công",
    UPDATE_LOCKER_SUCCESS: "Cập nhật hộc tủ thành công",
    DEL_LOCKER_SUCCESS: "Xóa hộc tủ thành công",
    GET_INFO_BY_DOC_ID_ERR: "Lấy thông tin dãy tủ thất bại. Vui lòng thử lại!",
    CHANGE_PASSWORD_SUCCESS: "Cập nhật mật khẩu thành công",
    CHANGE_PASSWORD_ERR: "Cập nhật mật khẩu thất bại",
    DUPLICATED_PINCODE_ERR: "Mã PIN bị trùng lặp",
    19: "Thông tin đã tồn tại. Vui lòng nhập lại",
    30: "Thông tin không được để trống",
    34: "Thông tin không được để trống",
    35: "Thông tin không được để trống",
    40: "Thông tin không được để trống",
    54: "Thông tin không được để trống",
    59: "Thông tin không được để trống",
    67: "Thông tin không được để trống",
    80: "Thông tin đã tồn tại. Vui lòng nhập lại",
    81: "Thông tin đã tồn tại. Vui lòng nhập lại",
    82: "Thông tin đã tồn tại. Vui lòng nhập lại",
    86: "Thông tin đã tồn tại. Vui lòng nhập lại",
    88: "Khuôn mặt không trùng khớp. Vui lòng thử lại",
    
    // member
    UPDATE_FACE_MEMBER_ERR: "Cập nhật thất bại!",
    DEVICE_ENROLLING_ERR: "Thiết bị đang được sử dụng. Vui lòng thử lại sau !",
    ACTION_ENROLLING_EXISTED_ERR: "Thao tác Cập nhật STH bị trùng lặp. Vui lòng refresh và tiến hành lại !",
    UPDATE_FACE_ERR: "Cập nhật khuôn mặt thất bại",
    UPDATE_FACE_TIMEOUT_ERR: "Quá trình lấy thông tin STH thất bại. Vui lòng thử lại!",
    DEVICE_ERR: "Lỗi thiết bị. Vui lòng kiểm tra lại",
    OVER_SIZE_ERR: "Dung lượng file vượt quá quy định (3Mb)",

    //autoDoor
    GET_LIST_DEVICE_ERR: "Lấy danh sách thiết bị cửa thất bại",
    OPEN_DOOR_ERR: "Mở cửa thất bại, vui lòng kiểm tra lại hệ thống"
}

const Notify = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { notifies } = useSelector(state => ({
        notifies: state.notifies
    }))
    const [currNotify, setCurrNotify] = useState(null);

    const _handleClosePopup = () => {
        setCurrNotify(null)
        dispatch(popNotify(currNotify.type))
    }

    useEffect(() => {
        if(currNotify) return;
        setCurrNotify(Object.values(notifies)[0])
    }, [notifies]);

    useEffect(() => {
        if(!currNotify) return;
        if(currNotify.type !== AUTHENTICATED_ERR) return;
        //update 26/8 fix login nhiều chỗ khác nhau 
        localStorage.removeItem(CITYGYM_ADMIN_LOGIN)
        dispatch({
            type: LOGOUT
        })
        navigate('/')
        // dispatch(logoutAction())
    }, [currNotify]);
    const notify = useMemo(() => {
        if(!currNotify) return null;

        const type = (() => {
            const isSuccess = /.*_SUCCESS/.test(currNotify.type)
            if(isSuccess)
                return toastTypes.SUCCESS
            
            // const isError = /.*_ERR/.test(currNotify.type)
            // if(isError) 
            return toastTypes.ERROR
        })()

        const code = currNotify?.payload?.code
       
        const message = (() => {
            if(checkIsSuccessAction(code)){
                return messages[currNotify.type]
            }else{
                return messages[code] ||  messages[currNotify.type] || `Đã có lỗi xảy ra, code ${code}`
            }
        })()
        if(!message) return null
        return <Toast type={type} onClose={_handleClosePopup}>{message}</Toast>
    }, [currNotify])

    return (
        <WrapNotify>
            {notify}
        </WrapNotify>
    )
}

export default Notify
