import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";

// css
import { WrapBreadcrumbAndTool, WrapBreadcrumb, WrapButton } from "./index.styles";

// images
import IcArrowRightBlack from '../../../assets/images/IcArrowRightBlack'
import IcFilterRed from "../../../assets/images/IcFilterRed";

// routes
import {
  main,
  newFaceBiometric,
  faceBiometric,
  kioskManage,
  cabinetRangeManage,
  advertismentManage,
  cabinetRangeDetails,
  kioskDetails,
  memberDetails
} from "../../../constant/routes";

//component
import Portal from "../../Control/Portal";
import InputSearch from "../../Control/InputSearch";
import Button from "../../Control/Button";
import PopupFilterFaceBiometric from "../Page/FaceBiometric/PopupFilterFaceBiometric";
import IcPlusWhite from "../../../assets/images/IcPlusWhite";
import PopupAddKiosk from "../Page/KioskManage/Listing/OptionKiosk/PopupAddKiosk";
import PopupFilterKiosk from "../Page/KioskManage/PopupFilterKiosk";
import PopupAddCabinetRange from "../Page/CabinetRangeManage/Listing/OptionCabinetRange/PopupAddCabinetRange";
import PopupFilterCabinetRange from "../Page/CabinetRangeManage/PopupFilterCabinetRange";

//action
import { getUpdateMemberList, clearUpdateMemberList } from "../../../redux/member/action";
import { getListKioskAction } from "../../../redux/kiosk/action";
import { CITYGYM_ADMIN_LOGIN } from "../../../constant/keys";
import { getListClusterAction } from "../../../redux/cluster/action";

const BreadcrumbItem = ({ arrPath }) => {
  return (
    <WrapBreadcrumb>
      {arrPath.map((pathItem, index) => (
        <React.Fragment key={pathItem.value}>
          <p
            onClick={pathItem.onClick}
            style={pathItem.onClick ? { cursor: "pointer" } : {}}
            className="title-breadcrumb"
          >
            {pathItem.value}
          </p>
          {arrPath.length - 1 !== index && (
            <IcArrowRightBlack className="icon-text" />
          )}
        </React.Fragment>
      ))}
    </WrapBreadcrumb>
  );
};

const NewFaceBioBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Dữ liệu sinh trắc học khuôn mặt"
        }
      ]} />
    </>
  );
};

const FaceBioBread = () => {

  const dispatch = useDispatch()
  const [valueSearch, setValueSearch] = useState("")
  const [isShowPopupFilter, setIsShowPopupFilter] = useState(false);
  const { branchCode, totalUpdate, isSearch, filterInfo, isSearching} = useSelector((state) => ({
    branchCode: state.login.dataLogin.branchCode,
    totalUpdate: state.memberReducer.totalUpdate,
    isSearch: state.memberReducer.isSearch,
    filterInfo: state.memberReducer.filterInfo,
    isSearching: state.memberReducer.isSearching

  }))

  const _handleSearch = (valueSearch) => {
    dispatch(clearUpdateMemberList())
    dispatch(getUpdateMemberList({
      branchCode: branchCode,
      filterInfo: {
        startTime: filterInfo?.startTime,
        endTime: filterInfo?.endTime,
        cursor: "",
        limit: 15,
        quickSearchData: valueSearch
      }
    },false ))
  }

  useEffect(()=>{
    if (!isSearch){
      setValueSearch("")
    }
  },[isSearch])

  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Dữ liệu STH"
        }
      ]} />
      <InputSearch
        value={valueSearch}
        setValue={setValueSearch}
        isOnChange={true}
        type="text"
        placeholder="Nhập mã hội viên, tên hội viên..."
        onChange={_handleSearch}
      />
      <WrapButton>
        <Button
          text="Bộ lọc"
          iconBtn={<IcFilterRed />}
          className="button-filter"
          onClick={() => setIsShowPopupFilter(true)}
        />
      </WrapButton>
      {
        isShowPopupFilter &&
        <Portal>
          <PopupFilterFaceBiometric
            onClose={() => setIsShowPopupFilter(false)}
            valueSearch={valueSearch}
          />
        </Portal>
      }
    </>
  );
};

const KioskManageBread = () => {
  const dispatch = useDispatch()
  const [valueSearch, setValueSearch] = useState("")
  const [isShowPopupFilter, setIsShowPopupFilter] = useState(false);
  const [isShowModalAddKiosk, setIsShowModalAddKiosk] = useState(false)
  const { total, isSearch, condFilters, isSearching } = useSelector(state => ({
    total: state.kioskReducer.total,
    isSearch: state.kioskReducer.isSearch,
    condFilters: state.kioskReducer.condFilters,
    isSearching: state.kioskReducer.isSearching
  }))
  
  const dataLogin = JSON.parse(localStorage.getItem(CITYGYM_ADMIN_LOGIN))
  const { branchCode } = dataLogin

  const _handleSearch = (valueSearch) => {
    dispatch(getListKioskAction({
      branchCode: branchCode,
      filterFrom: "",
      filterSize: total ? total : 100,
      quickSearchData: valueSearch,
      roomType: condFilters?.roomType,
      status: condFilters?.status
    }))
  } 
  
  useEffect(()=>{
    setValueSearch(condFilters.quickSearchData)
  },[])

  useEffect(()=>{
    let timer = setTimeout(()=>{
      if (isSearching && !valueSearch){
        _handleSearch(valueSearch)
        return
      }
      
      dispatch(getListKioskAction({  
        branchCode: branchCode,
        filterFrom: "",
        filterSize: total ? total : 100,
        quickSearchData: valueSearch ? valueSearch : condFilters?.quickSearchData,
        roomType: condFilters?.roomType,
        status: condFilters?.status
      }))
    },500)
    
    return ()=>{
      clearTimeout(timer)
    }
    
  },[valueSearch])

  useEffect(()=>{
    if (!isSearch){
      setValueSearch("")
    }
  },[isSearch])

  const _handleClickAddKiosk = () => {
    setIsShowModalAddKiosk(pre => !pre)
  }

  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Danh sách Kiosk"
        }
      ]} />
      <InputSearch
        value={valueSearch}
        setValue={setValueSearch}
        type="text"
        placeholder="Nhập tên Kiosk, mã Kiosk..."
        onChange={_handleSearch}
      />
      <WrapButton>
        <Button
          text="Bộ lọc"
          iconBtn={<IcFilterRed />}
          className="button-filter"
          onClick={() => setIsShowPopupFilter(true)}
        />
        <Button className="btn-add" iconBtn={<IcPlusWhite />} onClick={_handleClickAddKiosk} text='Thêm mới' />
      </WrapButton>
      {
        isShowModalAddKiosk &&
          <Portal>
            <PopupAddKiosk onClickIconClose={()=> setIsShowModalAddKiosk(false)} />
          </Portal>
      }
      {
        isShowPopupFilter &&
        <Portal>
          <PopupFilterKiosk
            onClose={() => setIsShowPopupFilter(false)}
            valueSearch={valueSearch}
          />
        </Portal>
      }
    </>
  );
};

const KioskManageDetailBread = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Danh sách Kiosk",
            onClick: () => {
              navigate(`/${main}/${kioskManage}`)
            }
          },
          {
            value: "Chi tiết Kiosk",
          },
        ]}
      />
    </>
  );
};

const CabinetRangeManageBread = () => {
  const dispatch = useDispatch()
  const [valueSearch, setValueSearch] = useState("")
  const [isShowPopupFilter, setIsShowPopupFilter] = useState(false);
  const [isShowModalAdd, setIsShowModalAdd] = useState(false)
  const { totalClusters, isSearch, condFilters, isSearching} = useSelector(state => ({
    totalClusters: state.clusterReducer.totalClusters,
    isSearch: state.clusterReducer.isSearch,
    condFilters: state.clusterReducer.condFilters,
    isSearching: state.clusterReducer.isSearching
  }))

  const dataLogin = JSON.parse(localStorage.getItem(CITYGYM_ADMIN_LOGIN))
  const { branchCode } = dataLogin

  const _handleSearch = (valueSearch) => {
    dispatch(getListClusterAction({
      branchCode:branchCode, 
      filterFrom: "",
      filterSize: totalClusters ? totalClusters : 100,
      quickSearchData: valueSearch,
      roomType: condFilters?.roomType,
      kioskName: condFilters?.kioskName
    }))
  }

  useEffect(()=>{
    setValueSearch(condFilters.quickSearchData)
  },[])

  useEffect(()=>{
    let timer = setTimeout(()=>{
      if (isSearching && !valueSearch){
        _handleSearch(valueSearch)
        return
      }
     
      dispatch(getListClusterAction({
        branchCode:branchCode, 
        filterFrom: "",
        filterSize: totalClusters ? totalClusters : 100,
        quickSearchData: valueSearch ? valueSearch : condFilters?.quickSearchData,
        roomType: condFilters?.roomType,
        kioskName: condFilters?.kioskName
      }))
    },500)
    
    return ()=>{
      clearTimeout(timer)
    }
  },[valueSearch])

  useEffect(()=>{
    if (!isSearch){
      setValueSearch("")
    }
  },[isSearch])

  const _handleClickAddCabinetRange = () => {
    setIsShowModalAdd(pre => !pre)
  }
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Danh sách dãy tủ"
        }
      ]} />
      <InputSearch
        value={valueSearch}
        setValue={setValueSearch}
        type="text"
        placeholder="Nhập tên dãy tủ, mã dãy tủ..."
        onChange={_handleSearch}
      />
      <WrapButton>
        <Button
          text="Bộ lọc"
          iconBtn={<IcFilterRed />}
          className="button-filter"
          onClick={() => setIsShowPopupFilter(true)}
        />
        <Button className="btn-add" iconBtn={<IcPlusWhite />} onClick={_handleClickAddCabinetRange} text='Thêm mới' />
      </WrapButton>
      {
        isShowModalAdd &&
          <Portal>
            <PopupAddCabinetRange onClickIconClose={()=> setIsShowModalAdd(false)} />
          </Portal>
      }
      {
        isShowPopupFilter &&
        <Portal>
          <PopupFilterCabinetRange
            onClose={() => setIsShowPopupFilter(false)}
            valueSearch={valueSearch}
          />
        </Portal>
      }
    </>
  );
};

const CabinetRangeDetailBread = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Danh sách dãy tủ",
            onClick: () => {
              navigate(`/${main}/${cabinetRangeManage}`)
            }
          },
          {
            value: "Chi tiết dãy tủ",
          },
        ]}
      />
    </>
  );
};
const AdvertismentManageBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Danh sách quảng cáo"
        }
      ]} />
    </>
  );
};

const Breadcrumb = () => {

  return (
    <WrapBreadcrumbAndTool>
      <div className="wrap-breadcrumb-and-tool">
        {useMatch(`/${main}/${newFaceBiometric}`) && <NewFaceBioBread />}
        {useMatch(`/${main}/${newFaceBiometric}/${memberDetails()}`) && <NewFaceBioBread />}
        {useMatch(`/${main}/${faceBiometric}`) && <FaceBioBread />}
        {useMatch(`/${main}/${kioskManage}`) && <KioskManageBread/>}
        {useMatch(`/${main}/${kioskManage}/${kioskDetails()}`) && <KioskManageDetailBread />}
        {useMatch(`/${main}/${cabinetRangeManage}`) && <CabinetRangeManageBread/>}
        {useMatch(`/${main}/${cabinetRangeManage}/${cabinetRangeDetails()}`) && <CabinetRangeDetailBread />}
        {useMatch(`/${main}/${advertismentManage}`) && <AdvertismentManageBread />}
      </div>
    </WrapBreadcrumbAndTool>
  );
};

export default Breadcrumb;
