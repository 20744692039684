import React from 'react'

const IcCheckWhite = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.17507 7.82499L4.52516 6.17509L3.7002 7.00004L6.17507 9.47489L10.2999 5.35011L9.47493 4.52515L6.17507 7.82499Z" fill="white" />
        </svg>
    )
}

export default IcCheckWhite
