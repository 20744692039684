import React from 'react'
import IcIdCabinetRangeBlue from '../../../../../../../../assets/images/IcIdCabinetRangeBlue'
import IcIdCabinetRangeGray from '../../../../../../../../assets/images/IcIdCabinetRangeGray'
import Input from '../../../../../../../Control/Input'

const iconStore = {
    icon: IcIdCabinetRangeGray,
    iconActive: IcIdCabinetRangeBlue,
    iconError: IcIdCabinetRangeBlue,
}

export default function InputIdCabinetRange({value, handleGetValueInput, isDisable, isRequired, handleValidationInput, error}) {

    const _handleChangeValue = (event) => {
      const { value } = event.target;
      handleGetValueInput && handleGetValueInput(value)
    }
  return (
    <Input
        error={error}
        heightInput="63px"
        title="ID Dãy tủ"
        iconInput={iconStore}
        inputType = "TEXT"
        value = {value}
        disabled = { isDisable }
        isRequired = {isRequired}
        required
        onChange = {_handleChangeValue}
        placeholder="ID Dãy tủ"
        handleValidationInput={handleValidationInput}
    />
  )
}
