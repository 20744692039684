import React from 'react'
import { useSelector } from 'react-redux';
import { useMatch } from "react-router-dom";

// routes
import {
  main,
  newFaceBiometric,
  faceBiometric,
  kioskManage,
  cabinetRangeManage,
  advertismentManage,
  kioskDetails,
  cabinetRangeDetails,
  memberDetails,
} from "../../../constant/routes";

// css
import { WrapTitlePage } from './index.styles'

const TitlePage = () => {
    const { total , totalupdateToDay, totalClusters, totalUpdate, branchName} = useSelector((state) => ({
        total : state.kioskReducer.total,
        totalupdateToDay: state.memberReducer.totalupdateToDay,
        totalClusters: state.clusterReducer.totalClusters,
        totalUpdate: state.memberReducer.totalUpdate,
        branchName: state.login.dataLogin.branchName,
    }))

    return (
        <WrapTitlePage>
            <div className='hero-container'>
                <p className="title-hero">Chi nhánh {branchName} </p>
                {
                    useMatch(`/${main}/${newFaceBiometric}`) && 
                    <p>{totalupdateToDay ? totalupdateToDay : 0} lượt đăng ký hôm nay</p>
                }
                {
                    useMatch(`/${main}/${newFaceBiometric}/${(memberDetails())}`) && 
                    <p>{totalupdateToDay ? totalupdateToDay : 0} lượt đăng ký hôm nay</p>
                }
                {
                    useMatch(`/${main}/${faceBiometric}`) && 
                    <p>{totalUpdate ? totalUpdate : 0} lượt cập nhật</p>
                }
                {
                    useMatch(`/${main}/${kioskManage}`) && 
                    <p>{total} Kiosk</p>
                }
                {
                    useMatch(`/${main}/${kioskManage}/${(kioskDetails())}`) && 
                    <p>{total} Kiosk</p>
                }
                {
                    useMatch(`/${main}/${cabinetRangeManage}`) && 
                    <p>{totalClusters} Dãy tủ</p>
                }
                {
                    useMatch(`/${main}/${cabinetRangeManage}/${cabinetRangeDetails()}`) && 
                    <p>{totalClusters} Dãy tủ</p>
                }
                {
                    useMatch(`/${main}/${advertismentManage}`) && 
                    <p></p>
                }
            </div>
        </WrapTitlePage>
    )
}

export default TitlePage