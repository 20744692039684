import React from 'react'
import OptionCabinet from '../OptionCabinet'
import LockerActiveTrue from "../../../../../../../assets/images/locker_active_true.png";
import LockerActiveFalse from "../../../../../../../assets/images/img_locker_free.png";
import LockerInActive from "../../../../../../../assets/images/locker_inactive.png";
import IcVipKiosk from '../../../../../../../assets/images/IcVipKiosk'
import { WrapItem } from './index.styles';
import { listRoom } from '../../../../../../../data/listRoom';
import converTime from '../../../../../../../helper/converTime';


const ItemDetailCabinet = (props) => {
    const {item, refList, onClick, dataCluster} = props
    let newTime = Date.now()
  
    return (
        <WrapItem onClick={onClick}>
            <div className="list-cabinet">
                <div className="item-cabinet">
                  <div className="item-cabinet-option">
                    <OptionCabinet dataCluster={dataCluster} dataCabinet={item} refList={refList} />
                  </div>
                  <div className="item-value">
                    <p>{item?.lockerID}</p>
                    <p className="value">{item.roomType ? listRoom[item.roomType]?.text : "-"}</p>
                  </div>
                  {
                            item.activeStatus === true ? (
                              <>
                                {
                                  item.useStatus === false ? (
                                      <img src={LockerActiveTrue} alt="citigym-locker" />
                                  ) : (
                                    <img src={LockerActiveFalse} alt="citigym-locker" />                 
                                  )
                                }
                              </>
                            ) : (
                              <img src={LockerInActive} alt="citigym-locker" />
                            )
                        }
                  <div className="item-check">
                    <p>{item.vipStatus === true ? "Tủ Vip" : "Tủ Thường"}</p>
                      {item.vipStatus === true ? <IcVipKiosk /> : ""}
                  </div>
                  <div className={`${item.useStatus === true ? "item-time" : ""}`}>
                    <p>{item?.useStatus === true ? converTime(newTime, item?.borrowTime, "giờ + phút") : ""}</p>
                  </div>
                </div>
              </div>
        </WrapItem>
    )
}

export default ItemDetailCabinet
