import React from 'react'
import IcNoData from '../../../assets/images/IcNoData'

//style
import * as styles from './index.style'
//image


const EmptyDataControl = (props) => {
    const { IconNonData, titleDes, labelDes } = props
    return (
        <styles.Bound>
            <div className='animation-container'>
                {
                    <div className="ic-no-data">
                        {IconNonData
                            ?
                            IconNonData
                            :
                            <IcNoData />}
                    </div>
                }
                {/* {
                    titleDes && <h1>{titleDes}</h1>
                } */}
                <p>{labelDes}</p>
            </div>
        </styles.Bound>
    )
}

export default React.memo(EmptyDataControl)
