import React from "react";

export default function IcSaveWhite(props) {
  return (
    <svg
      {...props}
      width={14}
      height={15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91667 12.75V8.08333H4.08333V12.75M4.08333 2.25V5.16667H8.75M11.0833 12.75H2.91667C2.60725 12.75 2.3105 12.6271 2.09171 12.4083C1.87292 12.1895 1.75 11.8928 1.75 11.5833V3.41667C1.75 3.10725 1.87292 2.8105 2.09171 2.59171C2.3105 2.37292 2.60725 2.25 2.91667 2.25H9.33333L12.25 5.16667V11.5833C12.25 11.8928 12.1271 12.1895 11.9083 12.4083C11.6895 12.6271 11.3928 12.75 11.0833 12.75Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
