import styled from 'styled-components'

const WrapPopupFilter = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    height: calc(100% - 60px);
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: -5px -5px 40px rgba(0, 0, 0, 0.1), 5px 5px 40px rgba(174, 174, 192, 0.2);
    padding: 27px 20px;

    @keyframes show-right-popup {
        from {right: -300px;}
        to {right: 0;}
    }
    @keyframes hide-right-popup {
        from {right: 0;}
        to {right: -300px;}
    }

    .wrap-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        .btn-close {
            cursor: pointer;
        }

        .wrap-btn {
            display: flex;
            align-items: center;

            .btn-reset {
                
            }
            .btn-filter {
                margin-left: 10px;
            }
        }
    }
    .select-filter{
        display: flex;
        flex-direction: column;
        .title{
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 15px;
            color: #191919;
            padding-bottom: 8px;
        }
        .input-dropdown{

        }
    }

`
export {
    WrapPopupFilter
}