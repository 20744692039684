import React from "react";

export default function IcCabinetFree(props) {
  return (
    <svg
      {...props}
      width={4}
      height={4}
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={2} cy={2} r={2} fill="url(#paint0_linear_3778_847)" />
      <defs>
        <linearGradient
          id="paint0_linear_3778_847"
          x1={2}
          y1={0}
          x2={2}
          y2={4}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41C728" />
          <stop offset={1} stopColor="#1FC000" />
        </linearGradient>
      </defs>
    </svg>
  );
}
