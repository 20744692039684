import { listKioskStatus } from "../../data/listKioskStatus";
import { listRoom } from "../../data/listRoom";
import * as types from "./types";

const initialState = {
  listKiosks: [],
  kioskInfo: {},
  condFilters: {
    roomType: "",
    status: "",
    filterFrom: "",
    filterSize: 9,
    quickSearchData: "",
    branchCode: ""
  },
  total: 0,
  // totalSearch: 0,
  identifierCode: "",
  isSearch: true,
  isSearching: false
  
};

const kioskReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_KIOSK:
      if(
        action.payload.condFilters.roomType !== initialState.condFilters.roomType || action.payload.condFilters.status !== initialState.condFilters.status ||
        action.payload.condFilters.quickSearchData !== initialState.condFilters.quickSearchData)
        {
        state.isSearching = true
      }
      return {
        ...state,
        ...action.payload,
        listKiosks: action.payload.isScroll
          ? [...state.listKiosks, ...action.payload.listKiosks]
          : action.payload.listKiosks,
        isSearch: true,
        type: action.type,
      };

    case types.RESET_LIST_KIOSK: {
      // return { ...initialState, type: action.type };
      return {
        ...state,
        listKiosks: [],
        condFilters: state.condFilters,
        identifierCode: "",
        isSearching: false,
        type: action.type
      }
    }

    case types.ADD_KIOSK_SUCCESS:
      return {
        ...state,
        condFilters: initialState.condFilters,
        type: action.type,
      };

    case types.UPDATE_KIOSK_SUCCESS:
      return {
        ...state,
        condFilters: initialState.condFilters,
        type: action.type,
      };
    case types.DEL_KIOSK_SUCCESS:
      return {
        ...state,
        listKiosks: [],
        condFilters: initialState.condFilters,
        type: action.type
      }
    case types.GET_CODE_IDENTIFY:
      return {
        ...state,
        identifierCode: action.payload.identifierCode,
        type: action.type,
      };

    case types.RESET_CODE_IDENTIFY:
      return {
        ...state,
        identifierCode: "",
        type: action.type,
      };

    case types.RESET_VALUE_SEARCH:
      return {
        ...state,
        isSearch: false,
        isSearching: false
      };

    case types.GET_INFO_KIOSK: 
      return {
        ...state,
        kioskInfo: action.payload.kioskInfo
      }
      
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        type: action.type
      }

    default:
      return state;
  }
};
export default kioskReducer;
