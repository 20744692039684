import React from 'react'

const IcConnect = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 13.3333H8.00667" stroke="#3D8B4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.68665 10.74C6.36345 10.2592 7.17309 10.0008 8.00331 10.0008C8.83353 10.0008 9.64318 10.2592 10.32 10.74" stroke="#3D8B4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.33337 8.36667C4.65108 7.26911 6.31178 6.66808 8.02671 6.66808C9.74164 6.66808 11.4023 7.26911 12.72 8.36667" stroke="#3D8B4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M0.946655 6C2.89494 4.28264 5.40285 3.3351 7.99999 3.3351C10.5971 3.3351 13.105 4.28264 15.0533 6" stroke="#3D8B4C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcConnect
