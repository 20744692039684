import styled from "styled-components";

const WrapFace = styled.div`
    position: relative;
    overflow: unset !important;
    img{
        cursor: pointer;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        object-fit: cover;
    }
    .wrap-face-zoom{
        z-index: 10;
        position: absolute;
        left: calc(100% + 10px);
        bottom: ${props => props.propsPopup ? props.propsPopup : "-260px"};
        width: 263px;
        height: 263px;
        box-sizing: border-box;
        border-radius: 3px;
    }
`

export {WrapFace};