import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// css
import { WrapPopupFilterRange } from './index.styles'
import { OverlayFullScreen } from '../../../../../../MainStyles/main'

// img
import IcClose from '../../../../../../../assets/images/IcClose'
import IcPlus from '../../../../../../../assets/images/IcPlus'

//component
import Button from '../../../../../../Control/Button'
import InputDropdownRange from './InputDropdownRange'

// data
import { dataListRange } from '../../../../../../../data/dataListRange'

// hook
import { useOnClickOutside } from '../../../../../../../hook/useClickOutside'
import { getListClusterAction } from '../../../../../../../redux/cluster/action'
import { addLockerClusterForKiosk, getInfoLocker } from '../../../../../../../redux/kiosk/action'

const PopupFilterApply = ({onClickIconClose, kioskDocID, arrTest}) => {

    const dispatch = useDispatch()
    const filterRef = useRef(null)

    const { totalClusters, listClusters, branchCode } = useSelector(state => ({
        totalClusters: state.clusterReducer.totalClusters,
        listClusters: state.clusterReducer.listClusters,
        branchCode: state.login.dataLogin.branchCode
      }))
    
    // check trùng STT hộc tủ
    const checkIDLocker = (clus) => {
        let arrLockersID = clus.map((item)=>item.lockerID)
        return arrLockersID.some((item)=>{
            return arrTest.includes(item)
        })
    }
    
    const configListClusterDropdown = listClusters?.filter(clus=>{ return clus.kioskId === "" && checkIDLocker(clus.lockerList) === false})?.map(item => {
        return {
            key: item.id,
            text: item.clusterName
        }
    })
    // sort array by anphabet
    configListClusterDropdown.sort((a,b)=>{
        if (a.text.toLowerCase() < b.text.toLowerCase()){ return -1}
        if (a.text.toLowerCase() > b.text.toLowerCase()){ return 1}
        return 0
    })
    const [dataRange, setDataRange] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            rangeCabinet : undefined,
            errSubmit: ""
        }
    )

    const _handleGetValueRange = (val) => {
        setDataRange({rangeCabinet : val, errSubmit: "" })
    }

    const _handleValidationInputDropdown = (objChoice,callback) => {
        if (!objChoice){
            callback("Thông tin không được để trống")
        }
    }

    useEffect(()=>{
        dispatch(getListClusterAction({
          branchCode:branchCode, 
          filterFrom: "",
          filterSize: totalClusters ? totalClusters : 9,
          quickSearchData: "",
          roomType: "",
          kioskName: ""
        }))
      },[totalClusters])

    const onSubmit = () => {
        if ( !dataRange?.rangeCabinet ){
            setDataRange({errSubmit: "Thông tin không được để trống"})
            return
        }

        const callbackSuccess = () => {
            dispatch(getInfoLocker({docID: kioskDocID}))
            onClickIconClose()
        }

        const callbackError = () => {

        }
        dispatch(addLockerClusterForKiosk({
            kioskDocID: kioskDocID,
            clusterDocID: dataRange?.rangeCabinet?.key
        }, callbackSuccess, callbackError))
    }

    // useOnClickOutside(filterRef, ()=> onClickIconClose())

    return (
        <OverlayFullScreen> 
            <WrapPopupFilterRange ref={filterRef}>
                <div className="wrap-header">
                    <p className='bold'>Chọn dãy tủ áp dụng</p>
                    <IcClose
                        className='btn-close'
                        onClick={onClickIconClose}
                    />
                </div>
                <div className="value">
                    Thông tin chi tiết dãy tủ
                </div>
                <div className="label-value">
                    <span>Dãy tủ</span>
                    <span className='notice'>*</span>
                </div>
                <InputDropdownRange
                    handleGetValueDropdown={_handleGetValueRange}
                    listDropDown={configListClusterDropdown}
                    objChoice={dataRange.rangeCabinet}
                    className="value"
                    handleValidationInput={_handleValidationInputDropdown}
                />
                { dataRange?.errSubmit && (
                    <div className='submit-error'>{dataRange?.errSubmit}</div>
                )}
                <Button 
                    text="Áp dụng"
                    iconBtn={<IcPlus />}
                    className="btn-confirm"
                    onClick={onSubmit}
                />
            </WrapPopupFilterRange>
        </OverlayFullScreen>
    )
}

export default PopupFilterApply
