import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// styles
import { WrapPopupDeleteKiosk } from './index.styles'
import { OverlayFullScreen } from '../../../../../../MainStyles/main';

//images
import IcClose from '../../../../../../../assets/images/IcClose';
import IcTrashWhite from '../../../../../../../assets/images/IcTrashWhite';

//control
import Button from '../../../../../../Control/Button';
import { delKioskAction, getListKioskAction } from '../../../../../../../redux/kiosk/action';


const PopupDeleteKiosk = ({ dataKiosk, handleClose }) => {

    const dispatch = useDispatch()
    
    const _handleDelete = () =>{
        const callbackSuccess = () =>{
            handleClose && handleClose()
            //get lại list ở đây
            dispatch(getListKioskAction({ 
                branchCode: dataKiosk?.branchCode,
                filterFrom: "",
                filterSize: 100,
                roomType: "",
                status: "",
                quickSearchData: ""
            }))
        }
    
        const callbackError = () => {
    
        }
        dispatch(delKioskAction({
            kioskDocID: dataKiosk?.id,
        }, callbackSuccess, callbackError))
    }

    return (
            <OverlayFullScreen onClick={(e)=> e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupDeleteKiosk>
                <IcClose className="icon-close" onClick={handleClose} />
                    <div className="title">Xoá Kiosk?</div>
                    <div className="sub-title">
                        Bạn đang thực hiện xóa Kiosk, việc này sẽ không thể hoàn tác. Bạn có chắc chắn muốn xóa không?
                    </div>
                    <div className="notice">
                        *Dãy tủ đang hoạt động sẽ mất liên kết với Kiosk này
                    </div>
                    <div className='btn-container'>
                        <Button
                            text="Hủy bỏ"
                            className="btn-cancel-del"
                            onClick={handleClose}
                        />
                        <Button
                            text="Xóa"
                            iconBtn={<IcTrashWhite />}
                            className="btn-del"
                            onClick={_handleDelete}
                        />
                    </div>
                </WrapPopupDeleteKiosk>
            </div>
        </OverlayFullScreen>        
    )
}

export default PopupDeleteKiosk
