import React, { useRef } from 'react'

// hook
import useScrollEnd from '../../../hook/useScrollEnd'
import IcCheckBlue from '../../../assets/images/IcCheckBlue'
import IcSingleChoice from '../../../assets/images/IcSingleChoice'
import IcSingleChoiceSelected from '../../../assets/images/IcSingleChoiceSelected'

// styles
import { WrapDropdownList } from './index.styles'

const DropdownList = ({
    isShowCheckStatus = { isShow: false, isSingleChoice: false, isCheckMark: true },
    listItem,
    valueSelected,
    setValueSelect,
    alignTop,
    className,
    maxItemShow,
    isLengthStringBig,
    handleScrollEnd }) => {
    const eleRef = useRef()

    useScrollEnd(eleRef, (callback) => {
        if (!maxItemShow) return;
        if (!handleScrollEnd) return;
        handleScrollEnd(callback)
    }, [handleScrollEnd])

    return (
        <WrapDropdownList ref={eleRef} alignTop={alignTop} className={className} maxItemShow={maxItemShow}>
            {
                listItem?.length !== 0 &&
                listItem?.map((item) => {
                    const { text, key } = item
                    return (
                        <div key={key} onClick={() => setValueSelect(item)}>
                            {
                                isShowCheckStatus?.isShow ?
                                    isShowCheckStatus?.isSingleChoice ?
                                        valueSelected?.key === key ?
                                            <IcSingleChoiceSelected className='ic-checkbox' />
                                            :
                                            <IcSingleChoice className='ic-checkbox' />
                                        :
                                        <></>
                                    :
                                    <></>
                            }
                            { isLengthStringBig ? (
                                <span title={text}>{text?.length > 40 ? `${text?.substring(0,40)}...` : text}</span>
                            ) : (
                                <span title={text}>{text?.length > 20 ? `${text?.substring(0,20)}...` : text}</span>
                            )}
                            {
                                isShowCheckStatus?.isShow && isShowCheckStatus?.isCheckMark &&
                                valueSelected?.key === key && <IcCheckBlue className='ic-check-mark' />
                            }
                        </div>
                    )
                })
            }
        </WrapDropdownList>
    )
}

export default DropdownList
