import Axios from "axios";
import { nanoid } from 'nanoid'

//type
import * as types from './types'

//host
import { host } from "../../constant/host";

//path
import { main } from "../../constant/routes";

// constant
import { CITYGYM_ADMIN_LANG, CITYGYM_ADMIN_LOGIN } from "../../constant/keys";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import checkSessionLogin from "../../helper/checkSessionLogin";

// firebase
import { signInFirebase, signOutFirebase } from "../../firebase/realtimeDatabase";


const apiLogin = `${host}/access/login`;
const apiLogout = `${host}/access/logout`;

const headerDataRequest = {
    cid: 'web',
    reqId: `${new Date().getTime()}`,
    langCode: 'vn'
}

export const loginAction = (navigate, dataLogin, callbackSuccess, callbackError) => async (dispatch) => {

    const dataRequest = {...headerDataRequest,data: dataLogin}
    const requestData = JSON.stringify(dataRequest);
    try {
        const res = await Axios.post(apiLogin, requestData);
        const { data } = res;
        if (checkIsSuccessAction(data.code)) {
            localStorage.setItem(CITYGYM_ADMIN_LANG, JSON.stringify({ langKey: 'vn' }));
            localStorage.setItem(CITYGYM_ADMIN_LOGIN, JSON.stringify({...data.data,sessionKey: dataRequest.reqId}));
            Axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`
            const { fbToken, bioDeviceID, receptionID } = data.data
            if(fbToken?.length > 0){
                signInFirebase(fbToken)
            }
            dispatch({
                type: types.LOGIN,
                data: {...data.data,sessionKey: dataRequest.reqId},
            });
            callbackSuccess && callbackSuccess()
            navigate(`/${main}`);
        }

        else {
            callbackError && callbackError()
        }

    } catch (error) {
        const response = error?.response;
        if (response) {
            callbackError && callbackError()
            return;
        }

        // dispatch({
        //     type: NETWORK_ERR,
        // });
    }
}

export const logoutAction = () => async (dispatch, getState) => {
    let userLogin = JSON.parse(localStorage.getItem(CITYGYM_ADMIN_LOGIN));
    let adminId = userLogin?.id
    const dataRequest = {...headerDataRequest,data: {id: adminId}, cid: adminId}
    const requestData = JSON.stringify(dataRequest);
       
    try {
        const res = await Axios.post(apiLogout, requestData, {
            headers: {
                reqID: nanoid(),
                deviceID: ""
            }
        }) 
    }catch (error){

    }
    let dataLogin = getState().login.dataLogin
   
    if(!dataLogin){
        // localStorage.removeItem(CITYGYM_ADMIN_LOGIN)
    }
    else{
        let isCheck = checkSessionLogin(dataLogin)
        if(isCheck) {
            const dataStore = localStorage.getItem(CITYGYM_ADMIN_LOGIN)
            let loginStore = JSON.parse(dataStore)
            // console.log(dataLogin, loginStore)
            localStorage.removeItem(CITYGYM_ADMIN_LOGIN)
        }
    }
    dispatch({
        type: types.LOGOUT
    })
}