import styled from 'styled-components'

import { WrapPageItem } from '../../../MainStyles/main'

const WrapKioskManage = styled(WrapPageItem)`
    background: #EFEFEF;
    padding: 30px;
`;

export{
    WrapKioskManage
}