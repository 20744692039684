import React from 'react'

const IcFilterWhite = (props) => {
    return (
        <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.8334 1.75H1.16675L5.83342 7.26833V11.0833L8.16675 12.25V7.26833L12.8334 1.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcFilterWhite
