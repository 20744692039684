import styled from 'styled-components'

const WrapHeader = styled.div`
    width: 100%;
    height: 60px;
    background: #A31E23;
    .wrap-inner {
        width: 1280px;
        height: 100%;
        padding: 0px 30px;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        display: flex;

        .logo-header{
            display: flex;
            &:hover{
                cursor: pointer;
            }
        }
        .user{
            display: flex;
            align-items: center;
            position: relative;
            .user_name{
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 13.5px;
                line-height: 18px;
                color: #FFFFFF;
            }
            .ic-user{
                margin-left: 12px;
                cursor: pointer;
            }
            .ic-logout{
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }
`;

export {
    WrapHeader
}