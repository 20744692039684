import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// styles
import { WrapPopupDeleteCabinetRange } from './index.styles'
import { OverlayFullScreen } from '../../../../../../MainStyles/main';

//images
import IcClose from '../../../../../../../assets/images/IcClose';
import IcTrashWhite from '../../../../../../../assets/images/IcTrashWhite';

//control
import Button from '../../../../../../Control/Button';
import { delClusterAction, getListClusterAction } from '../../../../../../../redux/cluster/action';



const PopupDeleteCabinetRange = ({ dataCabinetRange, handleClose }) => {

    const dispatch = useDispatch()
    
    const _handleDelete = () =>{
        const callbackSuccess = () =>{
            handleClose && handleClose()
            //get lại list ở đây
            dispatch(
                getListClusterAction({
                  branchCode: dataCabinetRange?.branchCode,
                  filterFrom: "",
                  filterSize: 100,
                  quickSearchData: "",
                  roomType: "",
                  kioskName: ""
                })
              );
        }
    
        const callbackError = () => {
    
        }
        dispatch(delClusterAction({
            id: dataCabinetRange?.id,
            branchCode: dataCabinetRange?.branchCode
        }, callbackSuccess, callbackError))
    }

    return (
            <OverlayFullScreen onClick={(e)=> e.stopPropagation()}>
            <div className="wrap-inner-screen">
                <WrapPopupDeleteCabinetRange>
                <IcClose className="icon-close" onClick={handleClose} />
                    <div className="title">Xoá Dãy tủ?</div>
                    <div className="sub-title">
                        Bạn đang thực hiện xóa Dãy tủ, việc này sẽ không thể hoàn tác. Bạn có chắc chắn muốn xóa không?
                    </div>
                    <div className="notice">
                        *Hộc tủ đang liên kết với dãy sẽ bị xóa
                    </div>
                    <div className='btn-container'>
                        <Button
                            text="Hủy bỏ"
                            className="btn-cancel-del"
                            onClick={handleClose}
                        />
                        <Button
                            text="Xóa"
                            iconBtn={<IcTrashWhite />}
                            className="btn-del"
                            onClick={_handleDelete}
                        />
                    </div>
                </WrapPopupDeleteCabinetRange>
            </div>
        </OverlayFullScreen>        
    )
}

export default PopupDeleteCabinetRange
