import styled from "styled-components";

const InputWrapper = styled.div`
    margin-bottom: 20px;
    height: ${props => props.heightInput || "40px"};
    &.err-wrap-input-login{
        margin-bottom: 40px;
    }
    .title-input{
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #191919;
        margin-bottom: 8px;
        .title-notice{
            color: #A31E23;
            margin-left: 5px;
        }
    }
    
    .wrap-input{
        height: 40px;
        position: relative;
        .icon-left{
            position: absolute;
            left: 10px;
            top: 10px;
        }
        input{
            font-family: "Open Sans";
            height: 100%;
            width: 100%;
            /* margin-left: 50p; */
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            padding-left: 40px;
            padding-right: 10px;
            border: 0.5px solid #B7B7B7;
            border-radius: 4px;
            color: #191919;;
            letter-spacing: -0.01em;
            font-size: 13.5px;
            line-height: 18px;

            &:disabled {
                background: #F7F7F7;
                font-weight: 400;
                color: #B7B7B7;
            }

            &:focus{
                outline: none;
                border: 0.5px solid #33adff;
            }
            &::placeholder{
                color: #BDBDBD;
            }
        }
        & > .error-input{
            border: 0.5px solid #F45C5C !important;
        }
    }
    .error{
        color: #F45C5C;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        padding: 4px 0 0 15px;
        float: left;
    }
`

export {InputWrapper};