export const SEARCH_MEMBER = 'SEARCH_MEMBER'
export const SEARCH_MEMBER_ERR = 'SEARCH_MEMBER_ERR'
export const SEARCH_MEMBER_FAIL = 'SEARCH_MEMBER_FAIL'

export const RESET_SEARCH_MEMBER = 'RESET_SEARCH_MEMBER'

export const UPDATE_FACE_MEMBER_SUCCESS = 'UPDATE_FACE_MEMBER_SUCCESS'
export const UPDATE_FACE_MEMBER_ERR = 'UPDATE_FACE_MEMBER_ERR'
export const UPDATE_FACE_MEMBER_FAIL = 'UPDATE_FACE_MEMBER_FAIL'

export const REQUEST_UPDATE_FACE = 'REQUEST_UPDATE_FACE'
export const REQUEST_UPDATE_FACE_ERR = 'REQUEST_UPDATE_FACE_ERR'
export const RESET_REQUEST_UPDATE_FACE = 'RESET_REQUEST_UPDATE_FACE'

export const GET_LIST_UPDATE = 'GET_LIST_UPDATE'
export const GET_LIST_UPDATE_ERR = 'GET_LIST_UPDATE_ERR'
export const CLEAR_LIST_UPDATE = 'CLEAR_LIST_UPDATE'
export const RESET_VALUE_SEARCH = 'RESET_VALUE_SEARCH'

export const UPDATE_FACE_ID = 'UPDATE_FACE_ID'
export const SET_FACE_UPDATE = 'SET_FACE_UPDATE'
export const SET_FACE_UPDATE_ERR = 'SET_FACE_UPDATE_ERR'
export const RESET_FACE_UPDATE = 'RESET_FACE_UPDATE'

export const DEVICE_ENROLLING_ERR = 'DEVICE_ENROLLING_ERR'
export const ACTION_ENROLLING_EXISTED_ERR = 'ACTION_ENROLLING_EXISTED_ERR'
export const UPDATE_FACE_TIMEOUT_ERR = 'UPDATE_FACE_TIMEOUT_ERR'
export const DEVICE_ERR = 'DEVICE_ERR'
export const UPDATE_FACE_ERR = 'UPDATE_FACE_ERR'
export const DUPLICATED_PINCODE_ERR = 'DUPLICATED_PINCODE_ERR'

export const GET_LIST_DUPLICATE = 'GET_LIST_DUPLICATE'
export const GET_LIST_DUPLICATE_FAIL = 'GET_LIST_DUPLICATE_FAIL'

