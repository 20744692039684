import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// css
import { TablelInfoCabinet } from "./index.styles";

// img
import IcEdit from "../../../../../../assets/images/IcEdit";
import IcConnect from "../../../../../../assets/images/IcConnect";
import testPNG from "../../../../../../assets/images/test.png";

// component
import Button from "../../../../../Control/Button";
import FaceZoom from "../../../../../Control/FaceZoom";
import Portal from "../../../../../Control/Portal";
import PopupEditCabinetRange from "../../Listing/OptionCabinetRange/PopupEditCabinetRange";

// mocData
import dataImgKiosk from "../../../../../../mockdata/dataImgKiosk";
import { listRoom } from "../../../../../../data/listRoom";

// host
import { apiGetPhoto } from "../../../../../../constant/host";
import {
  getInfoByDocID,
  resetGetInfoByDocID,
} from "../../../../../../redux/cluster/action";

const DetailCabinetRange = ({ refList, cabinetRangeId, isGetNewInfo }) => {
  const dispatch = useDispatch();
  const [isShowPopupEdit, setIsShowPopupEdit] = useState(false);
  const [listImage, setListImage] = useState([]);
  const { clusterInfo } = useSelector((state) => ({
    clusterInfo: state.clusterReducer.clusterInfo,
  }));

  let numberOfFreeLocker = clusterInfo?.lockerList?.filter(
    (locker) => locker.useStatus === false
  ).length;

  useEffect(() => {
    isGetNewInfo && dispatch(getInfoByDocID({ docID: cabinetRangeId }));
    return () => {
      // dispatch(resetGetInfoByDocID());
    };
  }, []);

  useEffect(() => {
    if (!clusterInfo) return;
    if (!clusterInfo.photoList || clusterInfo.photoList.length === 0) return;
    let list = [];
    clusterInfo.photoList.forEach((item) => {
      list.push(apiGetPhoto(item));
    });
    setListImage(list);

    return () => {};
  }, [clusterInfo]);

  return (
    <TablelInfoCabinet>
      <div className="detail-content-item">
        <p className="title">Hình thực tế</p>
        <FaceZoom url={testPNG} alt="" refList={refList} listItem={listImage} />
      </div>
      <div className="detail-content-item">
        <p className="title">Thông tin</p>
        <div className="detail-value">
          <p>ID Kiosk</p>
          <p className="bold">
            {clusterInfo?.kioskId ? clusterInfo?.kioskId : "-"}
          </p>
        </div>
        <div className="detail-value">
          <p>ID dãy tủ</p>
          <p className="bold">
            {clusterInfo?.clusterId ? clusterInfo?.clusterId : "-"}
          </p>
        </div>
        <div className="detail-value">
          <p>Tên dãy tủ</p>
          <p className="bold" title={clusterInfo?.clusterName}>
            {clusterInfo?.clusterName?.length > 60
              ? clusterInfo?.clusterName?.substr(0, 60) + "..."
              : clusterInfo?.clusterName}
          </p>
        </div>
        <div className="detail-value">
          <p>Phòng</p>
          <p className="bold">
            {clusterInfo?.roomType
              ? listRoom[clusterInfo?.roomType]?.text
              : "-"}
          </p>
        </div>
        <div className="detail-value">
          <p>Số lượng hộc tủ</p>
          <p className="bold">{clusterInfo?.lockerList?.length || 0}</p>
        </div>
        <div className="detail-value">
          <p>Còn đang trống</p>
          <p className="bold">{numberOfFreeLocker || 0}</p>
        </div>
        <div className="detail-value">
          <p>Lượt sử dụng</p>
          <p className="bold">Hôm nay {clusterInfo?.totalNumberOfUsedForToday <= 9 ? `0${clusterInfo?.totalNumberOfUsedForToday}` : clusterInfo?.totalNumberOfUsedForToday} | Tất cả {clusterInfo?.totalNumberOfUsed <= 9 ? `0${clusterInfo?.totalNumberOfUsed}` : clusterInfo?.totalNumberOfUsed}</p>
        </div>
      </div>
      <Button
        iconBtn={<IcEdit />}
        text="Chỉnh sửa"
        className="edit-btn"
        onClick={() => setIsShowPopupEdit(true)}
      />
      {isShowPopupEdit && (
        <Portal>
          <PopupEditCabinetRange
            dataCabinetRange={clusterInfo}
            onClickIconClose={() => setIsShowPopupEdit(false)}
          />
        </Portal>
      )}
    </TablelInfoCabinet>
  );
};

export default DetailCabinetRange;
