import { KIOSK_INFO, KIOSK_RANGE } from "../constant/keys";
import IcInfo from '../assets/images/IcInfo'
import IcGridInfo from '../assets/images/IcGridInfo'


const dataHeaderKiosk = [
  {
      key: KIOSK_INFO,
      name: "Thông tin Kiosk",
      Icon: IcInfo,
  },
  {
      key: KIOSK_RANGE,
      name: "Dãy tủ đang quản lý",
      Icon: IcGridInfo,
  },
];

export default dataHeaderKiosk;
