import styled from 'styled-components'

const WrapInputSearch = styled.div`
    position: relative;
    .input-search {
        min-width: 387px;
        height: 36px;
        background: #FFFFFF;
        border: 0.5px solid #BDBDBD;
        box-sizing: border-box;
        border-radius: 33px;
        padding: 0 30px 0 12px;
        color: #010101;
        outline: none;
        &::placeholder {
            color: #BDBDBD;
        }
    }
    .icon-search {
        position: absolute;
        right: 13px;
        top: calc(50% - 14px/2);
        cursor: pointer;
        width: 14px;
        height: 14px;
    }
`;

export {
    WrapInputSearch
}