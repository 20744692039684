//host
import { host } from "../../constant/host";

//axios
import Axios from "axios";

//help
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from "../../helper/mapErrors";

//types
import * as types from "./types";
import checkSessionLogin from "../../helper/checkSessionLogin";
import { logoutAction } from "../login/action";
import { disableLoading, enableLoading } from "../loading/action";
import { CITYGYM_ADMIN_LOGIN } from "../../constant/keys";

const apiAddCluster = `${host}/cluster/add`;
const apiUpdateCluster = `${host}/cluster/update`;
const apiDelCluster = `${host}/cluster/delete`;
const apiFilterCluster = `${host}/cluster/filter`;
const apiExportFile = `${host}/cluster/export`;
const apiGetInfoByDocID = `${host}/cluster/getInfoByDocID`
// const apiGetClusterListByKioskID = `${host}/cluster/getInfoByKioskID`



const headerDataRequest = {
  cid: "web",
  reqId: `${new Date().getTime()}`,
  langCode: "vn",
};

let isCalling = false

export const resetListCluster = (callbackSuccess) => async (dispatch) => {
  dispatch({
    type: types.RESET_LIST_CLUSTER,
  });

  callbackSuccess && callbackSuccess();
};

export const getListClusterAction =
  (
    dataGetList = {
      branchCode: "",
      filterFrom: "",
      filterSize: 10,
      quickSearchData: "",
      roomType: "",
      kioskName: "",
    },
    isScroll,
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    !isScroll && dispatch(enableLoading())
    isCalling = true
    let data = { ...dataGetList };
    const condFilters = {...data};
    let dataRequest = { ...headerDataRequest,cid:dataLogin.id,data };
    try {
      const res = await Axios.post(apiFilterCluster, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({
          type: types.GET_LIST_CLUSTER,
          payload: {
            listClusters: data.data.clusterList || [],
            totalClusters: data.data.total || 0,
            condFilters,
            isScroll,
          },
        });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({
          type: types.GET_LIST_CLUSTER_ERR,
          payload: {
            ...data,
          },
        });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.GET_LIST_CLUSTER_ERR));
    }

    isCalling = false
    dispatch(disableLoading())

  };

export const addClusterAction =
  (
    dataCluster = {
      branchCode: "",
      clusterID: "",
      clusterName: "",
      kioskID: "",
      roomType: "",
      photoList: [""],
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataCluster };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiAddCluster, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        await dispatch({ type: types.ADD_CLUSTER_SUCCESS });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({ type: types.ADD_CLUSTER_ERR, payload:{code: data.code, error: data.message} });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.ADD_CLUSTER_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const updateClusterAction =
  (
    dataCluster = {
      id: "",
      clusterName: "",
      kioskID: "",
      roomType: "",
      photoList: [],
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataCluster };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiUpdateCluster, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({ type: types.UPDATE_CLUSTER_SUCCESS, payload:{code: 0} });
        isCalling = false
        callbackSuccess && callbackSuccess();

      } else {
        dispatch({ type: types.UPDATE_CLUSTER_ERR });
        callbackError && callbackError(data.code);
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.UPDATE_CLUSTER_ERR));
    }

    isCalling = false
    dispatch(disableLoading())
  };

export const delClusterAction =
  (
    dataCluster = {
      id: "",
      branchCode: "",
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    if (isCalling) return
    isCalling = true
    let data = { ...dataCluster };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiDelCluster, dataRequest);
      const { data } = res;

      if (checkIsSuccessAction(data.code)) {
        dispatch({ type: types.DEL_CLUSTER_SUCCESS });
        isCalling = false
        callbackSuccess && callbackSuccess();
        
        
      } else {
        dispatch({ type: types.DEL_CLUSTER_ERR });
        callbackError && callbackError();
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.DEL_CLUSTER_ERR));
    }
    
    isCalling = false
    dispatch(disableLoading())
  };

export const resetValueSearchCluster = () => async (dispatch) => {
  dispatch({
    type: types.RESET_VALUE_SEARCH
  })
}

export const getInfoByDocID =
  (
    dataGetInfo = {
      docID: ""
    },
    callbackSuccess,
    callbackError
  ) =>
  async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin;
    if (!checkSessionLogin(dataLogin)) {
      dispatch(logoutAction());
      return;
    }
    // if (isCalling) return
    // isCalling = true
    let data = { ...dataGetInfo };
    let dataRequest = { ...headerDataRequest, data, cid: dataLogin.id };
    dispatch(enableLoading())
    try {
      const res = await Axios.post(apiGetInfoByDocID, dataRequest);
      const { data } = res;
      if (checkIsSuccessAction(data.code)) {
        dispatch({ 
          type: types.GET_INFO_BY_DOC_ID,
          clusterInfo: data.data.clusterInfo 
        });
        // isCalling = false
        callbackSuccess && callbackSuccess();
        
      } else {
        dispatch({ type: types.GET_INFO_BY_DOC_ID_ERR, payload: {code: 0} });
        callbackError && callbackError(data.code);
      }
    } catch (errors) {
      dispatch(mapErrors(errors, types.GET_INFO_BY_DOC_ID_ERR));
    }

    // isCalling = false
    dispatch(disableLoading())
  };

export const resetGetInfoByDocID = () => async (dispatch) => {
  dispatch({type: types.RESET_INFO_BY_DOC_ID})
}

// export const getClusterListByKioskID =
//   (
//     dataGet = {
//       kioskID: "",
//       branchCode: ""
//     },
//     callbackSuccess,
//     callbackError
//   ) =>
//   async (dispatch, getState) => {
//     let dataLogin = getState().login.dataLogin;
//     if (!checkSessionLogin(dataLogin)) {
//       dispatch(logoutAction());
//       return;
//     }
//     // if (isCalling) return
//     // isCalling = true
//     let data = { ...dataGet };
//     let dataRequest = { ...headerDataRequest, data };
//     dispatch(enableLoading())
//     try {
//       const res = await Axios.post(apiGetClusterListByKioskID, dataRequest);
//       const { data } = res;
//       if (checkIsSuccessAction(data.code)) {
//         dispatch({ 
//           type: types.GET_CLUSTER_LIST_BY_KIOSK_ID,
//           listClusters: data.data.clusterList 
//         });
//         // isCalling = false
//         callbackSuccess && callbackSuccess();
        
//       } else {
//         dispatch({ type: types.GET_CLUSTER_LIST_BY_KIOSK_ID_FAIL });
//         callbackError && callbackError();
//       }
//     } catch (errors) {
//       dispatch(mapErrors(errors, types.GET_CLUSTER_LIST_BY_KIOSK_ID_FAIL));
//     }

//     // isCalling = false
//     dispatch(disableLoading())
//   };
