import React, { useEffect, useState } from "react";
import IcFacePrintDefault from "../../../../../../assets/images/IcFacePrintDefault";
import { apiGetAvatar, apiGetPhoto } from "../../../../../../constant/host";
import { convertDateTime } from "../../../../../../helper/convertDateTime";
import FaceZoomAvt from "../../../../../Control/FaceZoomAvt";
import { Bound } from "./index.styles";
import Portal from "../../../../../Control/Portal";
import PopupFace from "./PopupFace";

const ItemNewCheckFace = (props) => {
  const { className, item, index, refList, urlMemberPhoto } = props;

  const [isErrImg, setIsErrImg] = useState(false);
  const [isShowPopupFace, setIsShowPopupFace] = useState(false);

  useEffect(() => {
    setIsErrImg(false);
  }, [item?.id]);

  return (
    <>
      <Bound className={className} onClick={() => setIsShowPopupFace(true)}>
        <div>{index < 9 ? `${index + 1}` : index + 1}</div>
        <div title={item?.memberSFID}>{item?.memberSFID || "-"}</div>
        <div className="wrap-avatar">
          {isErrImg ? (
            <IcFacePrintDefault className="avatar" />
          ) : (
            <FaceZoomAvt
              url={apiGetPhoto(item?.avatarURL)}
              className="avatar"
              alt="avatar"
              onError={() => setIsErrImg(true)}
              refList={refList}
            />
          )}
        </div>
        <div title={item?.fullname}>{item?.fullname || "-"}</div>
        <div>
          {item?.createAt
            ? convertDateTime(item?.lastUpdateAt, "DD/MM/YYYY HH:mm")
            : ""}
        </div>
      </Bound>
      {isShowPopupFace && (
        <Portal>
          <PopupFace
            urlMemberPhoto={urlMemberPhoto}
            item={item}
            onClickIconClose={() => setIsShowPopupFace(false)}
          />
        </Portal>
      )}
    </>
  );
};

export default ItemNewCheckFace;
