import React from 'react'
import InputDropdown from '../../../../../../../Control/InputDropdown'

// img
import IcRoomGray from '../../../../../../../../assets/images/IcRoomGray'
import IcRoomBlue from '../../../../../../../../assets/images/IcRoomBlue'

const iconStore = {
    icon: IcRoomGray,
    iconActive: IcRoomBlue,
    iconError: IcRoomBlue,
}

const InputDropdownRoom = ({ handleGetValueDropdown, listDropDown, objChoice }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }

    return (
        <InputDropdown
            title= "Loại tủ"
            getValueDropdown={_handleSetValueDropdown}
            iconInput={iconStore}
            placeholder="Trạng thái"
            required={false}
            dataFilterOption={listDropDown}
            objChoice={objChoice}
        />
    )
}

export default InputDropdownRoom
