import React from 'react'

const IcAddItem = (props) => {
    return (
        <svg {...props} width={15} height={15} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 2.9165V11.0832" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.4165 7H11.5832" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcAddItem