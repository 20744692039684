import styled from "styled-components";
import { WrapPopupFilter } from "../../../PopupFilterKiosk/index.style";

const WrapPopupFilterRang = styled(WrapPopupFilter)`
    .btn-filter-range{
        background-color: #A31E23;
        margin-left: 10px;
    }
`

export {
    WrapPopupFilterRang
}