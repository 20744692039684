import React from 'react'

const IcCabinetActive = (props) => {
    return (
        <svg {...props} width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 14.5H14.5V21.5H21.5V14.5Z" fill="#A31E23" />
            <path d="M10.5 14.5H3.5V21.5H10.5V14.5Z" fill="#A31E23" />
            <path d="M21.5 3.5H14.5V10.5H21.5V3.5Z" fill="#A31E23" />
            <path d="M10.5 3.5H3.5V10.5H10.5V3.5Z" fill="#A31E23" />
            <path d="M21.5 14.5H14.5V21.5H21.5V14.5Z" stroke="#A31E23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 14.5H3.5V21.5H10.5V14.5Z" stroke="#A31E23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.5 3.5H14.5V10.5H21.5V3.5Z" stroke="#A31E23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 3.5H3.5V10.5H10.5V3.5Z" stroke="#A31E23" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcCabinetActive
