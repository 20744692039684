import React from "react";

export default function IcNameKioskGray(props) {
  return (
    <svg
      {...props}
      width={18}
      height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 2H4.5C3.67157 2 3 2.67157 3 3.5V15.5C3 16.3284 3.67157 17 4.5 17H13.5C14.3284 17 15 16.3284 15 15.5V3.5C15 2.67157 14.3284 2 13.5 2Z"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 14H9.0075"
        stroke="#828282"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
